import BaseComponent from '../app-ui-kit/base-component';
import { withParams } from '../app-ui-kit/higher-order-component';

import { DataTransferManager, DataTransferManagerResultSignet } from "../../data-manager/data-transfer-manager"
import { ProgressIndicationManager } from '../../collaborators/progress-indication-manager';
import { PopupManager } from '../../collaborators/popup-manager';
import { ObservationManager, ObservationEventName } from '../../collaborators/observation-manager';
import { RoutingManager } from '../../collaborators/routing-manager';

import { Subscription } from '../../models/subscription';
import { SubscriptionPlan } from '../../models/subscription-plan';
import { Payment } from '../../models/payment';
import { AppSpacer } from '../app-ui-kit/app-ui-kit';

import { ConfigurationManager } from '../../collaborators/configuration-manager';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import "./subscription-details-component.css"
import { CacheManager } from '../../collaborators/cache-manager';
import { ATUtilityManager } from '../../third-party/com-aurvan-atkit-reactjs/src/at-utility-manager';
import { LocalizationManager } from '../../localization-manager/localization-manager';
import { AppButton, AppButtonType } from '../app-ui-kit/app-button';


class SubscriptionDetailsComponent extends BaseComponent {

	render() {
		var aReturnVal = this.loginRequiredView();
		if (CacheManager.shared.loggedInCustomer != null) {
			if (Subscription.isSubscriptionPlanActive(CacheManager.shared.loggedInCustomer.subscriptions, SubscriptionPlan.premiumR1)) {
				aReturnVal = this.premiumSubscriptionView();
			} else {
				aReturnVal = this.basicSubscriptionView();
			}
		}
		return aReturnVal;
	}

}


// MARK:- Views

SubscriptionDetailsComponent.prototype.basicSubscriptionView = function() {
	var aReturnVal = <div className="foundation-storey vertical-scroll-view">

		<div className="subscription-details-container subscription-details-basic-container">

			<div className="subscription-details-basic-content subscription-details-basic-plan-content">
				<AppSpacer height="10px" />
				<div className="subscription-details-icon"></div>
				<AppSpacer height="8px" />
				<div className="subscription-details-title">{LocalizationManager.subscriptionDetailsComponent_currentSubscriptionBasicPlanTitle()}</div>
				<AppSpacer height="8px" />
				<div className="subscription-order-summary-amount">$0</div>
				<AppSpacer height="10px" />
				<div className="subscription-details-summary">{LocalizationManager.subscriptionDetailsComponent_currentSubscriptionBasicPlanTagLine()}</div>
			</div>

			<div className="subscription-details-vertical-separator"></div>

			<div className="subscription-details-basic-content subscription-details-upgrade-content">
				<div className="subscription-details-upgrade-question">{LocalizationManager.subscriptionDetailsComponent_needMore()}</div>
				<AppSpacer height="8px" />
				<div className="subscription-details-upgrade-title">{LocalizationManager.subscriptionDetailsComponent_upgradeToPremium()}</div>
				<AppSpacer height="8px" />
				<div className="subscription-details-upgrade-tagline">
					{LocalizationManager.subscriptionDetailsComponent_premiumPlanTagLine()}
					{" "}
					<a href="#" onClick={this.didSelectKnowMore.bind(this)}>{LocalizationManager.subscriptionDetailsComponent_premiumPlanKnowMore()}</a>
					</div>
				<AppSpacer height="40px" />
				<div className="subscription-order-summary-box">
					<div className="subscription-order-summary-plan-name">{LocalizationManager.subscriptionDetailsComponent_premiumPlanBoxTitle()}</div>
					<AppSpacer height="10px" />
					<div className="subscription-order-summary-duration">{LocalizationManager.subscriptionDetailsComponent_premiumPlanBoxDuration()}</div>
					<AppSpacer height="15px" />
					<div className="subscription-order-summary-total">{LocalizationManager.subscriptionDetailsComponent_premiumPlanBoxTotalTitle()}</div>
					<AppSpacer height="3px" />
					<div className="subscription-order-summary-amount">${ConfigurationManager.premiumPlanYearlyAmountInUsd}</div>
					<AppSpacer height="30px" />
					<PayPalScriptProvider
						options={{
							"client-id": ConfigurationManager.paypalBusinessClientId()
						}}
					>
						<PayPalButtons
							style={{ layout: "horizontal", color: "gold", label: "pay", height: 40, tagline: false}}
							createOrder={(data, actions) => this.paypalCreateOrder(data, actions)}
							onApprove={(data, actions) => this.paypalOnApprove(data, actions)}
							onError={(data, actions) => this.paypalOnError(data, actions)}
						/>
						<PaymentOptionsView didSelectContactUsCallback={this.didSelectContactUs.bind(this)} />
					</PayPalScriptProvider>
				</div>
			</div>

			<AppSpacer height="30px" />

		</div>

	</div>;

	return aReturnVal;
}

SubscriptionDetailsComponent.prototype.premiumSubscriptionView = function() {
	var aMaximumDiskSpace = ATUtilityManager.formattedByteSize(CacheManager.shared.loggedInCustomer.combinedActiveSubscriptionBenefit.maximumDiskSpaceInBytes);
	
	var aReturnVal = <div className="foundation-storey vertical-scroll-view">
		<div className="subscription-details-container">
			<AppSpacer height="10px" />
			<div className="subscription-details-icon"></div>
			<AppSpacer height="8px" />
			<div className="subscription-details-title">{LocalizationManager.subscriptionDetailsComponent_currentSubscriptionPremiumPlanTitle()}</div>
			<AppSpacer height="8px" />
			<div className="subscription-details-summary">{LocalizationManager.subscriptionDetailsComponent_currentSubscriptionPremiumPlanTagLine()}</div>
			<AppSpacer height="30px" />
			<div className="subscription-details-benefit-box">
				<div className="subscription-details-benefit-feature">
					✅&nbsp;&nbsp;
					{LocalizationManager.subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit01()}
				</div>
				<div className="subscription-details-benefit-feature">
					✅&nbsp;&nbsp;
					{LocalizationManager.subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit02()}
				</div>
				<div className="subscription-details-benefit-feature">
					✅&nbsp;&nbsp;{ aMaximumDiskSpace }
					{LocalizationManager.subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit03()}
				</div>
			</div>
		</div>
	</div>;

	return aReturnVal;
}

function PaymentOptionsView(props) {
	var aReturnVal = [];

	const [{ isInitial, isPending, isResolved, isRejected }] = usePayPalScriptReducer();
	if (isResolved !== true) {
		aReturnVal.push(
			<AppButton
				key="contact_us_button"
				title={LocalizationManager.subscriptionDetailsComponent_contactUsForPaymentOptions()}
				type={AppButtonType.arcRect}
				didSelectCallback={props.didSelectContactUsCallback.bind(this)}
			/>
		);
	}

	return aReturnVal;
}


// MARK:- Action Handlers

SubscriptionDetailsComponent.prototype.didSelectKnowMore = function() {
	RoutingManager.shared.gotoSearchPlan();
}

SubscriptionDetailsComponent.prototype.didSelectContactUs = function() {
	RoutingManager.shared.gotoSupportDetails();
}


// MARK:- Data Manager

SubscriptionDetailsComponent.prototype.newPayment = function(pPaypalOrderId) {
	var aPayment = new Payment();
	aPayment.paypalOrderId = pPaypalOrderId;

	var aThis = this;
	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.newPayment(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			var aMessage = LocalizationManager.common_apiResponseSuccessMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage, function() {
				CacheManager.shared.reloadLoggedInUser(true)
			});
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, aPayment);
}


// MARK:- Paypal Integration

SubscriptionDetailsComponent.prototype.paypalCreateOrder = function(pData, pActions) {
	var anOrder = pActions.order.create({
		purchase_units: [
			{
				amount: {
					value: ConfigurationManager.premiumPlanYearlyAmountInUsd,
					currency_code: ConfigurationManager.paypalCurrencyCode
				},
				custom_id: CacheManager.shared.loggedInCustomer.uuid
			},
		],
	});
	return anOrder;
}

SubscriptionDetailsComponent.prototype.paypalOnApprove = function(pApproveData, pActions) {
	var aThis = this;
	return pActions.order.capture().then(function(pOrderData) {
		aThis.newPayment(pOrderData.id)
	});
}

SubscriptionDetailsComponent.prototype.paypalOnError = function(pData, pActions) {
	var aMessage = LocalizationManager.common_unknownErrorMessage();
	PopupManager.shared.displayErrorMessage(aMessage);
}


export default withParams(SubscriptionDetailsComponent);
