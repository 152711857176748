import BaseComponent from '../app-ui-kit/base-component';
import { AppSpacer } from '../app-ui-kit/app-ui-kit';
import { LocalizationManager } from '../../localization-manager/localization-manager';
import '../../localization-manager/localization-manager-support'
import { RoutingManager } from '../../collaborators/routing-manager';

import "./support-details-component.css"


class SupportDetailsComponent extends BaseComponent {

	render() {
		return this.supportDetailsViewEmailOnly()
	}
	
	supportDetailsView() {
		return (
			<div className="foundation-storey vertical-scroll-view support-details-storey">

				<AppSpacer height="15px" />

				<div className="support-details-header">
					<span className="support-details-header-hightlighter">{LocalizationManager.supportDetailsComponent_header01()}</span>
					&nbsp;
					<span>{LocalizationManager.supportDetailsComponent_header02()}</span>
				</div>

				<AppSpacer height="20px" />

				<div className="support-details-subheader">{LocalizationManager.supportDetailsComponent_subheader()}</div>

				<AppSpacer height="120px" />

				<div className="support-details-table-row">
					<div className="support-details-box support-details-box-email">
						<AppSpacer height="30px" />
						<div className="support-details-box-header">{LocalizationManager.supportDetailsComponent_emailSupportHeader()}</div>
						<AppSpacer height="30px" />
						<div className="support-details-email-image"></div>
						<AppSpacer height="30px" />
						<div className="support-details-box-note">{LocalizationManager.supportDetailsComponent_emailSupportNote()}</div>
					</div>
					<div className="support-details-box support-details-box-inapp">
						<AppSpacer height="50px" />
						<div className="support-details-box-header">{LocalizationManager.supportDetailsComponent_inAppSupportHeader()}</div>
						<AppSpacer height="50px" />
						<div>
							<span className="support-details-download-app support-details-download-ios-app-image" onClick={this.didSelectDownloadIosApp.bind()}></span>
							<span className="support-details-download-app support-details-download-android-app-image" onClick={this.didSelectDownloadAndroidApp.bind()}></span>
						</div>
						<AppSpacer height="50px" />
						<div className="support-details-box-note">{LocalizationManager.supportDetailsComponent_inAppSupportNote()}</div>
						<AppSpacer height="50px" />
					</div>
				</div>

			</div>
		);
	}

	supportDetailsViewEmailOnly() {
		return (
			<div className="foundation-storey vertical-scroll-view support-details-storey">

				<AppSpacer height="15px" />

				<div className="support-details-header">
					<span className="support-details-header-hightlighter">{LocalizationManager.supportDetailsComponent_header01()}</span>
					&nbsp;
					<span>{LocalizationManager.supportDetailsComponent_header02()}</span>
				</div>

				<AppSpacer height="20px" />

				<div className="support-details-subheader">{LocalizationManager.supportDetailsComponent_subheader()}</div>

				<AppSpacer height="50px" />

				<div className="support-details-table-row">
					<div className="support-details-box support-details-box-email support-details-box-email-only">
						<AppSpacer height="40px" />
						<div className="support-details-box-header">{LocalizationManager.supportDetailsComponent_emailSupportHeader()}</div>
						<AppSpacer height="40px" />
						<div className="support-details-email-image"></div>
						<AppSpacer height="40px" />
						<div className="support-details-box-note">{LocalizationManager.supportDetailsComponent_emailSupportNote()}</div>
					</div>
				</div>

			</div>
		);
	}

	didSelectDownloadIosApp() {
		RoutingManager.shared.gotoDownloadIosApp()
	}

	didSelectDownloadAndroidApp() {
		RoutingManager.shared.gotoDownloadAndroidApp()
	}

}


export default SupportDetailsComponent;
