
class AndroidAppSpecification {
	bundleIdentifier = null;
	minimumSupportedVersion = null;
	latestVersion = null;
	downloadUrl = null;
	fcmAuthJsonData = null;
	fcmAuthJsonDataSha1 = null;
}


export { AndroidAppSpecification };
