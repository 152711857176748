import BaseComponent from '../app-ui-kit/base-component';
import React from 'react';
import { DataTransferManager, DataTransferManagerResultSignet } from "../../data-manager/data-transfer-manager"
import { ProgressIndicationManager } from '../../collaborators/progress-indication-manager';
import { ATEncryptionManager } from '../../third-party/com-aurvan-atkit-reactjs/src/at-encryption-manager';
import { RoutingManager } from '../../collaborators/routing-manager';
import { PopupManager } from '../../collaborators/popup-manager';
import { AppTextField } from '../app-ui-kit/app-text-field';
import { AppButton } from '../app-ui-kit/app-button';
import { Session } from '../../models/session';
import { Customer } from '../../models/customer';
import { CacheManager } from '../../collaborators/cache-manager';
import { LocalizationManager } from '../../localization-manager/localization-manager';
import '../../localization-manager/localization-manager-customer';

import "./signup-login-customer-component.css"


class SignupLoginCustomerComponent extends BaseComponent {

	constructor(props) {
		super(props);

		this.signUpEmailAddressValueTextField = React.createRef();
		this.signUpPasswordValueTextField = React.createRef();
		this.signUpConfirmPasswordValueTextField = React.createRef();
		this.signUpDisplayNameValueTextField = React.createRef();

		this.loginEmailAddressValueTextField = React.createRef();
		this.loginPasswordValueTextField = React.createRef();
	}

	componentDidMount() {
		super.componentDidMount();
		this.reloadAllData();
	}

	resetAllViews() {
		this.signUpEmailAddressValueTextField.current.value = null;
		this.signUpPasswordValueTextField.current.value = null;
		this.signUpConfirmPasswordValueTextField.current.value = null;
		this.signUpDisplayNameValueTextField.current.value = null;

		this.loginEmailAddressValueTextField.current.value = null;
		this.loginPasswordValueTextField.current.value = null;

		this.forceUpdate();
	}

	render() {
		return (
			<div className="foundation-storey vertical-scroll-view signup-login-storey">

				<div className="signup-login-box">

					<div className="signup-login-form">
						<div className="signup-login-form-heading">{LocalizationManager.signupLoginCustomerComponent_signupFormTitle()}</div>

						<AppTextField ref={this.signUpEmailAddressValueTextField} placeholder={LocalizationManager.signupLoginCustomerComponent_signupEmailPlaceholder()} didClickEnterCallback={this.didSelectSignUpButton.bind(this)} />

						<AppTextField ref={this.signUpDisplayNameValueTextField} placeholder={LocalizationManager.signupLoginCustomerComponent_signupDisplayNamePlaceholder()} didClickEnterCallback={this.didSelectSignUpButton.bind(this)} />

						<AppTextField ref={this.signUpPasswordValueTextField} placeholder={LocalizationManager.signupLoginCustomerComponent_signupPasswordPlaceholder()} shouldSecureTextEntry={true} didClickEnterCallback={this.didSelectSignUpButton.bind(this)} />

						<AppTextField ref={this.signUpConfirmPasswordValueTextField} placeholder={LocalizationManager.signupLoginCustomerComponent_signupConfirmPasswordPlaceholder()} shouldSecureTextEntry={true} didClickEnterCallback={this.didSelectSignUpButton.bind(this)} />

						<AppButton
							inputClassNames={["signup-login-form-button"]}
							title={LocalizationManager.signupLoginCustomerComponent_signupButtonTitle()}
							didSelectCallback={this.didSelectSignUpButton.bind(this)}
						/>

						<div className="signup-login-form-instruction">{LocalizationManager.signupLoginCustomerComponent_signupButtonInstructionAcceptTerms()}</div>

						<div className="signup-login-form-instruction">{LocalizationManager.signupLoginCustomerComponent_signupButtonInstructionNextStep()}</div>
					</div>

					<div className="signup-login-vertical-separator-container">
						<div className="signup-login-vertical-separator-line"></div>
						<div className="signup-login-vertical-separator-word">{LocalizationManager.signupLoginCustomerComponent_separatorOr()}</div>
						<div className="signup-login-vertical-separator-line"></div>
					</div>

					<div className="login-forgot-password-form-container">

						<div className="signup-login-form">
							<div className="signup-login-form-heading">{LocalizationManager.signupLoginCustomerComponent_loginFormTitle()}</div>

							<AppTextField ref={this.loginEmailAddressValueTextField} placeholder={LocalizationManager.signupLoginCustomerComponent_loginEmailPlaceholder()} didClickEnterCallback={this.didSelectLoginButton.bind(this)} />

							<AppTextField ref={this.loginPasswordValueTextField} placeholder={LocalizationManager.signupLoginCustomerComponent_loginPasswordPlaceholder()} shouldSecureTextEntry={true} didClickEnterCallback={this.didSelectLoginButton.bind(this)} />

							<AppButton
								inputClassNames={["signup-login-form-button"]}
								title={LocalizationManager.signupLoginCustomerComponent_loginButtonTitle()}
								didSelectCallback={this.didSelectLoginButton.bind(this)}
							/>

							<div className="signup-login-form-instruction">{LocalizationManager.signupLoginCustomerComponent_loginButtonInstructionAcceptTerms()}</div>
						</div>

						<div className="signup-login-horizontal-separator-line" style={{"marginRight": "10px"}}></div>

						<div className="signup-login-form">
							<div className="signup-login-form-heading">{LocalizationManager.signupLoginCustomerComponent_forgotPasswordFormTitle()}</div>
	
							<AppButton
								inputClassNames={["signup-login-form-button"]}
								title={LocalizationManager.signupLoginCustomerComponent_forgotPasswordButtonTitle()}
								didSelectCallback={this.didSelectGotoResetPasswordButton.bind(this)}
							/>
						</div>

					</div>
				
				</div>

			</div>
		);
	}


	didSelectSignUpButton() {
		try {
			var anEmailAddress = this.signUpEmailAddressValueTextField.current.value;
			if (anEmailAddress == null || anEmailAddress.length <= 0) {
				throw new Error("Please provide email address.");
			}

			var aDisplayName = this.signUpDisplayNameValueTextField.current.value;
			if (aDisplayName == null || aDisplayName.length <= 0) {
				throw new Error("Please provide your display name.");
			}

			var aPlainTextPassword = this.signUpPasswordValueTextField.current.value;
			if (aPlainTextPassword == null || aPlainTextPassword.length <= 0) {
				throw new Error("Please provide your password.");
			}
			var aPlainTextConfirmPassword = this.signUpConfirmPasswordValueTextField.current.value;
			if (aPlainTextPassword != aPlainTextConfirmPassword) {
				throw new Error("Password and Confirm Password do not match.");
			}
			var aPasswordHash = ATEncryptionManager.sha512(aPlainTextPassword);

			var aCustomer = new Customer();
			aCustomer.emailAddress = anEmailAddress;
			aCustomer.displayName = aDisplayName;
			aCustomer.passwordHash = aPasswordHash;

			var aThis = this;
			ProgressIndicationManager.shared.showLoadingOverlay();
			DataTransferManager.shared.registerCustomer(function(pResult) {
				ProgressIndicationManager.shared.hideLoadingOverlay();
				if (pResult.signet === DataTransferManagerResultSignet.success) {
					aThis.resetAllViews()
					if (pResult.outcome instanceof Customer) {
						aThis.customer = pResult.outcome;
					}
					var aMessage = "Request processed successfully.";
					if (pResult.localizedDescription != null) {
						aMessage = pResult.localizedDescription;
					}
					PopupManager.shared.displaySuccessMessage(aMessage);
				} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
					RoutingManager.shared.gotoSignUpLoginCustomer();
				} else {
					var aMessage = "Unknown error.";
					if (pResult.localizedDescription != null) {
						aMessage = pResult.localizedDescription;
					}
					PopupManager.shared.displayErrorMessage(aMessage);
				}
			}, aCustomer);
		} catch (pError) {
			PopupManager.shared.displayError(pError);
		}
	}

	didSelectLoginButton() {
		try {
			var anEmailAddress = this.loginEmailAddressValueTextField.current.value;
			if (anEmailAddress == null || anEmailAddress.length <= 0) {
				throw new Error("Please provide email address.");
			}

			var aPlainTextPassword = this.loginPasswordValueTextField.current.value;
			if (aPlainTextPassword == null || aPlainTextPassword.length <= 0) {
				throw new Error("Please provide your password.");
			}
			var aPasswordHash = ATEncryptionManager.sha512(aPlainTextPassword);
			var anEncryptedPassword = ATEncryptionManager.sha512(aPasswordHash + aPasswordHash);


			var aCustomer = new Customer();
			aCustomer.emailAddress = anEmailAddress;
			aCustomer.encryptedPassword = anEncryptedPassword;

			var aThis = this;
			ProgressIndicationManager.shared.showLoadingOverlay();
			DataTransferManager.shared.loginCustomer(function(pResult) {
				ProgressIndicationManager.shared.hideLoadingOverlay();
				if (pResult.signet === DataTransferManagerResultSignet.success) {
					aThis.resetAllViews();
					if (pResult.outcome instanceof Session) {
						CacheManager.shared.session = pResult.outcome;
					}
					RoutingManager.shared.gotoSearchProduct();
				} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
					RoutingManager.shared.gotoSignUpLoginCustomer();
				} else {
					var aMessage = "Unknown error.";
					if (pResult.localizedDescription != null) {
						aMessage = pResult.localizedDescription;
					}
					PopupManager.shared.displayErrorMessage(aMessage);
				}
			}, aCustomer);
		} catch (pError) {
			PopupManager.shared.displayError(pError);
		}
	}

	didSelectGotoResetPasswordButton() {
		RoutingManager.shared.gotoResetPassword();
	}

}

export default SignupLoginCustomerComponent;
