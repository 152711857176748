import { DataTransferManager, DataTransferManagerResult } from "./data-transfer-manager"
import { Session } from '../models/session';
import { PagedList } from '../models/paged-list';
import { Customer } from '../models/customer';
import { CustomerIdentity } from '../models/customer-identity';
import { Subscription } from "../models/subscription";
import { SubscriptionBenefit } from "../models/subscription-benefit";
import { Product } from '../models/product';
import { ProductApiKey } from '../models/product-api-key';
import { IosAppSpecification } from '../models/ios-app-specification';
import { AndroidAppSpecification } from '../models/android-app-specification';
import { Spectator } from '../models/spectator';
import { SearchTicketForm } from '../models/search-ticket-form';
import { UpdateTicketForm } from '../models/update-ticket-form';
import { Ticket } from '../models/ticket';
import { TicketState } from '../models/ticket-state';
import { TicketMessage } from '../models/ticket-message';
import { TicketMessageAttachment } from '../models/ticket-message-attachment';
import { ProductCustomerMap } from '../models/product-customer-map';
import { ProductInvitation } from '../models/product-invitation';
import { ProductCustomerRole } from '../models/product-customer-role';
import { UserType } from "../models/user-type";
import { TicketMessageAccessLevel } from "../models/ticket-message-access-level";
import { SubscriptionPlan } from "../models/subscription-plan";


class DataContractManagerHttp {

	static json(pData) {
		var aReturnVal = null;

		try {
			aReturnVal = JSON.parse(pData);
		} catch (pError) {
			throw new Error("Can not parse server response. Error: " + pError /*  + ". Data: " + pData */);
		}

		return aReturnVal;
	}


	static boolean(pDictionary) {
		var aReturnVal = null;

		aReturnVal = pDictionary;

		return aReturnVal;
	}


	static pagedList(pDictionary, pModelType) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aPagedList = new PagedList();
		aPagedList.listTotal = aDictionary.listTotal;
		aPagedList.listFrom = aDictionary.listFrom;
		aPagedList.listTo = aDictionary.listTo;
		aPagedList.itemsPerPage = aDictionary.itemsPerPage;
		if (pModelType === Ticket.name) {
			aPagedList.list = this.ticketArray(aDictionary.list);
		}

		aReturnVal = aPagedList;

		return aReturnVal;
	}


	static session(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aSession = new Session();
		aSession.token = aDictionary.token;

		aReturnVal = aSession;

		return aReturnVal;
	}


	static customer(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aCustomer = new Customer();
		aCustomer.uuid = aDictionary.uuid;
		aCustomer.displayName = aDictionary.displayName;
		if (aDictionary.subscriptions != null) {
			aCustomer.subscriptions = DataContractManagerHttp.subscriptionArray(aDictionary.subscriptions);
		}
		if (aDictionary.combinedActiveSubscriptionBenefit != null) {
			aCustomer.combinedActiveSubscriptionBenefit = DataContractManagerHttp.subscriptionBenefit(aDictionary.combinedActiveSubscriptionBenefit);
		}
		if (aDictionary.identities != null) {
			aCustomer.identities = this.customerIdentityArray(aDictionary.identities);
		}

		aReturnVal = aCustomer;

		return aReturnVal;
	}

	static customerArray(pDictionaryArray) {
		var aReturnVal = null;

		var aDictionaryArray = pDictionaryArray;
		if (aDictionaryArray instanceof Array && aDictionaryArray.length > 0) {
			aReturnVal = [];
			for (var anIndex = 0; anIndex < aDictionaryArray.length; anIndex ++) {
				aReturnVal.push(DataContractManagerHttp.customer(aDictionaryArray[anIndex]));
			}
		}

		return aReturnVal;
	}


	static customerIdentity(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aCustomerIdentity = new CustomerIdentity();
		aCustomerIdentity.uuid = aDictionary.uuid;
		aCustomerIdentity.typeSignet = aDictionary.typeSignet;
		aCustomerIdentity.value = aDictionary.value;
		aCustomerIdentity.verifiedOnUsdfDate = aDictionary.verifiedOnUsdfDate;

		aReturnVal = aCustomerIdentity;

		return aReturnVal;
	}

	static customerIdentityArray(pDictionaryArray) {
		var aReturnVal = null;

		var aDictionaryArray = pDictionaryArray;
		if (aDictionaryArray instanceof Array && aDictionaryArray.length > 0) {
			aReturnVal = [];
			for (var anIndex = 0; anIndex < aDictionaryArray.length; anIndex ++) {
				aReturnVal.push(DataContractManagerHttp.customerIdentity(aDictionaryArray[anIndex]));
			}
		}

		return aReturnVal;
	}


	static subscription(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aSubscription = new Subscription();
		aSubscription.uuid = aDictionary.uuid;
		aSubscription.addedOnUsdfDate = aDictionary.addedOnUsdfDate;
		aSubscription.planSignet = aDictionary.planSignet;
		aSubscription.activatesOnUsdfDate = aDictionary.activatesOnUsdfDate;
		aSubscription.expiresOnUsdfDate = aDictionary.expiresOnUsdfDate;

		aReturnVal = aSubscription;

		return aReturnVal;
	}
	

	static subscriptionArray(pDictionaryArray) {
		var aReturnVal = null;

		var aDictionaryArray = pDictionaryArray;
		if (aDictionaryArray instanceof Array && aDictionaryArray.length > 0) {
			aReturnVal = [];
			for (var anIndex = 0; anIndex < aDictionaryArray.length; anIndex ++) {
				aReturnVal.push(DataContractManagerHttp.subscription(aDictionaryArray[anIndex]));
			}
		}

		return aReturnVal;
	}


	static subscriptionBenefit(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aSubscriptionBenefit = new SubscriptionBenefit();
		aSubscriptionBenefit.maximumDiskSpaceInBytes = aDictionary.maximumDiskSpaceInBytes;

		aReturnVal = aSubscriptionBenefit;

		return aReturnVal;
	}


	static product(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aProduct = new Product();
		aProduct.uuid = aDictionary.uuid;
		aProduct.title = aDictionary.title;
		if (aDictionary.apiKey != null) {
			aProduct.apiKey = this.productApiKey(aDictionary.apiKey);
		}
		if (aDictionary.iosAppSpecification != null) {
			aProduct.iosAppSpecification = this.iosAppSpecification(aDictionary.iosAppSpecification);
		}
		if (aDictionary.androidAppSpecification != null) {
			aProduct.androidAppSpecification = this.androidAppSpecification(aDictionary.androidAppSpecification);
		}

		aReturnVal = aProduct;

		return aReturnVal;
	}

	static productArray(pDictionaryArray) {
		var aReturnVal = null;

		var aDictionaryArray = pDictionaryArray;
		if (aDictionaryArray instanceof Array && aDictionaryArray.length > 0) {
			aReturnVal = [];
			for (var anIndex = 0; anIndex < aDictionaryArray.length; anIndex ++) {
				aReturnVal.push(DataContractManagerHttp.product(aDictionaryArray[anIndex]));
			}
		}

		return aReturnVal;
	}


	static spectator(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aSpectator = new Spectator();
		aSpectator.uuid = aDictionary.uuid;
		aSpectator.displayName = aDictionary.displayName;

		if (aDictionary.product != null) {
			var aProduct = new Product();
			aProduct.uuid = aDictionary.product.uuid;
			aProduct.title = aDictionary.product.title;
			aSpectator.product = aProduct;
		}

		aReturnVal = aSpectator;

		return aReturnVal;
	}

	static spectatorArray(pDictionaryArray) {
		var aReturnVal = null;

		var aDictionaryArray = pDictionaryArray;
		if (aDictionaryArray instanceof Array && aDictionaryArray.length > 0) {
			aReturnVal = [];
			for (var anIndex = 0; anIndex < aDictionaryArray.length; anIndex ++) {
				aReturnVal.push(DataContractManagerHttp.spectator(aDictionaryArray[anIndex]));
			}
		}

		return aReturnVal;
	}


	static searchTicketForm(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aSearchTicketForm = new SearchTicketForm();
		if (aDictionary.productCustomerMaps != null) {
			aSearchTicketForm.productCustomerMaps = DataContractManagerHttp.productCustomerMapArray(aDictionary.productCustomerMaps);
		}
		if (aDictionary.ticketStates != null) {
			aSearchTicketForm.ticketStates = DataContractManagerHttp.ticketStateArray(aDictionary.ticketStates);
		}

		aReturnVal = aSearchTicketForm;

		return aReturnVal;
	}


	static updateTicketForm(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aUpdateTicketForm = new UpdateTicketForm();
		if (aDictionary.availableAssignees != null) {
			aUpdateTicketForm.availableAssignees = DataContractManagerHttp.customerArray(aDictionary.availableAssignees);
		}

		aReturnVal = aUpdateTicketForm;

		return aReturnVal;
	}


	static ticket(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aTicket = new Ticket();
		aTicket.uuid = aDictionary.uuid;
		aTicket.addedOnUsdfDate = aDictionary.addedOnUsdfDate;
		aTicket.title = aDictionary.title;
		if (aDictionary.author != null) {
			aTicket.author = DataContractManagerHttp.spectator(aDictionary.author);
		}
		if (aDictionary.assignee != null) {
			aTicket.assignee = DataContractManagerHttp.customer(aDictionary.assignee);
		}
		if (aDictionary.stateSignet != null) {
			aTicket.state = new TicketState(aDictionary.stateSignet);
		}
		if (aDictionary.latestMessage != null) {
			aTicket.latestMessage = DataContractManagerHttp.ticketMessage(aDictionary.latestMessage);
		}
		if (aDictionary.messages != null) {
			aTicket.messages = DataContractManagerHttp.ticketMessageArray(aDictionary.messages);
		}

		aReturnVal = aTicket;

		return aReturnVal;
	}

	static ticketArray(pDictionaryArray) {
		var aReturnVal = null;

		var aDictionaryArray = pDictionaryArray;
		if (aDictionaryArray instanceof Array && aDictionaryArray.length > 0) {
			aReturnVal = [];
			for (var anIndex = 0; anIndex < aDictionaryArray.length; anIndex ++) {
				aReturnVal.push(DataContractManagerHttp.ticket(aDictionaryArray[anIndex]));
			}
		}

		return aReturnVal;
	}


	static ticketState(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aTicketState = new TicketState(aDictionary.signet);

		aReturnVal = aTicketState;

		return aReturnVal;
	}

	static ticketStateArray(pDictionaryArray) {
		var aReturnVal = null;

		var aDictionaryArray = pDictionaryArray;
		if (aDictionaryArray instanceof Array && aDictionaryArray.length > 0) {
			aReturnVal = [];
			for (var anIndex = 0; anIndex < aDictionaryArray.length; anIndex ++) {
				aReturnVal.push(DataContractManagerHttp.ticketState(aDictionaryArray[anIndex]));
			}
		}

		return aReturnVal;
	}


	static ticketMessage(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aTicketMessage = new TicketMessage();
		aTicketMessage.uuid = aDictionary.uuid;
		aTicketMessage.addedOnUsdfDate = aDictionary.addedOnUsdfDate;
		aTicketMessage.description = aDictionary.description;
		if (aDictionary.author != null) {
			if (aDictionary.author.userTypeSignet === UserType.spectator.signet()) {
				aTicketMessage.author = this.spectator(aDictionary.author);
			} else if (aDictionary.author.userTypeSignet === UserType.customer.signet()) {
				aTicketMessage.author = this.customer(aDictionary.author);
			}
		}
		if (aDictionary.accessLevelSignet != null) {
			aTicketMessage.accessLevel = new TicketMessageAccessLevel(aDictionary.accessLevelSignet);
		}
		if (aDictionary.attachments != null) {
			aTicketMessage.attachments = this.ticketMessageAttachmentArray(aDictionary.attachments);
		}

		aReturnVal = aTicketMessage;

		return aReturnVal;
	}

	static ticketMessageArray(pDictionaryArray) {
		var aReturnVal = null;

		var aDictionaryArray = pDictionaryArray;
		if (aDictionaryArray instanceof Array && aDictionaryArray.length > 0) {
			aReturnVal = [];
			for (var anIndex = 0; anIndex < aDictionaryArray.length; anIndex ++) {
				aReturnVal.push(DataContractManagerHttp.ticketMessage(aDictionaryArray[anIndex]));
			}
		}

		return aReturnVal;
	}


	static ticketMessageAttachment(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aTicketMessageAttachment = new TicketMessageAttachment();
		aTicketMessageAttachment.uuid = aDictionary.uuid;
		aTicketMessageAttachment.addedOnUsdfDate = aDictionary.addedOnUsdfDate;
		aTicketMessageAttachment.extension = aDictionary.extension;
		aTicketMessageAttachment.sizeInBytes = aDictionary.sizeInBytes;
		aTicketMessageAttachment.downloadUrl = aDictionary.downloadUrl;

		aReturnVal = aTicketMessageAttachment;

		return aReturnVal;
	}

	static ticketMessageAttachmentArray(pDictionaryArray) {
		var aReturnVal = null;

		var aDictionaryArray = pDictionaryArray;
		if (aDictionaryArray instanceof Array && aDictionaryArray.length > 0) {
			aReturnVal = [];
			for (var anIndex = 0; anIndex < aDictionaryArray.length; anIndex ++) {
				aReturnVal.push(DataContractManagerHttp.ticketMessageAttachment(aDictionaryArray[anIndex]));
			}
		}

		return aReturnVal;
	}


	static productCustomerMap(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aProductCustomerMap = new ProductCustomerMap();
		aProductCustomerMap.uuid = aDictionary.uuid;
		if (aDictionary.product != null) {
			aProductCustomerMap.product = DataContractManagerHttp.product(aDictionary.product);
		}
		if (aDictionary.customer != null) {
			aProductCustomerMap.customer = DataContractManagerHttp.customer(aDictionary.customer);
		}
		if (aDictionary.roleSignet != null) {
			aProductCustomerMap.role = new ProductCustomerRole(aDictionary.roleSignet);
		}

		aReturnVal = aProductCustomerMap;

		return aReturnVal;
	}

	static productCustomerMapArray(pDictionaryArray) {
		var aReturnVal = null;

		var aDictionaryArray = pDictionaryArray;
		if (aDictionaryArray instanceof Array && aDictionaryArray.length > 0) {
			aReturnVal = [];
			for (var anIndex = 0; anIndex < aDictionaryArray.length; anIndex ++) {
				aReturnVal.push(DataContractManagerHttp.productCustomerMap(aDictionaryArray[anIndex]));
			}
		}

		return aReturnVal;
	}


	static productInvitation(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aProductInvitation = new ProductInvitation();
		aProductInvitation.uuid = aDictionary.uuid;
		if (aDictionary.product != null) {
			aProductInvitation.product = DataContractManagerHttp.product(aDictionary.product);
		}
		if (aDictionary.emailAddress != null) {
			aProductInvitation.emailAddress = aDictionary.emailAddress;
		}
		if (aDictionary.roleSignet != null) {
			aProductInvitation.role = new ProductCustomerRole(aDictionary.roleSignet);
		}

		aReturnVal = aProductInvitation;

		return aReturnVal;
	}

	static productInvitationArray(pDictionaryArray) {
		var aReturnVal = null;

		var aDictionaryArray = pDictionaryArray;
		if (aDictionaryArray instanceof Array && aDictionaryArray.length > 0) {
			aReturnVal = [];
			for (var anIndex = 0; anIndex < aDictionaryArray.length; anIndex ++) {
				aReturnVal.push(DataContractManagerHttp.productInvitation(aDictionaryArray[anIndex]));
			}
		}

		return aReturnVal;
	}


	static productApiKey(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var aProductApiKey = new ProductApiKey();
		aProductApiKey.key = aDictionary.key;
		aProductApiKey.addedOnUsdfDate = aDictionary.addedOnUsdfDate;

		aReturnVal = aProductApiKey;

		return aReturnVal;
	}


	static iosAppSpecification(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var anIosAppSpecification = new IosAppSpecification();
		anIosAppSpecification.bundleIdentifier = aDictionary.bundleIdentifier;
		anIosAppSpecification.minimumSupportedVersion = aDictionary.minimumSupportedVersion;
		anIosAppSpecification.latestVersion = aDictionary.latestVersion;
		anIosAppSpecification.downloadUrl = aDictionary.downloadUrl;
		anIosAppSpecification.appleTeamId = aDictionary.appleTeamId;
		anIosAppSpecification.apnsAuthKeyId = aDictionary.apnsAuthKeyId;
		anIosAppSpecification.apnsAuthKeyDataSha1 = aDictionary.apnsAuthKeyDataSha1;

		aReturnVal = anIosAppSpecification;

		return aReturnVal;
	}


	static androidAppSpecification(pDictionary) {
		var aReturnVal = null;

		var aDictionary = pDictionary;

		var anAndroidAppSpecification = new AndroidAppSpecification();
		anAndroidAppSpecification.bundleIdentifier = aDictionary.bundleIdentifier;
		anAndroidAppSpecification.minimumSupportedVersion = aDictionary.minimumSupportedVersion;
		anAndroidAppSpecification.latestVersion = aDictionary.latestVersion;
		anAndroidAppSpecification.downloadUrl = aDictionary.downloadUrl;
		anAndroidAppSpecification.fcmAuthJsonDataSha1 = aDictionary.fcmAuthJsonDataSha1;

		aReturnVal = anAndroidAppSpecification;

		return aReturnVal;
	}


	static registerCustomerResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.customer(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static loginCustomerResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.session(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static logoutCustomerResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.boolean(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static newResetCustomerPasswordCodeResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.boolean(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static resetCustomerPasswordResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.boolean(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static profileDetailsResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.customer(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static updateCustomerProfileResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.customer(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static updateCustomerPasswordResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.customer(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static newCustomerIdentityResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.customerIdentity(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static verifyCustomerIdentityResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.customerIdentity(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static newVerifyCustomerIdentityCodeResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.customerIdentity(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static deleteCustomerIdentityResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.customerIdentity(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static newProductResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.product(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static productDetailsResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.product(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static updateProductResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.product(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static updateIosAppSpecificationResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.iosAppSpecification(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static searchProductCustomerMapResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.productCustomerMapArray(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static deleteProductCustomerMapResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.boolean(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static newProductInvitationResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.productInvitation(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static acceptProductInvitationResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.boolean(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static searchProductInvitationResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.productInvitationArray(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static deleteProductInvitationResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.boolean(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static newProductApiKeyResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.productApiKey(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}


	static searchTicketResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.pagedList(aResponseBodyObject.payload.outcome, Ticket.name);
		}

		return aReturnVal;
	}

	static updateTicketFormDetailsResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.updateTicketForm(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}

	static ticketDetailsResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.ticket(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}

	static updateTicketResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.ticket(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}

	static newTicketMessageResult(pResponseData) {
		var aReturnVal = new DataTransferManagerResult();

		// Convert json to object
		var aResponseBodyObject = DataContractManagerHttp.json(pResponseData);

		// Map signet
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.signet != null) {
			aReturnVal.signet = aResponseBodyObject.payload.signet;
		}

		// Map localized description
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.localizedDescription != null) {
			aReturnVal.localizedDescription = aResponseBodyObject.payload.localizedDescription;
		}

		// Map outcome
		if (aResponseBodyObject != null && aResponseBodyObject.payload != null && aResponseBodyObject.payload.outcome != null) {
			aReturnVal.outcome = DataContractManagerHttp.ticketMessage(aResponseBodyObject.payload.outcome);
		}

		return aReturnVal;
	}

}


export {DataContractManagerHttp}
