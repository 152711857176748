
class IosAppSpecification {
	bundleIdentifier = null;
	minimumSupportedVersion = null;
	latestVersion = null;
	downloadUrl = null;
	appleTeamId = null;
	apnsAuthKeyId = null;
	apnsAuthKeyData = null;
	apnsAuthKeyDataSha1 = null;
}


export { IosAppSpecification };
