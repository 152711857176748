import { Component } from 'react';

import { ObservationEventName, ObservationManager } from '../../collaborators/observation-manager';
import { LocalizationManager } from '../../localization-manager/localization-manager';


class BaseComponent extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var aThis = this;

        ObservationManager.shared.addObserver(function() {
            aThis.reloadAllData();
        }, ObservationEventName.didChangeAppLocale);
        
        ObservationManager.shared.addObserver(function() {
            aThis.reloadAllData();
        }, ObservationEventName.didFinishReloadLoggedInUser);
    }

    reloadAllData() {
        this.reloadAllViews()
    }

    reloadAllViews() {
        this.forceUpdate();
    }

    loginRequiredView() {
        return (<div className="no-data-view">{LocalizationManager.common_loginRequiredMessage()}</div>);
    }

}

export default BaseComponent;