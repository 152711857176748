import { LocalizationManager } from './localization-manager';

LocalizationManager.localizationManager_englishLocaleTitle = function() {
	return LocalizationManager.localizedString("localizationManager_englishLocaleTitle");
}

LocalizationManager.localizationManager_hindiLocaleTitle = function() {
	return LocalizationManager.localizedString("localizationManager_hindiLocaleTitle");
}

LocalizationManager.localizationManager_thaiLocaleTitle = function() {
	return LocalizationManager.localizedString("localizationManager_thaiLocaleTitle");
}


LocalizationManager.common_apiResponseSuccessMessage = function() {
	return LocalizationManager.localizedString("common_apiResponseSuccessMessage");
}

LocalizationManager.common_unknownApiResponseSignet = function() {
	return LocalizationManager.localizedString("common_unknownApiResponseSignet");
}

LocalizationManager.common_unknownErrorMessage = function() {
	return LocalizationManager.localizedString("common_unknownErrorMessage");
}

LocalizationManager.common_dateFormatDateAndTime = function() {
	return LocalizationManager.localizedString("common_dateFormatDateAndTime");
}

LocalizationManager.common_loginRequiredMessage = function() {
	return LocalizationManager.localizedString("common_loginRequiredMessage");
}
