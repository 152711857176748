import BaseComponent from '../app-ui-kit/base-component';
import { RoutingManager } from "../../collaborators/routing-manager"
import { DataTransferManager, DataTransferManagerResultSignet } from "../../data-manager/data-transfer-manager"
import { ProgressIndicationManager } from '../../collaborators/progress-indication-manager';
import { PopupManager } from '../../collaborators/popup-manager';
import { AppTable, AppTableRow, AppTableCell } from '../app-ui-kit/app-table';

import "../app-ui-kit/app-ui-kit.css"
import "./search-product-component.css"
import { CacheManager } from '../../collaborators/cache-manager';
import { LocalizationManager } from '../../localization-manager/localization-manager';


class SearchProductComponent extends BaseComponent {
	productCustomerMaps = null;

	reloadAllData() {
		this.searchAssignedProducts();
	}

	render() {
		var aReturnVal = this.loginRequiredView();
		if (CacheManager.shared.loggedInCustomer != null) {
			aReturnVal = this.searchProductView();
		}
		return aReturnVal;
	}

}


// MARK:- Data Manager

SearchProductComponent.prototype.searchAssignedProducts = function() {
	if (CacheManager.shared.loggedInCustomer != null) {
		var aThis = this;
		ProgressIndicationManager.shared.showLoadingOverlay();
		DataTransferManager.shared.searchProductCustomerMap(function(pResult) {
			ProgressIndicationManager.shared.hideLoadingOverlay();
			if (pResult.signet === DataTransferManagerResultSignet.success) {
				if (pResult.outcome instanceof Array) {
					aThis.productCustomerMaps = pResult.outcome;
				} else {
					aThis.productCustomerMaps = null;
				}
			} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
				RoutingManager.shared.gotoSignUpLoginCustomer();
			} else {
				var aMessage = LocalizationManager.common_unknownApiResponseSignet();
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displayErrorMessage(aMessage);
			}
			aThis.reloadAllViews();
		}, null, CacheManager.shared.loggedInCustomer.uuid);
	}
}


// MARK:- Views

SearchProductComponent.prototype.searchProductView = function() {
	// Create table rows
	var aProductRowArray = [];
	var aNoDataView = null;

	var aHeaderRow = new AppTableRow(
		[
			AppTableCell.initWithTitle(LocalizationManager.searchProductComponent_titleRowTitle())
			, AppTableCell.initWithTitle(LocalizationManager.searchProductComponent_roleRowTitle(), ["product-details-role-cell"])
		]
		, null
		, null
		, true
	);
	aProductRowArray.push(aHeaderRow);

	if (this.productCustomerMaps instanceof Array && this.productCustomerMaps.length > 0) {
		for (var anIndex = 0; anIndex < this.productCustomerMaps.length; anIndex ++) {
			var aProductCustomerMap = this.productCustomerMaps[anIndex];
			var aCellArray = [];
			aCellArray.push(AppTableCell.initWithTitle(aProductCustomerMap.product.title));
			aCellArray.push(AppTableCell.initWithTitle(aProductCustomerMap.role.localizedTitle(), ["product-details-role-cell"]));;
			aProductRowArray.push(new AppTableRow(aCellArray, null, anIndex));
		}
	} else {
		aNoDataView = <div className="no-data-view">{LocalizationManager.searchProductComponent_noDataMessage()}</div>;
	}

	return (
		<div className="foundation-storey vertical-scroll-view">
			<AppTable data={aProductRowArray} didSelectRowCallback={this.didSelectRow.bind(this)} />
			{aNoDataView}
		</div>
	);
}


// MARK:- Actions

SearchProductComponent.prototype.didSelectRow = function(pRowIndex) {
	if (this.productCustomerMaps instanceof Array && this.productCustomerMaps.length > pRowIndex) {
		var aProductCustomerMap = this.productCustomerMaps[pRowIndex];
		RoutingManager.shared.gotoProductDetails(aProductCustomerMap.product.uuid);
	}
}


export default SearchProductComponent;
