
class Customer {
	uuid = null;
	addedOnUsdfDate = null;

	emailAddress = null;
	passwordHash = null;
	displayName = null;
	identities = null;
	subscriptions = null;
	combinedActiveSubscriptionBenefit = null;
}


export { Customer };
