import React, { Component } from 'react';

import { DataTransferManager, DataTransferManagerResultSignet } from '../../data-manager/data-transfer-manager';
import { CacheManager } from '../../collaborators/cache-manager';
import { ProgressIndicationManager } from '../../collaborators/progress-indication-manager';
import { ObservationManager, ObservationEventName } from '../../collaborators/observation-manager';
import { RoutingManager } from '../../collaborators/routing-manager';
import { LocalizationManager } from '../../localization-manager/localization-manager';
import '../../localization-manager/localization-manager-foundation';

import './ceiling-bar-component.css';
import { AppSpacer } from '../app-ui-kit/app-ui-kit';


class CeilingBarComponent extends Component {


	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.reloadAllViews();

		var aThis = this;
		ObservationManager.shared.addObserver(function() {
			aThis.reloadAllData();
		}, ObservationEventName.didFinishReloadLoggedInUser);
	}

	reloadAllData() {
		this.reloadAllViews();
	}

	reloadAllViews() {
		var aDisplayName = LocalizationManager.ceilingBarComponent_guestUserDisplayName();
		if (CacheManager.shared.loggedInCustomer != null && CacheManager.shared.loggedInCustomer.displayName != null) {
			aDisplayName = CacheManager.shared.loggedInCustomer.displayName;
		}
		this.userDisplayName = aDisplayName;

		this.forceUpdate();
	}

	render() {
		return (
			<div className="ceiling-bar">

				<div className="ceiling-bar-header" ref={this.props.headerLabelReference}></div>

				<div className="ceiling-bar-spacer"></div>

				<div className="ceiling-bar-user-box">
					<div className="ceiling-bar-user-subtext">{LocalizationManager.ceilingBarComponent_welcomeMessage()}</div>
					<AppSpacer height="6px" />
					<div className="ceiling-bar-user-text">{this.userDisplayName}</div>
				</div>

				<div style={{"width":"20px"}}></div>

				<div className="ceiling-bar-logout-button" onClick={this.didSelectLogout.bind(this)}></div>

			</div>
		);
	}

	didSelectLogout() {
		ProgressIndicationManager.shared.showLoadingOverlay();
		DataTransferManager.shared.logoutCustomer(function(pResult) {
			ProgressIndicationManager.shared.hideLoadingOverlay();
			CacheManager.shared.session = null;
			RoutingManager.shared.gotoSignUpLoginCustomer();
		});
	}

}


export default React.forwardRef((props, ref) => <CeilingBarComponent headerLabelReference={ref} {...props}/>);
