import BaseComponent from '../app-ui-kit/base-component';
import { ConfigurationManager } from '../../collaborators/configuration-manager';
import { AppSpacer } from '../app-ui-kit/app-ui-kit';
import { AppButton, AppButtonType } from '../app-ui-kit/app-button';
import { LocalizationManager } from '../../localization-manager/localization-manager';
import '../../localization-manager/localization-manager-subscription';
import { RoutingManager } from '../../collaborators/routing-manager';

import "./search-plan-component.css"
import { CacheManager } from '../../collaborators/cache-manager';


class SearchPlanComponent extends BaseComponent {

	render() {
		return this.freeForeverView()
	}

	simplePricingView() {
		var aGoToPurchaseButton = null
		if (CacheManager.shared.loggedInCustomer != null) {
				aGoToPurchaseButton = <AppButton
				title={LocalizationManager.searchPlanComponent_gotoSubscriptionDetailsToPurchaseButtonTitle()}
				type={AppButtonType.arcRect}
				didSelectCallback={this.didSelectGoToSubscriptionDetails.bind(this)}
				inputClassNames={["search-plan-purchase-arc-button"]}
			/>
		} else {
			aGoToPurchaseButton = <AppButton
				title={LocalizationManager.searchPlanComponent_loginToPurchaseButtonTitle()}
				type={AppButtonType.arcRect}
				didSelectCallback={this.didSelectGoToSignUpLogin.bind(this)}
				inputClassNames={["search-plan-purchase-arc-button"]}
			/>
		}
		return (
			<div className="foundation-storey vertical-scroll-view search-plan-storey">

				<AppSpacer height="15px" />

				<div className="search-plan-header">
					<span className="search-plan-header-hightlighter">{LocalizationManager.searchPlanComponent_header01()}</span>
					&nbsp;
					<span>{LocalizationManager.searchPlanComponent_header02()}</span>
				</div>

				<AppSpacer height="20px" />

				<div className="search-plan-subheader">{LocalizationManager.searchPlanComponent_subheader01()}</div>

				<AppSpacer height="20px" />

				<div className="search-plan-tagline">{LocalizationManager.searchPlanComponent_subheader02()}</div>

				<AppSpacer height="30px" />

				{aGoToPurchaseButton}

				<AppSpacer height="40px" />

				<div className="search-plan-table-row">
					<div className="search-plan-box search-plan-box-basic">
						<AppSpacer height="20px" />
						<div className="search-plan-name">{LocalizationManager.searchPlanComponent_basicPlanName()}</div>
						<AppSpacer height="10px" />
						<div className="search-plan-note">{LocalizationManager.searchPlanComponent_basicPlanNote()}</div>
						<AppSpacer height="20px" />
						<div className="search-plan-amount">{LocalizationManager.searchPlanComponent_basicPlanAmount()}</div>
					</div>
					<div className="search-plan-box search-plan-box-premium">
						<AppSpacer height="25px" />
						<div className="search-plan-name">{LocalizationManager.searchPlanComponent_premiumPlanName()}</div>
						<AppSpacer height="15px" />
						<div className="search-plan-note">{LocalizationManager.searchPlanComponent_premiumPlanNote()}</div>
						<AppSpacer height="25px" />
						<div className="search-plan-amount">
							{
							LocalizationManager.searchPlanComponent_premiumPlanAmountMonthly(
								"$"
								, (ConfigurationManager.premiumPlanYearlyAmountInUsd / 12).toPrecision(2)
							)
							}
							</div>
						<AppSpacer height="25px" />
						<div className="search-plan-note">
							{
							LocalizationManager.searchPlanComponent_premiumPlanAmountYearly(
								"$"
								, ConfigurationManager.premiumPlanYearlyAmountInUsd
							)
							}
						</div>
					</div>
				</div>

				<AppSpacer height="30px" />

				<div className="search-plan-details-row">
					<div className="search-plan-details-box">
						<div className="search-plan-details-feature">✅&nbsp;&nbsp;{LocalizationManager.searchPlanComponent_basicPlanDetails01()}</div>
						<div className="search-plan-details-feature">✅&nbsp;&nbsp;{LocalizationManager.searchPlanComponent_basicPlanDetails02()}</div>
						<div className="search-plan-details-feature">❎&nbsp;&nbsp;{LocalizationManager.searchPlanComponent_basicPlanDetails03()}</div>
					</div>
					<div className="search-plan-details-box">
					<div className="search-plan-details-feature">✅&nbsp;&nbsp;{LocalizationManager.searchPlanComponent_premiumPlanDetails01()}</div>
					<div className="search-plan-details-feature">✅&nbsp;&nbsp;{LocalizationManager.searchPlanComponent_premiumPlanDetails02()}</div>
					<div className="search-plan-details-feature">✅&nbsp;&nbsp;{LocalizationManager.searchPlanComponent_premiumPlanDetails03()}</div>
					</div>
				</div>

			</div>
		);
	}

	freeForeverView() {
		return (
			<div className="foundation-storey vertical-scroll-view search-plan-storey">

				<AppSpacer height="15px" />

				<div className="search-plan-header">
					<span className="search-plan-header-hightlighter">{LocalizationManager.searchPlanComponent_freeForever_header01()}</span>
					&nbsp;
					<span>{LocalizationManager.searchPlanComponent_freeForever_header02()}</span>
				</div>

				<AppSpacer height="20px" />

				<div className="search-plan-subheader">{LocalizationManager.searchPlanComponent_freeForever_subheader01()}</div>

				<AppSpacer height="20px" />

				<div className="search-plan-tagline">{LocalizationManager.searchPlanComponent_freeForever_subheader02()}</div>

				<AppSpacer height="40px" />

				<div className="search-plan-table-row">
					<div className="search-plan-box search-plan-box-free">
						<AppSpacer height="25px" />
						<div className="search-plan-name">{LocalizationManager.searchPlanComponent_freeForever_basicPlanName()}</div>
						<AppSpacer height="15px" />
						<div className="search-plan-note">{LocalizationManager.searchPlanComponent_freeForever_basicPlanNote()}</div>
						<AppSpacer height="25px" />
						<div className="search-plan-amount">{LocalizationManager.searchPlanComponent_freeForever_basicPlanAmount()}</div>
						<AppSpacer height="25px" />
						<div className="search-plan-note">{LocalizationManager.searchPlanComponent_freeForever_basicPlanTagline()}</div>
					</div>
				</div>

				<AppSpacer height="30px" />

				<div className="search-plan-details-row">
					<div className="search-plan-details-box">
						<div className="search-plan-details-feature">✅&nbsp;&nbsp;{LocalizationManager.searchPlanComponent_freeForever_basicPlanDetails01()}</div>
						<div className="search-plan-details-feature">✅&nbsp;&nbsp;{LocalizationManager.searchPlanComponent_freeForever_basicPlanDetails02()}</div>
						<div className="search-plan-details-feature">✅&nbsp;&nbsp;{LocalizationManager.searchPlanComponent_freeForever_basicPlanDetails03()}</div>
						<div className="search-plan-details-feature">✅&nbsp;&nbsp;{LocalizationManager.searchPlanComponent_freeForever_basicPlanDetails04()}</div>
						{/* <div className="search-plan-details-feature">✅&nbsp;&nbsp;Imperative statistics and reports</div> */}
					</div>
				</div>

			</div>
		);
	}

	didSelectGoToSignUpLogin() {
		RoutingManager.shared.gotoSignUpLoginCustomer();
	}

	didSelectGoToSubscriptionDetails() {
		RoutingManager.shared.gotoSubscriptionDetails();
	}

}


export default SearchPlanComponent;
