
class RequestParameter {
	static ticketStateSignet = new RequestParameter("ticket_state_signet");
	static ticketAssigneeUuid = new RequestParameter("ticket_assignee_uuid");

	static iosAppBundleIdentifier = new RequestParameter("ios_app_bundle_identifier");
	static iosAppMinimumSupportedVersion = new RequestParameter("ios_app_minimum_supported_version");
	static iosAppLatestVersion = new RequestParameter("ios_app_latest_version");
	static iosAppDownloadUrl = new RequestParameter("ios_app_download_url");
	static iosAppAppleTeamId = new RequestParameter("ios_app_apple_team_id");
	static iosAppApnsAuthKeyId = new RequestParameter("ios_app_apns_auth_key_id");
	static iosAppApnsAuthKeyData = new RequestParameter("ios_app_apns_auth_key_data");

	static androidAppBundleIdentifier = new RequestParameter("android_app_bundle_identifier");
	static androidAppMinimumSupportedVersion = new RequestParameter("android_app_minimum_supported_version");
	static androidAppLatestVersion = new RequestParameter("android_app_latest_version");
	static androidAppDownloadUrl = new RequestParameter("android_app_download_url");
	static androidAppFcmAuthJsonData = new RequestParameter("android_app_fcm_auth_json_data");

	_signet = null;

	constructor(pSignet) {
		this._signet = pSignet;
	}

	signet() {
		return this._signet;
	}

	equals(pRhs) {
		return this.signet() === pRhs.signet();
	}

	static signetCsv(pRequestParameterArray) {
		var aSignetArray = [];
		pRequestParameterArray.forEach(function(pItem) {
			aSignetArray.push(pItem.signet());
		});
		return aSignetArray.join(",");
	}

}


export { RequestParameter };
