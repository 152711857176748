import { ATDateManager } from "../third-party/com-aurvan-atkit-reactjs/src/at-date-manager";

class Subscription {
	uuid = null;
	addedOnUsdfDate = null;
	planSignet = null;
	activatesOnUsdfDate = null;
	expiresOnUsdfDate = null;
	payment = null;

	static isSubscriptionPlanActive(pSubscriptionArray, pSubscriptionPlan) {
		var aReturnVal = false;
		if (pSubscriptionArray != null) {
			for (var anIndex = 0; anIndex < pSubscriptionArray.length; anIndex ++) {
				var aSubscription = pSubscriptionArray[anIndex];
				if (aSubscription.planSignet == pSubscriptionPlan.signet()
				&& ATDateManager.isDateGreaterThanOrEqualTo(ATDateManager.usdfDateNow(), aSubscription.activatesOnUsdfDate)
				&& ATDateManager.isDateGreaterThan(aSubscription.expiresOnUsdfDate, ATDateManager.usdfDateNow())) {
					aReturnVal = true;
					break;
				}
			}
		}
		return aReturnVal;
	}
}


export { Subscription };
