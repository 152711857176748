import { DataContractManagerHttp } from "../data-manager/data-contract-manager-http";

import '../third-party/scripts/iziToast/iziToast.css';

const IziToast = require('../third-party/scripts/iziToast/iziToast');

class PopupManager {
	static shared = new PopupManager();

	displaySuccessMessage(pMessage, pCompletion) {
		IziToast.show({
			position: "topCenter",
			messageColor: "#FFFFFF",
			backgroundColor: "#51a351",
			timeout: 3000,
			message: pMessage
		});
		if (pCompletion != null && pCompletion != undefined) {
			setTimeout(pCompletion, 3000)
		}
	}

	displayErrorMessage(pMessage, pCompletion) {
		IziToast.show({
			position: "topCenter",
			messageColor: "#FFFFFF",
			backgroundColor: "#bd362f",
			timeout: 3000,
			message: pMessage
		});
		if (pCompletion != null && pCompletion != undefined) {
			setTimeout(pCompletion, 3000)
		}
	}

	displayError(pError, pCompletion) {
		this.displayErrorMessage(pError.message, pCompletion);
	}

	displayWarningMessage(pMessage, pCompletion) {
		IziToast.show({
			position: "bottomCenter",
			messageColor: "#FFFFFF",
			backgroundColor: "#f89406",
			timeout: 3000,
			message: pMessage
		});
		if (pCompletion != null && pCompletion != undefined) {
			setTimeout(pCompletion, 3000)
		}
	}

	displayInformationMessage(pMessage, pCompletion) {
		IziToast.show({
			position: "bottomCenter",
			messageColor: "#FFFFFF",
			backgroundColor: "#2f96b4",
			timeout: 3000,
			message: pMessage
		});
		if (pCompletion != null && pCompletion != undefined) {
			setTimeout(pCompletion, 3000)
		}
	}

	displayConfirmationMessage(pMessage, pCompletion) {
		var aResult = window.confirm(pMessage);
		if (pCompletion != null && pCompletion != undefined) {
			if (aResult === true) {
				pCompletion();
			}
		}
	}


	displayCustomPopup(pHtml) {
		IziToast.show({
			position: "center",
			messageColor: "rgba(80,80,80,1.0)",
			backgroundColor: "rgba(255,249,178,1.0)",
			timeout: false,
			message: pHtml,
			close: true,
			progressBar: false,
			transitionIn: "fadeInUp"
		});
	}
}


export { PopupManager }
