import React, { Component } from 'react';


class AppSpacer extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		var aStyleDict = {}
		if (this.props.height != null) {
			aStyleDict["height"] = this.props.height;
			aStyleDict["minHeight"] = this.props.height;
		} else if (this.props.width != null) {
			aStyleDict["width"] = this.props.width;
			aStyleDict["minWidth"] = this.props.width;
		}

		return (
			<div style={aStyleDict}></div>
		);
	}

}


class AppSeparator extends Component {

	constructor(props) {
		super(props);
	}

	getDirection() {
		return this.props.direction;
	}

	render() {
		var aClassNameArray = [];
		if (this.getDirection() === "horizontal") {
			aClassNameArray.push("app-separator-horizontal");
		} else {
			aClassNameArray.push("app-separator-vertical");
		}

		return (
			<div className={aClassNameArray}></div>
		);
	}

}


export { AppSpacer, AppSeparator }