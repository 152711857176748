class ATDateManager {

	static LocaleSignet = {
		en : "en"
		, hi : "hi"
	}

	static usdfDateNow() {
		return ATDateManager.usdfDateFromJsDate(new Date());
	}


	static usdfDateFromJsDate(pJsDate) {
		var aReturnVal = null;
		// getTimezoneOffset() is in minutes, getTime() is in milliseconds 
		var aJsDateMiliseconds = pJsDate.getTime() + (pJsDate.getTimezoneOffset() * 60 * 1000)
		var aJsDate = new Date(aJsDateMiliseconds);
		aReturnVal = ATDateManager._stringFromJsDateWithFormat(aJsDate, ATDateManager._usdfDateFormat);

		return aReturnVal;
	}


	static componentFromUsdfDate(pUsdfDate, pComponentUrc) {
		var aReturnVal = null;

		if (pComponentUrc === "year") {
			aReturnVal = pUsdfDate.substring(0, 4);
		} else if (pComponentUrc === "month") {
			aReturnVal = pUsdfDate.substring(5, 7);
		} else if (pComponentUrc === "date") {
			aReturnVal = pUsdfDate.substring(8, 10);
		} else if (pComponentUrc === "hour") {
			aReturnVal = pUsdfDate.substring(11, 13);
		} else if (pComponentUrc === "minute") {
			aReturnVal = pUsdfDate.substring(14, 16);
		} else if (pComponentUrc === "second") {
			aReturnVal = pUsdfDate.substring(17, 19);
		} else if (pComponentUrc === "millsecond") {
			if (pUsdfDate.substring(19, 20) === ".") {
				aReturnVal = pUsdfDate.substring(20, 23);
			} else {
				aReturnVal = 0;
			}
		} else if (pComponentUrc === "timeZoneOffsetInMinutes") {
			var aTimeZoneOffset = "";
			if (pUsdfDate.substring(19, 20) === ".") {
				aTimeZoneOffset = pUsdfDate.substring(23, pUsdfDate.length);
			} else {
				aTimeZoneOffset = pUsdfDate.substring(25, pUsdfDate.length);
			}

			var aSign = "+";
			var anHour = 0;
			var aMinute = 0;
			if (aTimeZoneOffset.length === 6) {
				aSign = aTimeZoneOffset.substring(0, 1);
				anHour = aTimeZoneOffset.substring(1, 3);
				aMinute = aTimeZoneOffset.substring(4, 6);
			} else if (aTimeZoneOffset === "Z") {
				aSign = "+";
				anHour = 0;
				aMinute = 0;
			}

			aReturnVal = (anHour * 60) + aMinute * (aSign === "+" ? 1 : -1);
		}

		if (aReturnVal != null) {
			aReturnVal = parseInt(aReturnVal);
		}

		return aReturnVal;
	}


	static yearFromUsdfDate(pUsdfDate) {
		var aReturnVal = ATDateManager.componentFromUsdfDate(pUsdfDate, "year");
		return aReturnVal;
	}


	static monthFromUsdfDate(pUsdfDate) {
		var aReturnVal = ATDateManager.componentFromUsdfDate(pUsdfDate, "month");
		return aReturnVal;
	}


	static dateFromUsdfDate(pUsdfDate) {
		var aReturnVal = ATDateManager.componentFromUsdfDate(pUsdfDate, "date");
		return aReturnVal;
	}


	static hourFromUsdfDate(pUsdfDate) {
		var aReturnVal = ATDateManager.componentFromUsdfDate(pUsdfDate, "hour");
		return aReturnVal;
	}


	static minuteFromUsdfDate(pUsdfDate) {
		var aReturnVal = ATDateManager.componentFromUsdfDate(pUsdfDate, "minute");
		return aReturnVal;
	}


	static secondFromUsdfDate(pUsdfDate) {
		var aReturnVal = ATDateManager.componentFromUsdfDate(pUsdfDate, "second");
		return aReturnVal;
	}


	static millisecondFromUsdfDate(pUsdfDate) {
		var aReturnVal = ATDateManager.componentFromUsdfDate(pUsdfDate, "millisecond");
		return aReturnVal;
	}


	static timeZoneOffsetInMinutesFromUsdfDate(pUsdfDate) {
		var aReturnVal = ATDateManager.componentFromUsdfDate(pUsdfDate, "timeZoneOffsetInMinutes");
		return aReturnVal;
	}


	static _jsDateFromUsdfDate(pUsdfDate) {
		var aReturnVal = null;

		var anYear = ATDateManager.yearFromUsdfDate(pUsdfDate);
		var aMonth = ATDateManager.monthFromUsdfDate(pUsdfDate);
		var aDate = ATDateManager.dateFromUsdfDate(pUsdfDate);
		var anHour = ATDateManager.hourFromUsdfDate(pUsdfDate);
		var aMinute = ATDateManager.minuteFromUsdfDate(pUsdfDate);
		var aSecond = ATDateManager.secondFromUsdfDate(pUsdfDate);
		var aMilliSecond = ATDateManager.millisecondFromUsdfDate(pUsdfDate);
		var aJsDateUtc = new Date(anYear, aMonth - 1, aDate, anHour, aMinute, aSecond, aMilliSecond);
		var aReferenceTimestamp = aJsDateUtc.getTime() - (aJsDateUtc.getTimezoneOffset() * 60 * 1000);

		aReturnVal = new Date();
		aReturnVal.setTime(aReferenceTimestamp);

		return aReturnVal;
	}


	static _getPaddedComponent(pComponent, pPadingLength = 2) {
		var aReturnVal = pComponent.toString();
		while (aReturnVal.length < pPadingLength) {
			aReturnVal = "0" + aReturnVal;
		}
		return aReturnVal;
	}


	static _timeZoneDisplayString(pTimeZoneOffsetInMinutes, pShouldUseSpecialZoneDesignator) {
		var aReturnVal = null;

		var aTimeZoneOffsetInMinutesWithoutSign = pTimeZoneOffsetInMinutes < 0 ? (pTimeZoneOffsetInMinutes * -1) : pTimeZoneOffsetInMinutes;

		if (aTimeZoneOffsetInMinutesWithoutSign === 0 && pShouldUseSpecialZoneDesignator === true) {
			aReturnVal = "Z";
		} else {
			var anHour = (aTimeZoneOffsetInMinutesWithoutSign / 60);
			var aCompleteHour = Math.floor(anHour);
			var aMinute = (anHour - aCompleteHour) * 60;

			aReturnVal = (pTimeZoneOffsetInMinutes >= 0 ? "+" : "-");
			aReturnVal += ATDateManager._getPaddedComponent(aCompleteHour);
			aReturnVal += ":" + ATDateManager._getPaddedComponent(aMinute);
		}

		return aReturnVal;
	}


	static _usdfDateFormat = "yyyy-mm-dd'T'HH:MM:ss.l'000''Z'";


	static _stringFromJsDateWithFormat(pJsDate, pFormat, pLocale = ATDateManager.LocaleSignet.en) {
		var aReturnVal = pJsDate.format(pFormat, null, pLocale);
		return aReturnVal;
	}


	static usdfDateAtStartOfTheYear(pUsdfDate) {
		var aReturnVal = null;

		var aJsDate = ATDateManager._jsDateFromUsdfDate(pUsdfDate);
		var anYear = aJsDate.getFullYear();
		var aMonthIndex = 0;

		var aNewJSDate = new Date(anYear, aMonthIndex, 1);
		var aTimestamp = aNewJSDate.getTime() /* milliseconds */ + (aNewJSDate.getTimezoneOffset() * 60 * 1000);
		aNewJSDate.setTime(aTimestamp);

		var aStartOfTheMonth = ATDateManager._stringFromJsDateWithFormat(aNewJSDate, ATDateManager._usdfDateFormat);
		aReturnVal = aStartOfTheMonth;

		return aReturnVal;
	}


	static usdfDateAtEndOfTheYear(pUsdfDate) {
		var aReturnVal = null;

		var aJsDate = ATDateManager._jsDateFromUsdfDate(pUsdfDate);
		var anYear = aJsDate.getFullYear();
		var aMonthIndex = 11;
		var aDate = 31;

		var aNewJSDate = new Date(anYear, aMonthIndex, aDate, 23, 59, 59, 999);
		var aTimestamp = aNewJSDate.getTime() /* milliseconds */ + (aNewJSDate.getTimezoneOffset() * 60 * 1000);
		aNewJSDate.setTime(aTimestamp);

		var aStartOfTheMonth = ATDateManager._stringFromJsDateWithFormat(aNewJSDate, ATDateManager._usdfDateFormat);
		aReturnVal = aStartOfTheMonth;

		return aReturnVal;
	}


	static usdfDateAtStartOfTheMonth(pUsdfDate) {
		var aReturnVal = null;

		var aJsDate = ATDateManager._jsDateFromUsdfDate(pUsdfDate);
		var anYear = aJsDate.getFullYear();
		var aMonth = aJsDate.getMonth();

		var aNewJSDate = new Date(anYear, aMonth, 1);
		var aTimestamp = aNewJSDate.getTime() /* milliseconds */ + (aNewJSDate.getTimezoneOffset() * 60 * 1000);
		aNewJSDate.setTime(aTimestamp);

		var aStartOfTheMonth = ATDateManager._stringFromJsDateWithFormat(aNewJSDate, ATDateManager._usdfDateFormat);
		aReturnVal = aStartOfTheMonth;

		return aReturnVal;
	}


	static usdfDateAtEndOfTheMonth(pUsdfDate) {
		var aReturnVal = null;

		var aJsDate = ATDateManager._jsDateFromUsdfDate(pUsdfDate);
		var anYear = aJsDate.getFullYear();
		var aMonth = aJsDate.getMonth();

		var aNewJSDate = new Date(anYear, aMonth + 1, 0, 23, 59, 59, 999);
		var aTimestamp = aNewJSDate.getTime() /* milliseconds */ + (aNewJSDate.getTimezoneOffset() * 60 * 1000);
		aNewJSDate.setTime(aTimestamp);

		var aStartOfTheMonth = ATDateManager._stringFromJsDateWithFormat(aNewJSDate, ATDateManager._usdfDateFormat);
		aReturnVal = aStartOfTheMonth;

		return aReturnVal;
	}


	static usdfDateAtStartOfTheDay(pUsdfDate) {
		var aReturnVal = null;

		var anYear = ATDateManager.yearFromUsdfDate(pUsdfDate);
		var aMonth = ATDateManager.monthFromUsdfDate(pUsdfDate);
		var aDate = ATDateManager.dateFromUsdfDate(pUsdfDate);
		var anHour = ATDateManager.hourFromUsdfDate(pUsdfDate);
		var aMinute = ATDateManager.minuteFromUsdfDate(pUsdfDate);
		var aSecond = ATDateManager.secondFromUsdfDate(pUsdfDate);
		var aMilliSecond = ATDateManager.millisecondFromUsdfDate(pUsdfDate);
		var aJsDateUtc = new Date(anYear, aMonth - 1, aDate, anHour, aMinute, aSecond, aMilliSecond);
		var aReferenceTimestamp = aJsDateUtc.getTime() - (aJsDateUtc.getTimezoneOffset() * 60 * 1000);
		var aReferenceJsDateCurrentTimeZone = new Date();
		aReferenceJsDateCurrentTimeZone.setTime(aReferenceTimestamp);

		var aJsDate = new Date(aReferenceJsDateCurrentTimeZone.getFullYear(), aReferenceJsDateCurrentTimeZone.getMonth(), aReferenceJsDateCurrentTimeZone.getDate(), 0, 0, 0, 0);
		var aTimestamp = aJsDate.getTime() + (aJsDate.getTimezoneOffset() * 60 * 1000);
		aJsDate.setTime(aTimestamp);

		var aStartOfTheDay = ATDateManager._stringFromJsDateWithFormat(aJsDate, ATDateManager._usdfDateFormat);
		aReturnVal = aStartOfTheDay;

		return aReturnVal;
	}


	static usdfDateAtEndOfTheDay(pUsdfDate) {
		var aReturnVal = null;

		var anYear = ATDateManager.yearFromUsdfDate(pUsdfDate);
		var aMonth = ATDateManager.monthFromUsdfDate(pUsdfDate);
		var aDate = ATDateManager.dateFromUsdfDate(pUsdfDate);
		var anHour = ATDateManager.hourFromUsdfDate(pUsdfDate);
		var aMinute = ATDateManager.minuteFromUsdfDate(pUsdfDate);
		var aSecond = ATDateManager.secondFromUsdfDate(pUsdfDate);
		var aMilliSecond = ATDateManager.millisecondFromUsdfDate(pUsdfDate);
		var aJsDateUtc = new Date(anYear, aMonth - 1, aDate, anHour, aMinute, aSecond, aMilliSecond);
		var aReferenceTimestamp = aJsDateUtc.getTime() - (aJsDateUtc.getTimezoneOffset() * 60 * 1000);
		var aReferenceJsDateCurrentTimeZone = new Date();
		aReferenceJsDateCurrentTimeZone.setTime(aReferenceTimestamp);

		var aJsDate = new Date(aReferenceJsDateCurrentTimeZone.getFullYear(), aReferenceJsDateCurrentTimeZone.getMonth(), aReferenceJsDateCurrentTimeZone.getDate(), 23, 59, 59, 999);
		var aTimestamp = aJsDate.getTime() + (aJsDate.getTimezoneOffset() * 60 * 1000);
		aJsDate.setTime(aTimestamp);

		var anEndOfTheDay = ATDateManager._stringFromJsDateWithFormat(aJsDate, ATDateManager._usdfDateFormat);
		aReturnVal = anEndOfTheDay;

		return aReturnVal;
	}


	static stringFromUsdfDateWithFormat(pUsdfDate, pDateFormat, pLocale) {
		var aReturnVal = null;

		var aJsDate = ATDateManager._jsDateFromUsdfDate(pUsdfDate);
		aReturnVal = ATDateManager._stringFromJsDateWithFormat(aJsDate, pDateFormat, pLocale);

		return aReturnVal;
	}


	static differenceInDays(pStartUsdfDate, pEndUsdfDate) {
		var aReturnVal = null;

		aReturnVal = ATDateManager.differenceInMilliseconds(pStartUsdfDate, pEndUsdfDate) / (1000 * 60 * 60 * 24);

		return aReturnVal;
	}


	static elapsedTime(pStartUsdfDate, pEndUsdfDate) {
		var aReturnVal = null;

		var aMilliseconds = ATDateManager.differenceInMilliseconds(pStartUsdfDate, pEndUsdfDate);

		var anHours = Math.floor((aMilliseconds / (1000 * 60 * 60)) % 24);
		anHours = ATDateManager._getPaddedComponent(anHours, 2);
		
		var aMinutes = Math.floor((aMilliseconds / (1000 * 60)) % 60);
		aMinutes = ATDateManager._getPaddedComponent(aMinutes, 2);

		aReturnVal = "";
		if (anHours > 0) {
			aReturnVal = ATDateManager._getPaddedComponent(anHours, 2) + "h";
		}
		if (aMinutes > 0) {
			if (aReturnVal.length > 0) {
				aReturnVal += " ";
			}
			aReturnVal = ATDateManager._getPaddedComponent(aMinutes, 2) + "m";
		}

		return aReturnVal;
	}


	static differenceInMilliseconds(pStartUsdfDate, pEndUsdfDate) {
		var aReturnVal = null;

		var aStartJSDate = ATDateManager._jsDateFromUsdfDate(pStartUsdfDate);
		var anEndJSDate = ATDateManager._jsDateFromUsdfDate(pEndUsdfDate);

		aReturnVal = Math.abs(anEndJSDate.getTime() - aStartJSDate.getTime());

		return aReturnVal;
	}


	static dateByAddingSeconds(pUsdfDate, pSeconds) {
		var aReturnVal = null;

		var aJsDate = ATDateManager._jsDateFromUsdfDate(pUsdfDate);
		var aTimestamp = aJsDate.getTime() /* milliseconds */ + (pSeconds * 1000);

		var aNewJSDate = new Date();
		aNewJSDate.setTime(aTimestamp);

		aReturnVal = ATDateManager._stringFromJsDateWithFormat(aNewJSDate, ATDateManager._usdfDateFormat);

		return aReturnVal;
	}

	static isDateGreaterThan(pLhsUsdfDate, pRhsUsdfDate) {
		var aReturnVal = null;

		var anLhsJsDate = ATDateManager._jsDateFromUsdfDate(pLhsUsdfDate);
		var anRhsJsDate = ATDateManager._jsDateFromUsdfDate(pRhsUsdfDate);
		aReturnVal = anLhsJsDate > anRhsJsDate;

		return aReturnVal;
	}

	static isDateGreaterThanOrEqualTo(pLhsUsdfDate, pRhsUsdfDate) {
		var aReturnVal = null;

		var anLhsJsDate = ATDateManager._jsDateFromUsdfDate(pLhsUsdfDate);
		var anRhsJsDate = ATDateManager._jsDateFromUsdfDate(pRhsUsdfDate);
		aReturnVal = anLhsJsDate >= anRhsJsDate;

		return aReturnVal;
	}

}



var dateFormat = function () {
	var	token = /d{1,4}|m{1,4}|yy(?:yy)?|([HhMsTt])\1?|[LloSZ]|"[^"]*"|'[^']*'/g,
		timezone = /\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g,
		timezoneClip = /[^-+\dA-Z]/g,
		pad = function (val, len) {
			val = String(val);
			len = len || 2;
			while (val.length < len) val = "0" + val;
			return val;
		};

	// Regexes and supporting functions are cached through closure
	return function (date, mask, utc, pLocale) {
		var dF = dateFormat;

		// You can't provide utc if you skip other args (use the "UTC:" mask prefix)
		if (arguments.length == 1 && Object.prototype.toString.call(date) == "[object String]" && !/\d/.test(date)) {
			mask = date;
			date = undefined;
		}

		// Passing date through Date applies Date.parse, if necessary
		date = date ? new Date(date) : new Date;
		if (isNaN(date)) throw SyntaxError("invalid date");

		mask = String(dF.masks[mask] || mask || dF.masks["default"]);

		// Allow setting the utc argument via the mask
		if (mask.slice(0, 4) == "UTC:") {
			mask = mask.slice(4);
			utc = true;
		}

		var aLocale = pLocale
		var aLocalizedDict = {
			dayNames: [
				"Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat",
				"Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
			],
			monthNames: [
				"Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
				"January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
			],
			amShort: "a",
			amMid: "am",
			pmShort: "p",
			pmMid: "pm"
		};
		if (aLocale == ATDateManager.LocaleSignet.hi) {
			aLocalizedDict = {
				dayNames: [
					"रवि", "सोम", "मंगल", "बुध", "गुरू", "शुक्र", "शनि",
					"रविवार", "सोमवार", "मंगलवार", "बुधवार", "गुरूवार", "शुक्रवार", "शनिवार"
				],
				monthNames: [
					"जन", "फ़र", "मार्च", "अप्रै", "मई", "जून", "जुला", "अग", "सितं", "अक्टू", "नवं", "दिसं",
					"जनवरी", "फ़रवरी", "मार्च", "अप्रैल", "मई", "जून", "जुलाई", "अगस्त", "सितंबर", "अक्टूबर", "नवंबर", "दिसंबर"
				],
				amShort: "पु",
				amMid: "मपु",
				pmShort: "उ",
				pmMid: "मउ"
			};
		}

		var	_ = utc ? "getUTC" : "get",
			d = date[_ + "Date"](),
			D = date[_ + "Day"](),
			m = date[_ + "Month"](),
			y = date[_ + "FullYear"](),
			H = date[_ + "Hours"](),
			M = date[_ + "Minutes"](),
			s = date[_ + "Seconds"](),
			L = date[_ + "Milliseconds"](),
			o = utc ? 0 : date.getTimezoneOffset(),
			flags = {
				d:    d,
				dd:   pad(d),
				ddd:  aLocalizedDict.dayNames[D],
				dddd: aLocalizedDict.dayNames[D + 7],
				m:    m + 1,
				mm:   pad(m + 1),
				mmm:  aLocalizedDict.monthNames[m],
				mmmm: aLocalizedDict.monthNames[m + 12],
				yy:   String(y).slice(2),
				yyyy: y,
				h:    H % 12 || 12,
				hh:   pad(H % 12 || 12),
				H:    H,
				HH:   pad(H),
				M:    M,
				MM:   pad(M),
				s:    s,
				ss:   pad(s),
				l:    pad(L, 3),
				L:    pad(L > 99 ? Math.round(L / 10) : L),
				t:    H < 12 ? aLocalizedDict.amShort  : aLocalizedDict.pmShort,
				tt:   H < 12 ? aLocalizedDict.amMid : aLocalizedDict.pmMid,
				T:    H < 12 ? aLocalizedDict.amShort.toUpperCase()  : aLocalizedDict.pmShort.toUpperCase(),
				TT:   H < 12 ? aLocalizedDict.amMid.toUpperCase() : aLocalizedDict.pmMid.toUpperCase(),
				Z:    utc ? "UTC" : (String(date).match(timezone) || [""]).pop().replace(timezoneClip, ""),
				o:    (o > 0 ? "-" : "+") + pad(Math.floor(Math.abs(o) / 60) * 100 + Math.abs(o) % 60, 4),
				S:    ["th", "st", "nd", "rd"][d % 10 > 3 ? 0 : (d % 100 - d % 10 != 10) * d % 10]
			};

		return mask.replace(token, function ($0) {
			return $0 in flags ? flags[$0] : $0.slice(1, $0.length - 1);
		});
	};
}();

// Some common format strings
dateFormat.masks = {
	"default":      "ddd mmm dd yyyy HH:MM:ss",
	shortDate:      "m/d/yy",
	mediumDate:     "mmm d, yyyy",
	longDate:       "mmmm d, yyyy",
	fullDate:       "dddd, mmmm d, yyyy",
	shortTime:      "h:MM TT",
	mediumTime:     "h:MM:ss TT",
	longTime:       "h:MM:ss TT Z",
	isoDate:        "yyyy-mm-dd",
	isoTime:        "HH:MM:ss",
	isoDateTime:    "yyyy-mm-dd'T'HH:MM:ss",
	isoUtcDateTime: "UTC:yyyy-mm-dd'T'HH:MM:ss'Z'"
};

// For convenience...
Date.prototype.format = function (mask, utc, pLocale) {
	return dateFormat(this, mask, utc, pLocale);
};


export { ATDateManager }
