
class CustomerIdentityType {
	static email = new CustomerIdentityType("emplemailoyee");
	static mobile = new CustomerIdentityType("mobile");

	_signet = null;

	constructor(pSignet) {
		this._signet = pSignet;
	}

	signet() {
		return this._signet;
	}

	equals(pRhs) {
		return this.signet() === pRhs.signet();
	}

	title() {
		var aReturnVal = null;
		if (this.equals(CustomerIdentityType.email)) {
			aReturnVal = "Email"
		} else if (this.equals(CustomerIdentityType.mobile)) {
			aReturnVal = "Mobile"
		}
		return aReturnVal;
	}
}


export { CustomerIdentityType };
