import React, { Component } from 'react';

import BaseComponent from '../app-ui-kit/base-component';
import { AppSpacer } from '../app-ui-kit/app-ui-kit';
import { LocalizationManager } from '../../localization-manager/localization-manager';

import "./page-not-found-component.css"


class PageNotFoundComponent extends BaseComponent {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="foundation-storey page-not-found-storey">

				<AppSpacer height="100px" />

				<div className="page-not-found-header">
					<span className="page-not-found-header-hightlighter">{LocalizationManager.pageNotFoundComponent_header01()}</span>
					<span> - </span>
					<span>{LocalizationManager.pageNotFoundComponent_header02()}</span>
				</div>

				<AppSpacer height="50px" />

				<div className="page-not-found-subheader">
				{LocalizationManager.pageNotFoundComponent_message()}
				</div>

			</div>
		);
	}

}

export default PageNotFoundComponent;
