
class ObservationManager {
	static shared = new ObservationManager();
	_observers = [];


	addObserver(pCallback, pName) {
		if (this._observers[pName] instanceof Array === false) {
			this._observers[pName] = [];
		}
		this._observers[pName].push(pCallback);
	}


	removeObserver(pCallback, pName) {
		var anObserverArray = this._observers[pName];

		if (anObserverArray instanceof Array) {
			anObserverArray.remove(pCallback);
		}
	}


	postNotification(pName, pProgrammerData) {
		var anObserverArray = this._observers[pName];

		if (anObserverArray instanceof Array) {
			anObserverArray.forEach(function(pItem) {
				pItem(pProgrammerData);
			});
		}
	}

}


class ObservationEventName {
	static didFinishReloadLoggedInUser = "didFinishReloadLoggedInUser"
	static didChangeAppLocale = "didChangeAppLocale"
}


export {ObservationManager, ObservationEventName}
