import { LocalizationManager } from './localization-manager';

LocalizationManager.homePageComponent_headerAppName = function() {
	return LocalizationManager.localizedString("homePageComponent_headerAppName");
}

LocalizationManager.homePageComponent_headerAppTagLine = function() {
	return LocalizationManager.localizedString("homePageComponent_headerAppTagLine");
}


LocalizationManager.homePageComponent_subheader = function() {
	return LocalizationManager.localizedString("homePageComponent_subheader");
}


LocalizationManager.homePageComponent_featureHeader = function() {
	return LocalizationManager.localizedString("homePageComponent_featureHeader");
}

LocalizationManager.homePageComponent_featureSummary = function() {
	return LocalizationManager.localizedString("homePageComponent_featureSummary");
}


LocalizationManager.homePageComponent_appUpdatePopup_pageHeader01 = function() {
	return LocalizationManager.localizedString("homePageComponent_appUpdatePopup_pageHeader01");
}

LocalizationManager.homePageComponent_appUpdatePopup_pageHeader02 = function() {
	return LocalizationManager.localizedString("homePageComponent_appUpdatePopup_pageHeader02");
}

LocalizationManager.homePageComponent_appUpdatePopup_pageSubheader = function() {
	return LocalizationManager.localizedString("homePageComponent_appUpdatePopup_pageSubheader");
}

LocalizationManager.homePageComponent_featureAppUpdatePopupTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_featureAppUpdatePopupTitle");
}

LocalizationManager.homePageComponent_featureAppUpdatePopupSummary01 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureAppUpdatePopupSummary01");
}

LocalizationManager.homePageComponent_watchOnYoutubeButtonTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_watchOnYoutubeButtonTitle");
}

LocalizationManager.homePageComponent_getStartedButtonTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_getStartedButtonTitle");
}


LocalizationManager.homePageComponent_featureWorksFromWithinAppTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_featureWorksFromWithinAppTitle");
}

LocalizationManager.homePageComponent_featureWorksFromWithinAppSummary01 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureWorksFromWithinAppSummary01");
}

LocalizationManager.homePageComponent_featureWorksFromWithinAppSummary02 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureWorksFromWithinAppSummary02");
}


LocalizationManager.homePageComponent_featureNoRegistrationRequiredTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_featureNoRegistrationRequiredTitle");
}

LocalizationManager.homePageComponent_featureNoRegistrationRequiredSummary01 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureNoRegistrationRequiredSummary01");
}


LocalizationManager.homePageComponent_featureBetterTeamCollborationTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_featureBetterTeamCollborationTitle");
}

LocalizationManager.homePageComponent_featureBetterTeamCollborationSummary01 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureBetterTeamCollborationSummary01");
}

LocalizationManager.homePageComponent_featureBetterTeamCollborationSummary02 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureBetterTeamCollborationSummary02");
}


LocalizationManager.homePageComponent_featureStayWellOrganizedTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_featureStayWellOrganizedTitle");
}

LocalizationManager.homePageComponent_featureStayWellOrganizedSummary01 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureStayWellOrganizedSummary01");
}

LocalizationManager.homePageComponent_featureStayWellOrganizedSummary02 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureStayWellOrganizedSummary02");
}

LocalizationManager.homePageComponent_featureStayWellOrganizedSummary03 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureStayWellOrganizedSummary03");
}

LocalizationManager.homePageComponent_featureStayWellOrganizedSummary04 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureStayWellOrganizedSummary04");
}


LocalizationManager.homePageComponent_featureEasyIntegrationTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_featureEasyIntegrationTitle");
}

LocalizationManager.homePageComponent_featureEasyIntegrationSummary01 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureEasyIntegrationSummary01");
}


LocalizationManager.homePageComponent_featureBetterCommunicationTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_featureBetterCommunicationTitle");
}

LocalizationManager.homePageComponent_featureBetterCommunicationSummary01 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureBetterCommunicationSummary01");
}
