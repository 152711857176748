import BaseComponent from '../app-ui-kit/base-component';
import React, { Component } from 'react';
import { CacheManager } from '../../collaborators/cache-manager';
import { RoutingManager } from '../../collaborators/routing-manager';
import { LocalizationManager, LocalizationLocale } from '../../localization-manager/localization-manager';
import '../../localization-manager/localization-manager-foundation';
import { AppSelectField, AppSelectFieldOption } from '../app-ui-kit/app-select-field';
import { ObservationEventName, ObservationManager } from '../../collaborators/observation-manager';

import './menu-bar-component.css';


class MenuBarComponent extends Component {

	constructor(props) {
		super(props);

		this.languageSelectField = React.createRef();
	}

	componentDidMount() {
		this.reloadAllData();
	}

	reloadAllData() {
		this.reloadAllViews();
	}

	reloadAllViews() {
		var anOptionArray = [];
		anOptionArray.push(
			new AppSelectFieldOption(
				LocalizationLocale.en.localizedTitle()
				, LocalizationLocale.en.signet()
			)
		);
		anOptionArray.push(
			new AppSelectFieldOption(
				LocalizationLocale.hi.localizedTitle()
				, LocalizationLocale.hi.signet()
			)
		);
		anOptionArray.push(
			new AppSelectFieldOption(
				LocalizationLocale.th.localizedTitle()
				, LocalizationLocale.th.signet()
			)
		);
		this.languageSelectField.current.options = anOptionArray;
		this.languageSelectField.current.selectOptionValue(LocalizationManager.currentLocale.signet());

		this.forceUpdate();
	}

	render() {
		var aMenuBarItemArray = [];
		aMenuBarItemArray.push(<div className="menu-bar-item" key="home" onClick={this.didSelectHome.bind()}>{LocalizationManager.menuBarComponent_homeTitle()}</div>);
		aMenuBarItemArray.push(<div className="menu-bar-item" key="knowledgebase" onClick={this.didSelectSearchKnowledgebase.bind()}>{LocalizationManager.menuBarComponent_knowledgebaseTitle()}</div>);
		// aMenuBarItemArray.push(<div className="menu-bar-item" key="download">{LocalizationManager.menuBarComponent_downloadTitle()}</div>);
		aMenuBarItemArray.push(<div className="menu-bar-item" key="support" onClick={this.didSelectSupportDetails.bind()}>{LocalizationManager.menuBarComponent_supportTitle()}</div>);
		aMenuBarItemArray.push(<div className="menu-bar-item" key="pricing" onClick={this.didSelectSearchPlan.bind()}>{LocalizationManager.menuBarComponent_pricingTitle()}</div>);

		// TODO: Add login observer here.
		if (CacheManager.shared.session != null) {
			aMenuBarItemArray.push(<div className="menu-bar-item" key="dashboard" onClick={this.didSelectDashboard.bind()}>{LocalizationManager.menuBarComponent_dashboardTitle()}</div>);
		} else {
			aMenuBarItemArray.push(<div className="menu-bar-item" key="signup_login" onClick={this.didSelectSignupLogin.bind()}>{LocalizationManager.menuBarComponent_signupLoginTitle()}</div>);
		}

		aMenuBarItemArray.push(
			<AppSelectField
				ref={this.languageSelectField}
				titleView={<span className="menu-bar-language-input-icon"></span>}
				inputClassNames={["menu-bar-language-input"]}
				key="language"
				didChangeValueCallback={this.didChangeAppLocale.bind(this)}
			/>
		);

		return (
			<div className="menu-bar">
				{aMenuBarItemArray}
			</div>
		);
	}

	didSelectHome() {
		RoutingManager.shared.gotoHome();
	}

	didSelectSignupLogin() {
		RoutingManager.shared.gotoSignUpLoginCustomer();
	}

	didSelectDashboard() {
		RoutingManager.shared.gotoSearchProduct();
	}

	didSelectSearchPlan() {
		RoutingManager.shared.gotoSearchPlan();
	}

	didSelectSearchKnowledgebase() {
		RoutingManager.shared.gotoSearchKnowledgebase();
	}

	didSelectSupportDetails() {
		RoutingManager.shared.gotoSupportDetails();
	}

	didChangeAppLocale() {
		if (this.languageSelectField != null && this.languageSelectField.current != null) {
			var aSelectedOptionArray = this.languageSelectField.current.value();
			LocalizationManager.currentLocaleSignet = aSelectedOptionArray[0].value;
			ObservationManager.shared.postNotification(ObservationEventName.didChangeAppLocale);
			this.forceUpdate();
		}
	}

}


export { MenuBarComponent }
