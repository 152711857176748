import React, { Component } from 'react';

import "./app-table.css"


class AppTable extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		var aRowArray = [];

		if (this.props.data instanceof Array && this.props.data.length > 0) {
			var aTableRowIndex = 0;
			for (var aRowIndex = 0; aRowIndex < this.props.data.length; aRowIndex ++) {
				var aRow = this.props.data[aRowIndex];

				var aCellArray = [];
				for (var aCellIndex = 0; aCellIndex < aRow.cells.length; aCellIndex ++) {
					var aCell = aRow.cells[aCellIndex];
					var aCellClassNameArray = [];
					aCellClassNameArray.push("app-list-cell");
					if (aCell.classNames instanceof Array) {
						aCellClassNameArray = aCellClassNameArray.concat(aCell.classNames);
					}
					if (aCell.title != null) {
						aCellArray.push(
							<div className={aCellClassNameArray.join(" ")} key={"Cell_" + aCellIndex}>{aCell.title}</div>
						)
					} else if (aCell.view != null) {
						aCellArray.push(
							<div className={aCellClassNameArray.join(" ")} key={"Cell_" + aCellIndex}>{aCell.view}</div>
						)
					}
				}

				var aRowClassNameArray = [];
				aRowClassNameArray.push("app-list-row");
				if (aRow.classNames instanceof Array) {
					aRowClassNameArray = aRowClassNameArray.concat(aRow.classNames);
				}
				if (aRow.isHeaderRow) {
					aRowClassNameArray.push("app-list-row-header");
				}
				aRowArray.push(
					<div className={aRowClassNameArray.join(" ")} key={"Row_" + aRowIndex} onClick={this.didSelectRow.bind(this, aRow.item)}>
						{aCellArray}
					</div>
				);
				if (aRow.isHeaderRow === false) {
					aTableRowIndex ++;
				}
			}
		}

		return (
			<div className="app-list-table">
				{aRowArray}
			</div>
		);
	}

	didSelectRow(pItem) {
		if (this.props.didSelectRowCallback != null) {
			this.props.didSelectRowCallback(pItem);
		}
	}

}


class AppTableRow {
	isHeaderRow = false;
	classNames = null;
	cells = null;
	item = null;

	constructor(pCellArray, pClassNameArray, pItem, pIsHeaderRow = false) {
		this.cells = pCellArray;
		this.classNames = pClassNameArray;
		this.item = pItem;
		this.isHeaderRow = pIsHeaderRow;
	}
}

class AppTableCell {
	title = null;
	view = null;
	classNames = null;

	static initWithTitle(pTitle, pClassNameArray) {
		var aReturnVal = new AppTableCell();
		aReturnVal.title = pTitle;
		aReturnVal.classNames = pClassNameArray;
		return aReturnVal;
	}

	static initWithView(pView, pClassNameArray) {
		var aReturnVal = new AppTableCell();
		aReturnVal.view = pView;
		aReturnVal.classNames = pClassNameArray;
		return aReturnVal;
	}
}


export { AppTable, AppTableRow, AppTableCell }