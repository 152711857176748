import {DataTransferManagerHttp} from './data-transfer-manager-http';
import {DataTransferManagerMethodStub} from './data-transfer-manager-method-stub';


class DataTransferManagerEnvironmentType {
	static httpApi = "httpApi";
	static methodStub = "methodStub";
}


class DataTransferManagerResult {
	signet = null;
	localizedDescription = null;
	outcome = null;
}


class DataTransferManagerResultSignet {
	static error = "error";
	static errorAuthenticationNeeded = "error_authentication_needed";
	static success = "success";
}


class DataTransferManager {
	static shared = new DataTransferManager();

	environmentType = DataTransferManagerEnvironmentType.methodStub;
	methodStubResponseDelayInMiliseconds = 1000;

	_dataTransferManagerHttp = new DataTransferManagerHttp();
	_dataTransferManagerMethodStub = new DataTransferManagerMethodStub();


	registerCustomer(pCallback, pCustomer) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.registerCustomer(pCallback, pCustomer);
		} else {
			this._dataTransferManagerMethodStub.registerCustomer(pCallback, pCustomer);
		}
	}


	loginCustomer(pCallback, pCustomer) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.loginCustomer(pCallback, pCustomer);
		} else {
			this._dataTransferManagerMethodStub.loginCustomer(pCallback, pCustomer);
		}
	}


	logoutCustomer(pCallback, pCustomer) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.logoutCustomer(pCallback, pCustomer);
		} else {
			this._dataTransferManagerMethodStub.logoutCustomer(pCallback, pCustomer);
		}
	}


	newResetCustomerPasswordCode(pCallback, pCustomer) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.newResetCustomerPasswordCode(pCallback, pCustomer);
		} else {
			this._dataTransferManagerMethodStub.newResetCustomerPasswordCode(pCallback, pCustomer);
		}
	}


	resetCustomerPassword(pCallback, pCustomer) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.resetCustomerPassword(pCallback, pCustomer);
		} else {
			this._dataTransferManagerMethodStub.resetCustomerPassword(pCallback, pCustomer);
		}
	}


	profileDetails(pCallback) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.profileDetails(pCallback);
		} else {
			this._dataTransferManagerMethodStub.profileDetails(pCallback);
		}
	}


	updateCustomerProfile(pCallback, pCustomer) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.updateCustomerProfile(pCallback, pCustomer);
		} else {
			this._dataTransferManagerMethodStub.updateCustomerProfile(pCallback, pCustomer);
		}
	}

	updateCustomerPassword(pCallback, pCustomer) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.updateCustomerPassword(pCallback, pCustomer);
		} else {
			this._dataTransferManagerMethodStub.updateCustomerPassword(pCallback, pCustomer);
		}
	}

	newCustomerIdentity(pCallback, pCustomerIdentity) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.newCustomerIdentity(pCallback, pCustomerIdentity);
		} else {
			this._dataTransferManagerMethodStub.newCustomerIdentity(pCallback, pCustomerIdentity);
		}
	}

	verifyCustomerIdentity(pCallback, pCustomerIdentity) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.verifyCustomerIdentity(pCallback, pCustomerIdentity);
		} else {
			this._dataTransferManagerMethodStub.verifyCustomerIdentity(pCallback, pCustomerIdentity);
		}
	}

	newVerifyCustomerIdentityCode(pCallback, pCustomerIdentity) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.newVerifyCustomerIdentityCode(pCallback, pCustomerIdentity);
		} else {
			this._dataTransferManagerMethodStub.newVerifyCustomerIdentityCode(pCallback, pCustomerIdentity);
		}
	}

	deleteCustomerIdentity(pCallback, pCustomerIdentity) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.deleteCustomerIdentity(pCallback, pCustomerIdentity);
		} else {
			this._dataTransferManagerMethodStub.deleteCustomerIdentity(pCallback, pCustomerIdentity);
		}
	}


	newProduct(pCallback, pProduct) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.newProduct(pCallback, pProduct);
		} else {
			this._dataTransferManagerMethodStub.newProduct(pCallback, pProduct);
		}
	}


	productDetails(pCallback, pProductUuid) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.productDetails(pCallback, pProductUuid);
		} else {
			this._dataTransferManagerMethodStub.productDetails(pCallback, pProductUuid);
		}
	}


	updateProduct(pCallback, pProduct) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.updateProduct(pCallback, pProduct);
		} else {
			this._dataTransferManagerMethodStub.updateProduct(pCallback, pProduct);
		}
	}


	updateIosAppSpecification(pCallback, pIosAppSpecification, pProductUuid, pRequestParameterArray) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.updateIosAppSpecification(pCallback, pIosAppSpecification, pProductUuid, pRequestParameterArray);
		} else {
			this._dataTransferManagerMethodStub.updateIosAppSpecification(pCallback, pIosAppSpecification, pProductUuid, pRequestParameterArray);
		}
	}


	updateAndroidAppSpecification(pCallback, pAndroidAppSpecification, pProductUuid, pRequestParameterArray) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.updateAndroidAppSpecification(pCallback, pAndroidAppSpecification, pProductUuid, pRequestParameterArray);
		} else {
			this._dataTransferManagerMethodStub.updateAndroidAppSpecification(pCallback, pAndroidAppSpecification, pProductUuid, pRequestParameterArray);
		}
	}


	searchProductCustomerMap(pCallback, pProductUuid, pAssigneeUuid) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.searchProductCustomerMap(pCallback, pProductUuid, pAssigneeUuid);
		} else {
			this._dataTransferManagerMethodStub.searchProductCustomerMap(pCallback, pProductUuid, pAssigneeUuid);
		}
	}

	deleteProductCustomerMap(pCallback, pProductCustomerMapUuid) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.deleteProductCustomerMap(pCallback, pProductCustomerMapUuid);
		} else {
			this._dataTransferManagerMethodStub.deleteProductCustomerMap(pCallback, pProductCustomerMapUuid);
		}
	}

	newProductInvitation(pCallback, pProductUuid) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.newProductInvitation(pCallback, pProductUuid);
		} else {
			this._dataTransferManagerMethodStub.newProductInvitation(pCallback, pProductUuid);
		}
	}

	searchProductInvitation(pCallback, pProductUuid) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.searchProductInvitation(pCallback, pProductUuid);
		} else {
			this._dataTransferManagerMethodStub.searchProductInvitation(pCallback, pProductUuid);
		}
	}

	deleteProductInvitation(pCallback, pProductInvitationUuid) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.deleteProductInvitation(pCallback, pProductInvitationUuid);
		} else {
			this._dataTransferManagerMethodStub.deleteProductInvitation(pCallback, pProductInvitationUuid);
		}
	}

	acceptProductInvitation(pCallback, pProductInvitationUuid) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.acceptProductInvitation(pCallback, pProductInvitationUuid);
		} else {
			this._dataTransferManagerMethodStub.acceptProductInvitation(pCallback, pProductInvitationUuid);
		}
	}

	newProductApiKey(pCallback, pProductUuid, pRsaPublicKey) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.newProductApiKey(pCallback, pProductUuid, pRsaPublicKey);
		} else {
			this._dataTransferManagerMethodStub.newProductApiKey(pCallback, pProductUuid, pRsaPublicKey);
		}
	}

	newPayment(pCallback, pPayment) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.newPayment(pCallback, pPayment);
		} else {
			this._dataTransferManagerMethodStub.newPayment(pCallback, pPayment);
		}
	}

	searchTicketFormDetails(pCallback) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.searchTicketFormDetails(pCallback);
		} else {
			this._dataTransferManagerMethodStub.searchTicketFormDetails(pCallback);
		}
	}

	searchTicket(pCallback, pTicketFilter) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.searchTicket(pCallback, pTicketFilter);
		} else {
			this._dataTransferManagerMethodStub.searchTicket(pCallback, pTicketFilter);
		}
	}

	ticketDetails(pCallback, pTicketUuid) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.ticketDetails(pCallback, pTicketUuid);
		} else {
			this._dataTransferManagerMethodStub.ticketDetails(pCallback, pTicketUuid);
		}
	}

	updateTicketFormDetails(pCallback, pTicketUuid) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.updateTicketFormDetails(pCallback, pTicketUuid);
		} else {
			this._dataTransferManagerMethodStub.updateTicketFormDetails(pCallback, pTicketUuid);
		}
	}

	updateTicket(pCallback, pTicketUuid, pRequestParameterArray) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.updateTicket(pCallback, pTicketUuid, pRequestParameterArray);
		} else {
			this._dataTransferManagerMethodStub.updateTicket(pCallback, pTicketUuid, pRequestParameterArray);
		}
	}

	newTicketMessage(pCallback, pTicketUuid, pTicketMessage) {
		if (this.environmentType === DataTransferManagerEnvironmentType.httpApi) {
			this._dataTransferManagerHttp.newTicketMessage(pCallback, pTicketUuid, pTicketMessage);
		} else {
			this._dataTransferManagerMethodStub.newTicketMessage(pCallback, pTicketUuid, pTicketMessage);
		}
	}

}


export {DataTransferManager, DataTransferManagerResult, DataTransferManagerResultSignet, DataTransferManagerEnvironmentType}
