import { TicketMessageAccessLevel } from "./ticket-message-access-level";

class UpdateTicketForm {
	availableAssignees = null;
	ticketMessageAccessLevels = null;

	constructor() {
		var aTicketMessageAccessLevelArray = [];
		aTicketMessageAccessLevelArray.push(TicketMessageAccessLevel.default);
		aTicketMessageAccessLevelArray.push(TicketMessageAccessLevel.internal);
		aTicketMessageAccessLevelArray.push(TicketMessageAccessLevel.private);
		this.ticketMessageAccessLevels = aTicketMessageAccessLevelArray;
	}
}


export { UpdateTicketForm };
