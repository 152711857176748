
class UserType {
	static employee = new UserType("employee");
	static customer = new UserType("customer");
	static spectator = new UserType("spectator");

	_signet = null;

	constructor(pSignet) {
		this._signet = pSignet;
	}

	signet() {
		return this._signet;
	}

	equals(pRhs) {
		return this.signet() === pRhs.signet();
	}

	title() {
		var aReturnVal = null;
		if (this.equals(UserType.employee)) {
			aReturnVal = "Employee"
		} else if (this.equals(UserType.customer)) {
			aReturnVal = "Customer"
		} else if (this.equals(UserType.spectator)) {
			aReturnVal = "Spectator"
		}
		return aReturnVal;
	}
}


export { UserType };
