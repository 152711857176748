
class Product {
	uuid = null;
	title = null;
	agentCount = null;
	apiKey = null;
	iosAppSpecification = null;
	androidAppSpecification = null;
}


export { Product };
