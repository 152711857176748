import React, { Component } from 'react';

import "./app-file-field.css"
import { ATUtilityManager } from '../../third-party/com-aurvan-atkit-reactjs/src/at-utility-manager';


class AppFileField extends Component {
	title = null;

	constructor(props) {
		super(props);
	}

	_fileModels = []

	setSelectedFileModels(pNewValue) {
		if (pNewValue instanceof Array) {
			this._fileModels = pNewValue
		} else {
			this._fileModels = []
		}
	}

	getLocalFiles() {
		var aReturnVal = null

		if (this._fileModels != null) {
			aReturnVal = []
			for (var anIndex = 0; anIndex < this._fileModels.length; anIndex ++) {
				if (this._fileModels[anIndex].localFile instanceof File) {
					aReturnVal.push(this._fileModels[anIndex].localFile)
				}
			}
		}

		return aReturnVal
	}

	getFileModels(pCompletion) {
		this.getFileModelsAsync(pCompletion)
	}

	async getFileModelsAsync(pCompletion) {
		if (this._fileModels != null) {
			var aThis = this;
			var aFileModelArray = []
			for (var anIndex = 0; anIndex < aThis._fileModels.length; anIndex ++) {
				var anEachFileModel = aThis._fileModels[anIndex]
				if (anEachFileModel.localFile != null) {
					var aData = await aThis._getFileContents(anEachFileModel.localFile);
					if (aData != null) {
						var aFileModel = new AppFileFieldModel()
						aFileModel.title = anEachFileModel.title
						aFileModel.extension = anEachFileModel.extension
						aFileModel.data = aData
						aFileModelArray.push(aFileModel)
					}
				}
			}
			pCompletion(aFileModelArray)
		} else {
			pCompletion(null)
		}
	}

	_getFileContents(pFile) {
		return new Promise((resolve, reject) => {
			const aFileReader = new FileReader()
			aFileReader.onloadend = function (pEvent) {
				resolve(pEvent.target.result)
			}
			aFileReader.onerror = function (pEvent) {
				reject(null)
			}
			aFileReader.readAsText(pFile)
		})
	}

	_maxFileCount = null;
	get maxFileCount() {
		var aReturnVal = 1;
		if (this._maxFileCount != null) {
			aReturnVal = this._maxFileCount;
		} else if (this.props != null && this.props.maxFileCount != null) {
			aReturnVal = this.props.maxFileCount;
		}
		return aReturnVal;
	}
	set maxFileCount(pNewValue) {
		this._maxFileCount = pNewValue;
	}

	getTitle() {
		var aReturnVal = "";
		if (this.title != null) {
			aReturnVal = this.title;
		} else if (this.props != null && this.props.title != null) {
			aReturnVal = this.props.title;
		}
		return aReturnVal;
	}

	render() {
		var aContainerClassArray = [];
		aContainerClassArray.push("app-input-field");
		aContainerClassArray.push("app-input-filefield");

		return (
			<div className={aContainerClassArray.join(" ")}>
				{this.fileListView()}
			</div>
		);
	}

	fileListView() {
		var aReturnVal = []

		var aTitleClassArray = [];
		aTitleClassArray.push("app-input-filefield-title");
		if (this.getTitle() == null || this.getTitle() === "") {
			aTitleClassArray = aTitleClassArray.concat(["app-input-filefield-title-hidden"]);
		}

		var anInputClassArray = [];
		anInputClassArray.push("app-input-filefield-input");
		if (this.props.inputClassNames instanceof Array) {
			anInputClassArray = anInputClassArray.concat(this.props.inputClassNames);
		}

		if (this._fileModels == null) {
			this._fileModels = []
		}
		if (this._fileModels.length === 0) {
			this._fileModels.push(AppFileFieldModel.init());
		}

		for (var anIndex = 0; anIndex < this._fileModels.length; anIndex ++) {
			var aFileModel = this._fileModels[anIndex];

			var aHashClassArray = [];
			aHashClassArray.push("app-input-filefield-hash");
			if (aFileModel.hash == null || aFileModel.hash === "") {
				aHashClassArray = aHashClassArray.concat(["app-input-filefield-hash-hidden"]);
			}

			var aCellArray = []
			aCellArray.push(
				<div className={aTitleClassArray.join(" ")} key={"title"}>{this.getTitle()}</div>
			)
			aCellArray.push(
				<div className={aHashClassArray.join(" ")} key={"hash"}>{aFileModel.hash}</div>
			)
			aCellArray.push(
				<input type="file" className={anInputClassArray.join(" ")} key={"input"} onChange={this.didSelectFile.bind(this, anIndex)} />
			)
			if (anIndex === this._fileModels.length - 1 && this._fileModels.length < this.maxFileCount) {
				aCellArray.push(
					<div
						className={["app-input-filefield-button", "app-input-filefield-button-add"].join(" ")}
						onClick={this.didSelectAdd.bind(this)}
						key={"addButton"}
					></div>
				)
			}
			if (anIndex > 0) {
				var aRowIndex = anIndex
				aCellArray.push(
					<div
						className={["app-input-filefield-button", "app-input-filefield-button-delete"].join(" ")}
						onClick={this.didSelectDelete.bind(this, aRowIndex)}
						key={"deleteButton"}
					></div>
				)
			}
			aReturnVal.push(
				<div className={["app-input-filefield-row"].join(" ")} key={"row_" + aFileModel.uuid}>
					{aCellArray}
				</div>
			)
		}

		return aReturnVal
	}

	didSelectFile(pIndex, pEvent) {
		if (pIndex >= 0 && this._fileModels.length > pIndex) {
			this._fileModels[pIndex].localFile = pEvent.target.files[0]
			var aFileName = pEvent.target.files[0].name
			this._fileModels[pIndex].title = aFileName.substring(0, aFileName.lastIndexOf("."))
			this._fileModels[pIndex].extension = aFileName.substring(aFileName.lastIndexOf(".") + 1)
		}
		this.forceUpdate();
	}

	didSelectAdd() {
		if (this._fileModels == null) {
			this._fileModels = []
		}
		if (this._fileModels.length < this.maxFileCount) {
			this._fileModels.push(AppFileFieldModel.init());
		}
		this.forceUpdate();
	}

	didSelectDelete(pIndex) {
		if (pIndex >= 0 && this._fileModels.length > pIndex) {
			this._fileModels.splice(pIndex, 1);
		}
		this.forceUpdate();
	}

	reset() {
		this.setSelectedFileModels(null)
	}

}

class AppFileFieldModel {
	uuid = null
	hash = null

	localFile = null
	title = null
	extension = null
	data = null

	static init() {
		var aReturnVal = new AppFileFieldModel();
		aReturnVal.uuid = ATUtilityManager.uuid()
		return aReturnVal
	}

	static initWithHash(pHash) {
		var aReturnVal = new AppFileFieldModel();
		aReturnVal.uuid = ATUtilityManager.uuid()
		aReturnVal.hash = pHash
		return aReturnVal
	}
}


export { AppFileField, AppFileFieldModel }