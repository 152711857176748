
class CustomerIdentity {
	uuid = null;
	addedOnUsdfDate = null;

	typeSignet = null;
	value = null;

	verificationCodeHash = null;
	verifiedOnUsdfDate = null;
}


export { CustomerIdentity };
