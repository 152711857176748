import React, { Component } from 'react';

import "./at-markdown-view.css"

class ATMarkdownView extends Component {
	
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.reloadAllData();
	}

	reloadAllData() {
		this.reloadAllViews();
	}

	reloadAllViews() {
		this.forceUpdate();
	}

	render() {
		var anHtml = this.props.children;

		// Headers
		anHtml = anHtml.replace(/^\#{1}[\s](.*)/g, ("<div class=\"at-markdown-view-h1\">" + "$1" + "</div>"));
		anHtml = anHtml.replace(/^\#{2}[\s](.*)/g, ("<div class=\"at-markdown-view-h2\">" + "$1" + "</div>"));
		anHtml = anHtml.replace(/^\#{3}[\s](.*)/g, ("<div class=\"at-markdown-view-h3\">" + "$1" + "</div>"));
		anHtml = anHtml.replace(/^\#{4}[\s](.*)/g, ("<div class=\"at-markdown-view-h4\">" + "$1" + "</div>"));

		// Bold
		anHtml = anHtml.replace(/\*\*([^]*?)\*\*/g, '<b>$1</b>');

		// Bullet list
		anHtml = anHtml.replace(/^(\d\.)/g, ("&nbsp;".repeat(8) + "$1"));

		// Movie
		anHtml = anHtml.replace(
			/\!\[(.*)\]\((.*)\)+/g
			, (
				"<video width=\"320\" height=\"240\" controls>"
				+ "<source src=\"$2\" type=\"$1\">"
				+ "Your browser does not support the video tag."
				+ "</video>"
			)
		);

		// Link
		anHtml = anHtml.replace(/\[(.*)\]\("(.*)"\)+/g, ("<a href=\"$2\" class=\"at-markdown-view-link\">" + "$1" + "</a>"));

		// Bookmark
		anHtml = anHtml.replace(/\[(.*)\]\(#(.*)\)+/g, ("<span id=\"$2\">" + "$1" + "</span>"));

		// Code
		anHtml = anHtml.replace(/~{3}([\S\s]*?)~{3}/g, function(pText, pMatch1) {
			var aText = pMatch1.trim("\n");
			var aMinimumTab = 0;
			var aTextLineArray = aText.split(/\n/)
				for (var anIndex = 0; anIndex < aTextLineArray.length; anIndex ++) {
				var anEachMinimumTab = (aTextLineArray[anIndex].match(/\t/g) || []).length
				if (aMinimumTab == 0 || aMinimumTab > anEachMinimumTab) {
					aMinimumTab = anEachMinimumTab
				}
			}
			aText = aText.replaceAll("\t".repeat(aMinimumTab), "")
			return "<div class=\"at-markdown-view-code\">" + aText + "</div>"
		});

		// Blockquote
		anHtml = anHtml.replace(/^\>{1}[\s](.*)/g, ("<div class=\"at-markdown-view-blockquote\">" + "$1" + "</div>"));

		return (
			<div className="at-markdown-view" dangerouslySetInnerHTML={{__html: anHtml}}></div>
		);
	}

}


export { ATMarkdownView }
