class ATUtilityManager {

	static formattedByteSize(pSizeInBytes) {
		var aReturnVal = null;

		var aSizeInBytes = pSizeInBytes;

		const anIsDecimal = true; // false in case of binary (1024)
		const aPrecision = 1;
		const aThreshold = anIsDecimal ? 1000 : 1024;

		if (Math.abs(aSizeInBytes) < aThreshold) {
			aReturnVal = aSizeInBytes + " B";
		} else {
			const aUnitArray = anIsDecimal
			? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
			: ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
			var aUnitIndex = -1;
			const anExponent = 10 ** aPrecision;

			do {
				aSizeInBytes /= aThreshold;
				++aUnitIndex;
			} while (Math.round(Math.abs(aSizeInBytes) * anExponent) / anExponent >= aThreshold && aUnitIndex < aUnitArray.length - 1);

			aReturnVal = aSizeInBytes.toFixed(aPrecision) + " " + aUnitArray[aUnitIndex];
		}

		return aReturnVal;
	}

	static uuid() {
		var uuid = "", i, random;
		for (i = 0; i < 32; i++) {
			random = Math.random() * 16 | 0;
			if (i == 8 || i == 12 || i == 16 || i == 20) {
				uuid += "-"
			}
			uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
		}
		return uuid;
	}


	static saveAsFile(pFileData, pFileName, pMimeType) {
		var aFileData = pFileData
		var aFileMimeType = "application/octet-stream";
		if (pMimeType != null) {
			aFileMimeType = pMimeType
		}
		var aLink = document.createElement("a");
		var aBlob = new Blob([aFileData], {"type":aFileMimeType});
		aLink.href = window.URL.createObjectURL(aBlob);
		if (pFileName != null) {
			aLink.download = pFileName;
		} else {
			aLink.download = "file.bin";
		}
		aLink.click();
	}

}

export { ATUtilityManager }
