import BaseComponent from '../app-ui-kit/base-component';
import React from 'react';
import { withParams } from '../app-ui-kit/higher-order-component';
import { CacheManager } from '../../collaborators/cache-manager';

import { Ticket } from '../../models/ticket';
import { RequestParameter } from '../../models/request-parameter';
import { DataTransferManager, DataTransferManagerResultSignet } from "../../data-manager/data-transfer-manager"
import { ProgressIndicationManager } from '../../collaborators/progress-indication-manager';
import { PopupManager } from '../../collaborators/popup-manager';
import { AppButton } from '../app-ui-kit/app-button';
import { RoutingManager, URL } from '../../collaborators/routing-manager';
import { AppFileField } from '../app-ui-kit/app-file-field';

import "./ticket-details-component.css"
import { TicketMessage } from '../../models/ticket-message';
import { TicketMessageAccessLevel } from '../../models/ticket-message-access-level';
import { ATDateManager } from "../../third-party/com-aurvan-atkit-reactjs/src/at-date-manager";
import { ATUtilityManager } from "../../third-party/com-aurvan-atkit-reactjs/src/at-utility-manager";
import { AppSeparator, AppSpacer } from '../app-ui-kit/app-ui-kit';
import { AppSelectField, AppSelectFieldOption } from '../app-ui-kit/app-select-field';
import { TicketState } from '../../models/ticket-state';
import { Customer } from '../../models/customer';
import { UpdateTicketForm } from '../../models/update-ticket-form';
import { LocalizationManager } from '../../localization-manager/localization-manager';
import { TicketMessageAttachment } from '../../models/ticket-message-attachment';


class TicketDetailsComponent extends BaseComponent {
	ticket = null;
	updateTicketForm = null;

	constructor(props) {
		super(props);

		this.baseContainer = React.createRef();
		this.replyMessageInputField = React.createRef();
		this.replyMessageFileField = React.createRef();
		this.accessLevelSelectField = React.createRef();
		this.assigneeSelectField = React.createRef();
	}

	reloadAllData() {
		if (CacheManager.shared.loggedInCustomer != null) {
			var aThis = this;
			aThis.ticketDetails(function() {
				aThis.updateTicketFormDetails();
			});
		}
	}

	reloadAllViews() {
		if (this.accessLevelSelectField != null && this.accessLevelSelectField.current != null) {
			this.accessLevelSelectField.current.title = LocalizationManager.ticketDetailsComponent_messageAccessLevelInputTitle();
			if (this.updateTicketForm != null && this.updateTicketForm.ticketMessageAccessLevels != null) {
				var anAccessLevelOptionArray = [];
				for (var anIndex = 0; anIndex < this.updateTicketForm.ticketMessageAccessLevels.length; anIndex ++) {
					var aTicketMessageAccessLevel = this.updateTicketForm.ticketMessageAccessLevels[anIndex];
					anAccessLevelOptionArray.push(
						new AppSelectFieldOption(aTicketMessageAccessLevel.localizedTitle(), aTicketMessageAccessLevel.signet())
					);
				}
				this.accessLevelSelectField.current.options = anAccessLevelOptionArray;
				this.accessLevelSelectField.current.selectOptionValue(TicketMessageAccessLevel.default.signet());
			}
		}

		if (this.assigneeSelectField != null && this.assigneeSelectField.current != null) {
			this.assigneeSelectField.current.title = LocalizationManager.ticketDetailsComponent_assigneeInputTitle();
			if (this.updateTicketForm != null && this.updateTicketForm.availableAssignees instanceof Array) {
				var anAssigneeOptionArray = [];
				for (var anIndex = 0; anIndex < this.updateTicketForm.availableAssignees.length; anIndex ++) {
					var anAssignee = this.updateTicketForm.availableAssignees[anIndex];
					anAssigneeOptionArray.push(
						new AppSelectFieldOption(anAssignee.displayName, anAssignee.uuid)
					);
				}
				this.assigneeSelectField.current.options = anAssigneeOptionArray;
				if (this.ticket != null
					&& this.ticket.assignee != null
					&& this.ticket.assignee.uuid != null) {
					this.assigneeSelectField.current.selectOptionValue(this.ticket.assignee.uuid);
				}
			}
		}

		this.forceUpdate();
	}

	resetAllViews() {
		if (this.replyMessageInputField != null && this.replyMessageInputField.current != null) {
			this.replyMessageInputField.current.value = null;
		}
		if (this.replyMessageFileField != null && this.replyMessageFileField.current != null) {
			this.replyMessageFileField.current.reset();
		}
		this.forceUpdate();
	}

	render() {
		var aReturnVal = this.loginRequiredView();
		if (CacheManager.shared.loggedInCustomer != null) {
			aReturnVal = this.ticketDetailsView();
		}
		return aReturnVal;
	}

}


// MARK:- Views

TicketDetailsComponent.prototype.ticketDetailsView = function() {
	var aTicketTitle = null;
	if (this.ticket != null) {
		aTicketTitle = this.ticket.title;
	}

	return (
		<div className="foundation-storey vertical-scroll-view" ref={this.baseContainer}>
			<div className="ticket-details-message-title">
				<div>{aTicketTitle}</div>
			</div>

			<AppSpacer height="30px" />

			{this.ticketReplyMenuBarView()}

			<AppSpacer height="20px" />

			{this.ticketMessageListView()}

			<AppSpacer height="40px" />

			{this.ticketReplyFormView()}
		</div>
	);
}

TicketDetailsComponent.prototype.ticketMessageListView = function() {
	var aMessageCellArray = [];
	if (this.ticket instanceof Ticket
	&& this.ticket.messages instanceof Array
	&& this.ticket.messages.length > 0) {
		for (var anIndex = 0; anIndex < this.ticket.messages.length; anIndex ++) {
			var aTicketMessage = this.ticket.messages[anIndex];

			var aRowClassNameArray = [];
			aRowClassNameArray.push("ticket-details-message-row");
			var anAccessLevelCellClassNameArray = [];
			anAccessLevelCellClassNameArray.push("ticket-details-message-access-level-cell")
			var anAccessLevelIconClassNameArray = [];
			anAccessLevelIconClassNameArray.push("ticket-details-message-access-level-icon")
			if (aTicketMessage.accessLevel.equals(TicketMessageAccessLevel.private)) {
				aRowClassNameArray.push("ticket-details-message-row-access-private");
				anAccessLevelCellClassNameArray.push("ticket-details-message-access-level-private");
				anAccessLevelIconClassNameArray.push("ticket-details-message-access-level-icon-private")
			} else if (aTicketMessage.accessLevel.equals(TicketMessageAccessLevel.internal)) {
				aRowClassNameArray.push("ticket-details-message-row-access-internal");
				anAccessLevelCellClassNameArray.push("ticket-details-message-access-level-internal");
				anAccessLevelIconClassNameArray.push("ticket-details-message-access-level-icon-internal")
			} else {
				aRowClassNameArray.push("ticket-details-message-row-access-default");
				anAccessLevelCellClassNameArray.push("ticket-details-message-access-level-default");
				anAccessLevelIconClassNameArray.push("ticket-details-message-access-level-icon-default")
			}

			var aMessageAttachmentCellArray = [];
			if (aTicketMessage.attachments != null) {
				for (var anAttachmentIndex = 0; anAttachmentIndex < aTicketMessage.attachments.length; anAttachmentIndex ++) {
					var aMessageAttachment = aTicketMessage.attachments[anAttachmentIndex]
					var aMessageAttachmentSize = ATUtilityManager.formattedByteSize(aMessageAttachment.sizeInBytes);
					aMessageAttachmentCellArray.push(
						<div className="ticket-details-message-attachment-file" key={"message-attachment-cell-"+anAttachmentIndex}>
							<a href={aMessageAttachment.downloadUrl} className="app-link">
								<span className="app-link-icon ticket-details-message-attachment-download-icon"></span>
								<span>{" " + aMessageAttachment.extension.toUpperCase() + " (" + aMessageAttachmentSize + ")"}</span>
							</a>
						</div>
					)
				}
			}

			aMessageCellArray.push(
				<div className={aRowClassNameArray.join(" ")} key={anIndex}>
					<div className="ticket-details-message-cell" key={"date-cell-"+anIndex}>
						<div>
							{ATDateManager.stringFromUsdfDateWithFormat(aTicketMessage.addedOnUsdfDate, "dd-mmm-yyyy", LocalizationManager.currentLocale.signet())}
						</div>
						<div>
							{ATDateManager.stringFromUsdfDateWithFormat(aTicketMessage.addedOnUsdfDate, "hh:MM tt", LocalizationManager.currentLocale.signet())}
						</div>
						<div style={{"flexGrow":"1"}}></div>
						<div className={anAccessLevelCellClassNameArray.join(" ")}>
							<span className={anAccessLevelIconClassNameArray.join(" ")}></span>
							<span>{aTicketMessage.accessLevel.localizedTitle()}</span>
							<span className={["help-note-button"].join(" ")} onClick={this.didSelectMessageAccessLevelHelp.bind(this)}></span>
						</div>
					</div>
					<div className="ticket-details-message-cell" key={"message-cell-"+anIndex}>
						<div className="ticket-details-message-author">{aTicketMessage.author.displayName}</div>
						<div className="ticket-details-message-description">{aTicketMessage.description}</div>
						<div className="ticket-details-message-attachment">{aMessageAttachmentCellArray}</div>
					</div>
				</div>
			);
			aMessageCellArray.push(
				<AppSeparator direction="horizontal" key={"app-separator-"+anIndex} />
			);
		}
	}

	return <div className="ticket-details-message-table">
		{aMessageCellArray}
	</div>
}

TicketDetailsComponent.prototype.ticketReplyMenuBarView = function() {
	var aReturnVal = null;
	
	if (this.ticket != null && this.ticket.state != null) {
		if (this.ticket.state.equals(TicketState.open)) {
			aReturnVal = <div className={["ticket-details-reply-table"].join(" ")}>
				<div className="ticket-details-reply-row">
					<AppSelectField inputClassNames={["ticket-details-access-level-input"]} ref={this.assigneeSelectField} />
					<AppButton
						title={LocalizationManager.ticketDetailsComponent_assignButtonTitle()}
						inputClassNames={["ticket-details-submit-button"]}
						didSelectCallback={this.didSelectUpdateAssignee.bind(this)}
					/>
					<AppSeparator />
					<AppButton
						title={LocalizationManager.ticketDetailsComponent_closeTicketButtonTitle()}
						inputClassNames={["ticket-details-submit-button"]}
						didSelectCallback={this.didSelectCloseTicket.bind(this)}
					/>
					<AppSeparator />
					<AppButton
						title={LocalizationManager.ticketDetailsComponent_draftReplyButtonTitle()}
						inputClassNames={["ticket-details-submit-button"]}
						didSelectCallback={this.didSelectDraftReply.bind(this)}
					/>
				</div>
			</div>
		} else {
			aReturnVal = <div className={["ticket-details-reply-table"].join(" ")}>
				<div className="ticket-details-reply-row">
					<AppButton title="Open Ticket" inputClassNames={["ticket-details-submit-button"]} didSelectCallback={this.didSelectOpenTicket.bind(this)} />
					<div className="ticket-details-reply-spacer"></div>
				</div>
			</div>
		}
	}

	return aReturnVal
}

TicketDetailsComponent.prototype.ticketReplyFormView = function() {
	var aReturnVal = null;
	
	if (this.ticket != null && this.ticket.state != null && this.ticket.state.equals(TicketState.open)) {
		aReturnVal = <div className={["ticket-details-reply-table"].join(" ")}>
			<textarea className="ticket-details-submit-textarea" ref={this.replyMessageInputField}></textarea>
			<AppFileField
				title={LocalizationManager.ticketDetailsComponent_messageAttachmentFileInputTitle() + " :"}
				inputClassNames={["ticket-details-file-field"]}
				ref={this.replyMessageFileField}
				maxFileCount={5}
			/>
			<div className="ticket-details-reply-row">
				<div className="ticket-details-reply-spacer"></div>
				<AppSelectField inputClassNames={["ticket-details-access-level-input"]} ref={this.accessLevelSelectField} />
				<div className={["help-note-button", "ticket-details-access-level-help"].join(" ")} onClick={this.didSelectMessageAccessLevelHelp.bind(this)}></div>
				<AppButton
					title={LocalizationManager.ticketDetailsComponent_submitReplyButtonTitle()}
					inputClassNames={["ticket-details-submit-button"]}
					didSelectCallback={this.didSelectSubmit.bind(this)}
				/>
			</div>
		</div>
	}

	return aReturnVal
}


// MARK:- Actions

TicketDetailsComponent.prototype.didSelectDraftReply = function() {
	if (this.baseContainer != null && this.baseContainer.current != null) {
		this.baseContainer.current.scrollTop = this.baseContainer.current.scrollHeight;
	}
	this.replyMessageInputField.current.focus();
}

TicketDetailsComponent.prototype.didSelectMessageAccessLevelHelp = function() {
	var anHtml = '<div style="display: flex; flex-direction:column; gap: 7px; max-width: 380px; padding: 12px 0px; text-align: justify; font-family: var(--app-font-normal)">'
	anHtml += '<center><b>' + LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpTitle() + '</b></center>'
	anHtml += '<div style="height: 1px; min-height: 1px;"></div>'
	anHtml += '<div style="line-height: 20px;"><b>● '
		+ LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpPrivateTitle()
		+ ' :</b> ' + LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpPrivateDescription()
		+ '</div>'
	anHtml += '<div style="line-height: 20px;"><b>● '
		+ LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpInternalTitle()
		+ ' :</b> ' + LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpInternalDescription()
		+ '</div>'
	anHtml += '<div style="line-height: 20px;"><b>● '
		+ LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpDefaultTitle()
		+ ' :</b> ' + LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpDefaultDescription()
		+ '</div>'
	anHtml += '</div>'

	PopupManager.shared.displayCustomPopup(anHtml);
}

TicketDetailsComponent.prototype.didSelectSubmit = function() {
	this.newTicketMessage()
}

TicketDetailsComponent.prototype.didSelectCloseTicket = function() {
	this.updateTicket(null, TicketState.closed);
}

TicketDetailsComponent.prototype.didSelectOpenTicket = function() {
	this.updateTicket(null, TicketState.open);
}

TicketDetailsComponent.prototype.didSelectUpdateAssignee = function() {
	if (this.assigneeSelectField.current != null
	&& this.assigneeSelectField.current.value() instanceof Array
	&& this.assigneeSelectField.current.value().length > 0) {
		this.updateTicket(this.assigneeSelectField.current.value()[0].value, null);
	}
}


// MARK:- Data Manager

TicketDetailsComponent.prototype.newTicketMessage = function() {
	var aTicketUuid = this.ticket.uuid;

	var aTicketMessage = new TicketMessage();
	aTicketMessage.description = this.replyMessageInputField.current.value;

	var aLocalFileArray = this.replyMessageFileField.current.getLocalFiles()
	if (aLocalFileArray != null) {
		var anAttachmentArray = []
		for (var anIndex = 0; anIndex < aLocalFileArray.length; anIndex ++) {
			var anAttachment = new TicketMessageAttachment()
			anAttachment.localFile = aLocalFileArray[anIndex]
			anAttachmentArray.push(anAttachment)
		}
		aTicketMessage.attachments = anAttachmentArray
	}
	aTicketMessage.accessLevel = new TicketMessageAccessLevel(this.accessLevelSelectField.current.value()[0].value);

	var aThis = this;
	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.newTicketMessage(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			aThis.resetAllViews()
			var aMessage = LocalizationManager.common_apiResponseSuccessMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllData();
	}, aTicketUuid, aTicketMessage);
}

TicketDetailsComponent.prototype.updateTicketFormDetails = function() {
	var aThis = this;

	if (aThis.ticket != null
		&& aThis.ticket.uuid != null) {
		ProgressIndicationManager.shared.showLoadingOverlay();
		DataTransferManager.shared.updateTicketFormDetails(function(pResult) {
			ProgressIndicationManager.shared.hideLoadingOverlay();
			if (pResult.signet === DataTransferManagerResultSignet.success) {
				if (pResult.outcome instanceof UpdateTicketForm) {
					aThis.updateTicketForm = pResult.outcome;
				} else {
					aThis.updateTicketForm = null;
				}
			} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
				RoutingManager.shared.gotoSignUpLoginCustomer();
			} else {
				var aMessage = LocalizationManager.common_unknownErrorMessage();
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displayErrorMessage(aMessage);
			}
			aThis.reloadAllViews();
		}, aThis.ticket.uuid);
	}
}

TicketDetailsComponent.prototype.updateTicket = function(pAssigneeUuid, pTicketState) {
	var aTicket = new Ticket()
	aTicket.uuid = this.ticket.uuid;
	aTicket.state = pTicketState;
	if (pAssigneeUuid != null) {
		var anAssignee = new Customer();
		anAssignee.uuid = pAssigneeUuid
		aTicket.assignee = anAssignee;
	}

	var aRequestParameterArray = [];
	if (pTicketState != null) {
		aRequestParameterArray.push(RequestParameter.ticketStateSignet);
	}
	if (pAssigneeUuid != null) {
		aRequestParameterArray.push(RequestParameter.ticketAssigneeUuid);
	}

	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.updateTicket(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			var aMessage = LocalizationManager.common_apiResponseSuccessMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
			aThis.reloadAllData();
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, aTicket, aRequestParameterArray);
}

TicketDetailsComponent.prototype.ticketDetails = function(pCompletion) {
	var aTicketUuid = this.props.params.uuid;
	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.ticketDetails(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			if (pResult.outcome instanceof Ticket) {
				aThis.ticket = pResult.outcome;
			} else {
				aThis.ticket = null;
			}
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
		pCompletion();
	}, aTicketUuid);
}


export default withParams(TicketDetailsComponent);
