import { LocalizationManager } from './localization-manager';

LocalizationManager.supportDetailsComponent_header01 = function() {
	return LocalizationManager.localizedString("supportDetailsComponent_header01");
}

LocalizationManager.supportDetailsComponent_header02 = function() {
	return LocalizationManager.localizedString("supportDetailsComponent_header02");
}

LocalizationManager.supportDetailsComponent_subheader = function() {
	return LocalizationManager.localizedString("supportDetailsComponent_subheader");
}

LocalizationManager.supportDetailsComponent_emailSupportHeader = function() {
	return LocalizationManager.localizedString("supportDetailsComponent_emailSupportHeader");
}

LocalizationManager.supportDetailsComponent_emailSupportNote = function() {
	return LocalizationManager.localizedString("supportDetailsComponent_emailSupportNote");
}

LocalizationManager.supportDetailsComponent_inAppSupportHeader = function() {
	return LocalizationManager.localizedString("supportDetailsComponent_inAppSupportHeader");
}

LocalizationManager.supportDetailsComponent_inAppSupportNote = function() {
	return LocalizationManager.localizedString("supportDetailsComponent_inAppSupportNote");
}
