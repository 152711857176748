import React from 'react';
import BaseComponent from '../app-ui-kit/base-component';
import { Product } from "../../models/product"
import { DataTransferManager, DataTransferManagerResultSignet } from "../../data-manager/data-transfer-manager"
import { ProgressIndicationManager } from '../../collaborators/progress-indication-manager';
import { PopupManager } from '../../collaborators/popup-manager';
import { AppButton, AppButtonType } from '../app-ui-kit/app-button';
import { RoutingManager, URL } from '../../collaborators/routing-manager';
import { AppSpacer } from '../app-ui-kit/app-ui-kit';
import { CacheManager } from '../../collaborators/cache-manager';
import { LocalizationManager } from '../../localization-manager/localization-manager';
import '../../localization-manager/localization-manager-product';

import "./new-product-component.css"
import { AppTextField } from '../app-ui-kit/app-text-field';


class NewProductComponent extends BaseComponent {

	constructor(props) {
		super(props);

		this.productTitleValueTextField = React.createRef();
	}

	render() {
		var aReturnVal = this.loginRequiredView();
		if (CacheManager.shared.loggedInCustomer != null) {
			aReturnVal = this.newProductView();
		}
		return aReturnVal;
	}

}


// MARK:- Views

NewProductComponent.prototype.newProductView = function() {
	return (
		<div className="foundation-storey vertical-scroll-view">
			<div style={{"fontSize":"var(--font-size-biggest)", "color":"var(--text-darkest-color)"}}>
				{LocalizationManager.newProductComponent_pageHeader()}
			</div>
			<AppSpacer height="8px" />
			<div style={{"fontSize":"var(--font-size-normal)", "color":"var(--text-darker-color)"}}>
				{LocalizationManager.newProductComponent_pageInstruction()}
			</div>

			<AppSpacer height="32px" />

			<div className="app-input-title">{LocalizationManager.newProductComponent_productTitleTextFieldTitle()}</div>
			<AppSpacer height="8px" />
			<AppTextField ref={this.productTitleValueTextField} inputClassNames={["new-product-title-text-field"]} />

			<AppSpacer height="32px" />

			<AppButton
				title={LocalizationManager.newProductComponent_submitButtonTitle()}
				type={AppButtonType.submit}
				inputClassNames={["new-product-submit-button"]}
				didSelectCallback={this.didSelectNewProductButton.bind(this)}
			/>
		</div>
	);
}


// MARK:- Actions

NewProductComponent.prototype.didSelectNewProductButton = function() {
	this.newProduct()
}


// MARK:- Operations

NewProductComponent.prototype.resetAllViews = function() {
	if (this.productTitleValueTextField != null && this.productTitleValueTextField.current != null) {
		this.productTitleValueTextField.current.value = null;
	}
}


// MARK:- Data Manager

NewProductComponent.prototype.newProduct = function() {
	var aProduct = new Product();
	aProduct.title = this.productTitleValueTextField.current.value;

	var aThis = this;
	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.newProduct(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			aThis.resetAllViews()
			var aMessage = "Request processed successfully.";
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = "Unknown error.";
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, aProduct);
}

export default NewProductComponent;
