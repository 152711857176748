
class Session {
	uuid = null;
	userType = null;
	expiresOnUsdfDate = null;
	token = null;
}


export { Session };
