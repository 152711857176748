import { LocalizationManager } from "../localization-manager/localization-manager";

class ProductCustomerRole {
	static owner = new ProductCustomerRole("owner");
	static agent = new ProductCustomerRole("agent");

	_signet = null;

	constructor(pSignet) {
		this._signet = pSignet;
	}

	signet() {
		return this._signet;
	}

	equals(pRhs) {
		return this.signet() === pRhs.signet();
	}

	localizedTitle() {
		var aReturnVal = null;
		if (this.equals(ProductCustomerRole.owner)) {
			aReturnVal = LocalizationManager.productCustomerRole_ownerTitle()
		} else if (this.equals(ProductCustomerRole.agent)) {
			aReturnVal = LocalizationManager.productCustomerRole_agentTitle()
		}
		return aReturnVal;
	}
}


export { ProductCustomerRole };
