import React from 'react';
import BaseComponent from '../app-ui-kit/base-component';
import { withParams } from '../app-ui-kit/higher-order-component';

import { Customer } from "../../models/customer"
import { DataTransferManager, DataTransferManagerResultSignet } from "../../data-manager/data-transfer-manager"
import { ProgressIndicationManager } from '../../collaborators/progress-indication-manager';
import { ATEncryptionManager } from '../../third-party/com-aurvan-atkit-reactjs/src/at-encryption-manager';
import { PopupManager } from '../../collaborators/popup-manager';
import { AppButton, AppButtonType } from '../app-ui-kit/app-button';
import { AppTextField } from '../app-ui-kit/app-text-field';
import { AppTable, AppTableRow, AppTableCell } from '../app-ui-kit/app-table';
import { RoutingManager } from '../../collaborators/routing-manager';
import { AppSpacer } from '../app-ui-kit/app-ui-kit';
import { ObservationManager, ObservationEventName } from '../../collaborators/observation-manager';
import { ATDateManager } from "../../third-party/com-aurvan-atkit-reactjs/src/at-date-manager";
import { LocalizationManager } from '../../localization-manager/localization-manager';
import '../../localization-manager/localization-manager-customer';

import "./customer-profile-details-component.css"
import { CacheManager } from '../../collaborators/cache-manager';
import { CustomerIdentity } from '../../models/customer-identity';
import { CustomerIdentityType } from '../../models/customer-identity-type';



class CustomerProfileDetailsComponent extends BaseComponent {
	isLoadedOnce = false;

	constructor(props) {
		super(props);

		this.displayNameTextField = React.createRef();
		this.newPasswordTextField = React.createRef();
		this.newPasswordRetypeTextField = React.createRef();
		this.currentPasswordTextField = React.createRef();

		this.identityEmailAddressTextField = React.createRef();
		this.identityVerificationCodeTextField = React.createRef();
	}

	reloadAllData() {
		if (this.isLoadedOnce == false) {
			this.isLoadedOnce = true
			this.reloadLoggedInCustomerData()
		}
		this.reloadAllViews()
	}

	reloadAllViews() {
		var aCustomerDisplayName = "";
		if (CacheManager.shared.loggedInCustomer != null && CacheManager.shared.loggedInCustomer.displayName != null) {
			aCustomerDisplayName = CacheManager.shared.loggedInCustomer.displayName;
		}
		if (this.displayNameTextField != null && this.displayNameTextField.current != null) {
			this.displayNameTextField.current.value = aCustomerDisplayName;
		}

		this.forceUpdate();
	}

	resetAllViews() {
		if (this.displayNameTextField != null && this.displayNameTextField.current != null) {
			this.displayNameTextField.current.value = null;
		}
		this.identityEmailAddressTextField.current.value = null;
	}

	render() {
		var aReturnVal = this.loginRequiredView();
		if (CacheManager.shared.loggedInCustomer != null) {
			aReturnVal = this.customerProfileDetailsView();
		}
		return aReturnVal;
	}

}


// MARK:- Views

CustomerProfileDetailsComponent.prototype.customerProfileDetailsView = function() {
	return (
		<div className="foundation-storey vertical-scroll-view">
			<div className="customer-profile-details-section-title">{LocalizationManager.customerProfileDetailsComponent_profileDetailsSectionTitle()}</div>

			<AppSpacer height="8px" />

			{this.updateCustomerProfileView()}

			{this.identitySectionView()}

			{this.updatePasswordSectionView()}

			<AppSpacer height="40px" />

		</div>
	);
}

CustomerProfileDetailsComponent.prototype.identitySectionView = function() {
	var aReturnVal = null;

	var anIdentityRowArray = [];
	var aHeaderRow = new AppTableRow(
		[
			AppTableCell.initWithTitle(LocalizationManager.customerProfileDetailsComponent_identityEmailAddressColumnTitle(), ["customer-identity-email-cell"])
			, AppTableCell.initWithTitle(LocalizationManager.customerProfileDetailsComponent_identityVerificationDateColumnTitle(), ["customer-identity-date-cell"])
			, AppTableCell.initWithTitle(" ", ["customer-identity-cta-cell"])
		]
		, null
		, null
		, true
	);
	anIdentityRowArray.push(aHeaderRow);

	var anIdentityFormRow = new AppTableRow(
		[
			AppTableCell.initWithView(
				<AppTextField
					title={LocalizationManager.customerProfileDetailsComponent_identityEmailAddressInputTitle() + " :"}
					placeholder={LocalizationManager.customerProfileDetailsComponent_textInputPlaceholder()}
					inputClassNames={["customer-profile-details-text-field"]}
					ref={this.identityEmailAddressTextField}
				/>
				, ["customer-identity-email-cell"]
			)
			, AppTableCell.initWithTitle(
				" "
				, ["customer-identity-date-cell"]
			)
			, AppTableCell.initWithView(
				<AppButton
					title={LocalizationManager.customerProfileDetailsComponent_addIdentityCtaTitle()}
					type={AppButtonType.arcRect}
					didSelectCallback={this.didSelectNewCustomerIdentity.bind(this)}
					inputClassNames={["customer-profile-details-arc-button"]}
				/>
				, ["customer-identity-cta-cell"]
			)
		]
		, ["customer-identity-row"]
	);
	anIdentityRowArray.push(anIdentityFormRow);

	if (CacheManager.shared.loggedInCustomer != null
	&& CacheManager.shared.loggedInCustomer.identities instanceof Array
	&& CacheManager.shared.loggedInCustomer.identities.length > 0) {
		for (var anIndex = 0; anIndex < CacheManager.shared.loggedInCustomer.identities.length; anIndex ++) {
			var aCustomerIdentity = CacheManager.shared.loggedInCustomer.identities[anIndex];
			var aCellArray = [];
			aCellArray.push(
				AppTableCell.initWithTitle(
					aCustomerIdentity.value
					, ["customer-identity-email-cell"]
				)
			);
			if (aCustomerIdentity.verifiedOnUsdfDate != null) {
				var aVerifiedOnDate = ATDateManager.stringFromUsdfDateWithFormat(aCustomerIdentity.verifiedOnUsdfDate, "dd-mmm-yyyy");
				aCellArray.push(
					AppTableCell.initWithTitle(
						aVerifiedOnDate
						, ["customer-identity-date-cell"]
					)
				);
			} else {
				aCellArray.push(
					AppTableCell.initWithView(
						<AppButton
							title={LocalizationManager.customerProfileDetailsComponent_resendVerificationCodeCtaTitle()}
							type={AppButtonType.arcRect}
							didSelectCallback={this.didSelectResendVerificationCode.bind(this, aCustomerIdentity.uuid)}
							inputClassNames={null}
						/>
						, ["customer-identity-date-cell"]
					)
				);
			}
			aCellArray.push(
				AppTableCell.initWithView(
					<AppButton
						title={LocalizationManager.customerProfileDetailsComponent_deleteCtaTitle()}
						type={AppButtonType.arcRect}
						didSelectCallback={this.didSelectDeleteCustomerIdentity.bind(this, aCustomerIdentity.uuid)}
						inputClassNames={["customer-profile-details-arc-button"]}
						isDestructive={true}
					/>
					, ["customer-identity-cta-cell"]
				)
			);
			anIdentityRowArray.push(
				new AppTableRow(
					aCellArray
					, ["customer-identity-row"]
				)
			);
		}
	}

	var anIdentitySection = [];
	anIdentitySection.push(<AppSpacer height="40px" key="identity_top_space" />);
	anIdentitySection.push(<div className="customer-profile-details-section-title" key="identity_title">{LocalizationManager.customerProfileDetailsComponent_identitySectionTitle()}</div>);
	anIdentitySection.push(<AppSpacer height="8px" key="identity_line_space" />);
	anIdentitySection.push(<AppTable data={anIdentityRowArray} key="identity_list" />);
	aReturnVal = anIdentitySection;

	return aReturnVal;
}

CustomerProfileDetailsComponent.prototype.updatePasswordSectionView = function() {
	var aReturnVal = null;

	var anUpdatePasswordFormRowArray = [];

	anUpdatePasswordFormRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.customerProfileDetailsComponent_updatePasswordNewPasswordInputTitle() + " :"}
						placeholder={LocalizationManager.customerProfileDetailsComponent_textInputPlaceholder()}
						shouldSecureTextEntry={true}
						ref={this.newPasswordTextField}
						inputClassNames={["customer-profile-details-text-field"]}
					/>
				)
				, AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.customerProfileDetailsComponent_updatePasswordNewPasswordRetypeInputTitle() + " :"}
						placeholder={LocalizationManager.customerProfileDetailsComponent_textInputPlaceholder()}
						shouldSecureTextEntry={true}
						ref={this.newPasswordRetypeTextField}
						inputClassNames={["customer-profile-details-text-field"]}
					/>
				)
			]
			, ["update-customer-password-row"]
		)
	);

	anUpdatePasswordFormRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.customerProfileDetailsComponent_updatePasswordCurrentPasswordInputTitle() + " :"}
						placeholder={LocalizationManager.customerProfileDetailsComponent_textInputPlaceholder()}
						shouldSecureTextEntry={true}
						ref={this.currentPasswordTextField}
						inputClassNames={["customer-profile-details-text-field"]}
					/>
				)
				, AppTableCell.initWithView(
					<div></div>
				)
			]
			, ["update-customer-password-row"]
		)
	);

	anUpdatePasswordFormRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppButton
						title={LocalizationManager.customerProfileDetailsComponent_updateCtaTitle()}
						type={AppButtonType.arcRect}
						didSelectCallback={this.didSelectUpdateCustomerPassword.bind(this)}
						inputClassNames={["customer-profile-details-arc-button"]}
					/>
					, ["update-customer-password-cell"]
				)
			]
			, ["update-customer-password-row"]
		)
	);

	var anUpdatePasswordSection = [];
	anUpdatePasswordSection.push(<AppSpacer height="40px" key="update_password_top_space" />);
	anUpdatePasswordSection.push(<div className="customer-profile-details-section-title" key="update_password_title">{LocalizationManager.customerProfileDetailsComponent_updatePasswordSectionTitle()}</div>);
	anUpdatePasswordSection.push(<AppSpacer height="8px" key="update_password_line_space" />);
	anUpdatePasswordSection.push(<AppTable data={anUpdatePasswordFormRowArray} key="update_password_list" />);

	aReturnVal = anUpdatePasswordSection;

	return aReturnVal;
}

CustomerProfileDetailsComponent.prototype.updateCustomerProfileView = function() {
	var aReturnVal = null;

	var anUpdateCustomerProfileFormRowArray = [];

	var anUpdateCustomerProfileFormRow = new AppTableRow(
		[
			AppTableCell.initWithView(
				<AppTextField
					title={LocalizationManager.customerProfileDetailsComponent_profileDetailsDisplayNameInputTitle() + " :"}
					placeholder={LocalizationManager.customerProfileDetailsComponent_textInputPlaceholder()}
					ref={this.displayNameTextField}
					inputClassNames={["customer-profile-details-text-field"]}
				/>
			)
			, AppTableCell.initWithView(
				<AppButton
					title={LocalizationManager.customerProfileDetailsComponent_updateCtaTitle()}
					type={AppButtonType.arcRect}
					didSelectCallback={this.didSelectUpdateCustomerProfile.bind(this)}
					inputClassNames={["customer-profile-details-arc-button"]}
				/>
				, ["customer-profile-details-cta-cell"]
			)
		]
	);
	anUpdateCustomerProfileFormRowArray.push(anUpdateCustomerProfileFormRow);

	aReturnVal = <AppTable data={anUpdateCustomerProfileFormRowArray} />;

	return aReturnVal;
}


// MARK:- Action Handlers

CustomerProfileDetailsComponent.prototype.didSelectUpdateCustomerProfile = function() {
	this.updateCustomerProfile();
}

CustomerProfileDetailsComponent.prototype.didSelectUpdateCustomerPassword = function() {
	this.updateCustomerPassword();
}

CustomerProfileDetailsComponent.prototype.didSelectNewCustomerIdentity = function() {
	this.newCustomerIdentity();
}

CustomerProfileDetailsComponent.prototype.didSelectDeleteCustomerIdentity = function(pCustomerIdentityUuid) {
	var aThis = this;
	PopupManager.shared.displayConfirmationMessage(LocalizationManager.customerProfileDetailsComponent_deleteEmailConformationMessage(), function() {
		aThis.deleteCustomerIdentity(pCustomerIdentityUuid);
	})
}

CustomerProfileDetailsComponent.prototype.didSelectVerifyCustomerIdentity = function(pCustomerIdentity) {
	this.verifyCustomerIdentity(pCustomerIdentity);
}

CustomerProfileDetailsComponent.prototype.didSelectResendVerificationCode = function(pCustomerIdentityUuid) {
	this.newVerifyCustomerIdentityCode(pCustomerIdentityUuid);
}


// MARK:- Data Manager

CustomerProfileDetailsComponent.prototype.reloadLoggedInCustomerData = function() {
	// Completion handler is not required because notification will reload all views.
	CacheManager.shared.reloadLoggedInUser(true);
}

CustomerProfileDetailsComponent.prototype.updateCustomerProfile = function() {
	var aCustomer = new Customer()
	aCustomer.uuid = this.props.params.uuid;
	aCustomer.displayName = this.displayNameTextField.current.value;

	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.updateCustomerProfile(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			var aMessage = "Request processed successfully.";
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
			aThis.isLoadedOnce = false;
			aThis.reloadAllData();
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = "Unknown error.";
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, aCustomer);
}

CustomerProfileDetailsComponent.prototype.updateCustomerPassword = function() {
	try {
		var aPlainTextNewPassword = this.newPasswordTextField.current.value;
		if (aPlainTextNewPassword == null || aPlainTextNewPassword.length <= 0) {
			throw new Error(LocalizationManager.customerProfileDetailsComponent_provideNewPassword());
		}
		var aPlainTextNewRetypePassword = this.newPasswordRetypeTextField.current.value;
		if (aPlainTextNewRetypePassword == null || aPlainTextNewRetypePassword.length <= 0) {
			throw new Error(LocalizationManager.customerProfileDetailsComponent_retypeNewPassword());
		}
		if (aPlainTextNewPassword != aPlainTextNewRetypePassword) {
			throw new Error(LocalizationManager.customerProfileDetailsComponent_newRetypePasswordDoNotMatch());
		}
		var aNewPasswordHash = ATEncryptionManager.sha512(aPlainTextNewPassword);

		var aPlainTextPassword = this.currentPasswordTextField.current.value;
		if (aPlainTextPassword == null || aPlainTextPassword.length <= 0) {
			throw new Error(LocalizationManager.customerProfileDetailsComponent_provideCurrentPassword());
		}
		var aPasswordHash = ATEncryptionManager.sha512(aPlainTextPassword);
		var anEncryptedPassword = ATEncryptionManager.sha512(aPasswordHash + aPasswordHash);

		var aCustomer = new Customer()
		aCustomer.uuid = this.props.params.uuid;
		aCustomer.encryptedPassword = anEncryptedPassword;
		aCustomer.newPasswordHash = aNewPasswordHash;

		var aThis = this;

		ProgressIndicationManager.shared.showLoadingOverlay();
		DataTransferManager.shared.updateCustomerPassword(function(pResult) {
			ProgressIndicationManager.shared.hideLoadingOverlay();
			if (pResult.signet === DataTransferManagerResultSignet.success) {
				var aMessage = "Request processed successfully.";
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displaySuccessMessage(aMessage);
				aThis.isLoadedOnce = false;
				aThis.reloadAllData();
			} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
				RoutingManager.shared.gotoSignUpLoginCustomer();
			} else {
				var aMessage = "Unknown error.";
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displayErrorMessage(aMessage);
			}
			aThis.reloadAllViews();
		}, aCustomer);
	} catch (pError) {
		PopupManager.shared.displayError(pError);
	}
}

CustomerProfileDetailsComponent.prototype.newCustomerIdentity = function() {
	try {
		var anEmailAddress = this.identityEmailAddressTextField.current.value;
		if (anEmailAddress == null || anEmailAddress.length <= 0) {
			throw new Error(LocalizationManager.customerProfileDetailsComponent_provideEmailAddress());
		}

		var aCustomerIdentity = new CustomerIdentity()
		aCustomerIdentity.typeSignet = CustomerIdentityType.email;
		aCustomerIdentity.value = anEmailAddress;

		var aThis = this;

		ProgressIndicationManager.shared.showLoadingOverlay();
		DataTransferManager.shared.newCustomerIdentity(function(pResult) {
			ProgressIndicationManager.shared.hideLoadingOverlay();
			if (pResult.signet === DataTransferManagerResultSignet.success) {
				aThis.resetAllViews();
				var aMessage = "Request processed successfully.";
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displaySuccessMessage(aMessage);
				aThis.isLoadedOnce = false;
				aThis.reloadAllData();
			} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
				RoutingManager.shared.gotoSignUpLoginCustomer();
			} else {
				var aMessage = "Unknown error.";
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displayErrorMessage(aMessage);
			}
			aThis.reloadAllViews();
		}, aCustomerIdentity);
	} catch (pError) {
		PopupManager.shared.displayError(pError);
	}
}

CustomerProfileDetailsComponent.prototype.deleteCustomerIdentity = function(pCustomerIdentityUuid) {
	try {
		var aCustomerIdentity = new CustomerIdentity()
		aCustomerIdentity.uuid = pCustomerIdentityUuid;

		var aThis = this;

		ProgressIndicationManager.shared.showLoadingOverlay();
		DataTransferManager.shared.deleteCustomerIdentity(function(pResult) {
			ProgressIndicationManager.shared.hideLoadingOverlay();
			if (pResult.signet === DataTransferManagerResultSignet.success) {
				aThis.resetAllViews();
				var aMessage = "Request processed successfully.";
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displaySuccessMessage(aMessage);
				aThis.isLoadedOnce = false;
				aThis.reloadAllData();
			} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
				RoutingManager.shared.gotoSignUpLoginCustomer();
			} else {
				var aMessage = "Unknown error.";
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displayErrorMessage(aMessage);
			}
			aThis.reloadAllViews();
		}, aCustomerIdentity);
	} catch (pError) {
		PopupManager.shared.displayError(pError);
	}
}

CustomerProfileDetailsComponent.prototype.verifyCustomerIdentity = function(pCustomerIdentity) {
	try {
		var anEmailAddress = pCustomerIdentity.value;
		if (anEmailAddress == null || anEmailAddress.length <= 0) {
			throw new Error(LocalizationManager.customerProfileDetailsComponent_provideEmailAddress());
		}

		var aVerificationCode = this.identityVerificationCodeTextField.current.value;
		if (aVerificationCode == null || aVerificationCode.length <= 0) {
			throw new Error(LocalizationManager.customerProfileDetailsComponent_provideVerificationCode());
		}
		var aVerificationCodeHash = ATEncryptionManager.sha512(aVerificationCode);

		var aCustomerIdentity = new CustomerIdentity()
		aCustomerIdentity.typeSignet = "email";
		aCustomerIdentity.value = anEmailAddress;
		aCustomerIdentity.verificationCodeHash = aVerificationCodeHash;

		var aThis = this;

		ProgressIndicationManager.shared.showLoadingOverlay();
		DataTransferManager.shared.verifyCustomerIdentity(function(pResult) {
			ProgressIndicationManager.shared.hideLoadingOverlay();
			if (pResult.signet === DataTransferManagerResultSignet.success) {
				aThis.resetAllViews();
				var aMessage = "Request processed successfully.";
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displaySuccessMessage(aMessage);
				aThis.isLoadedOnce = false;
				aThis.reloadAllData();
			} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
				RoutingManager.shared.gotoSignUpLoginCustomer();
			} else {
				var aMessage = "Unknown error.";
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displayErrorMessage(aMessage);
			}
			aThis.reloadAllViews();
		}, aCustomerIdentity);
	} catch (pError) {
		PopupManager.shared.displayError(pError);
	}
}

CustomerProfileDetailsComponent.prototype.newVerifyCustomerIdentityCode = function(pCustomerIdentityUuid) {
	try {
		var aCustomerIdentity = new CustomerIdentity()
		aCustomerIdentity.uuid = pCustomerIdentityUuid;

		var aThis = this;

		ProgressIndicationManager.shared.showLoadingOverlay();
		DataTransferManager.shared.newVerifyCustomerIdentityCode(function(pResult) {
			ProgressIndicationManager.shared.hideLoadingOverlay();
			if (pResult.signet === DataTransferManagerResultSignet.success) {
				aThis.resetAllViews();
				var aMessage = "Request processed successfully.";
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displaySuccessMessage(aMessage);
				aThis.isLoadedOnce = false;
				aThis.reloadAllData();
			} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
				RoutingManager.shared.gotoSignUpLoginCustomer();
			} else {
				var aMessage = "Unknown error.";
				if (pResult.localizedDescription != null) {
					aMessage = pResult.localizedDescription;
				}
				PopupManager.shared.displayErrorMessage(aMessage);
			}
			aThis.reloadAllViews();
		}, aCustomerIdentity);
	} catch (pError) {
		PopupManager.shared.displayError(pError);
	}
}


export default withParams(CustomerProfileDetailsComponent);
