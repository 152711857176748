import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Session } from '../../models/session';

import { ConfigurationManager, AppEnvironment } from '../../collaborators/configuration-manager';
import { RoutingManager, URL } from '../../collaborators/routing-manager';
import { NavigationBarComponent } from '../../modules/foundation/navigation-bar-component';
import CeilingBarComponent from './ceiling-bar-component';
import CustomerProfileDetailsComponent from '../../modules/customer/customer-profile-details-component';
import NewProductComponent from '../../modules/product/new-product-component';
import SearchProductComponent from '../../modules/product/search-product-component';
import ProductDetailsComponent from '../../modules/product/product-details-component';
import SearchProductInvitationComponent from '../../modules/product/search-product-invitation-component';
import SearchTicketComponent from '../../modules/ticket/search-ticket-component';
import TicketDetailsComponent from '../../modules/ticket/ticket-details-component';
import SearchPlanComponent from '../../modules/subscription/search-plan-component';
import SubscriptionDetailsComponent from '../../modules/subscription/subscription-details-component';
import SignupLoginCustomerComponent from '../../modules/customer/signup-login-customer-component';
import ResetPasswordComponent from '../../modules/customer/reset-password-component';
import HomePageComponent from '../../modules/home/home-page-component';
import SearchKnowledgebaseComponent from '../../modules/knowledgebase/search-knowledgebase-component';
import SupportDetailsComponent from '../../modules/support/support-details-component';
import PageNotFoundComponent from './page-not-found-component';

import './foundation-component.css';
import '../app-ui-kit/app-ui-kit.css';
import { DataTransferManager, DataTransferManagerEnvironmentType } from '../../data-manager/data-transfer-manager';
import { CacheManager } from '../../collaborators/cache-manager';
import { MenuBarComponent } from './menu-bar-component';


class FoundationComponent extends Component {
	ceilingBarHeaderLabelReference = React.createRef()

	componentDidMount() {
		CacheManager.shared.reloadLoggedInUser();
	}

	render() {
		var aFoundationBuildingArray = [];
		if (RoutingManager.shared.doesPageExist === false) {
			aFoundationBuildingArray.push(<PageNotFoundComponent key="page_not_found" />);
		} else if (RoutingManager.shared.isHomeScreenDisplayed === true) {
			aFoundationBuildingArray.push(<HomePageComponent key="home" />);
		} else if (RoutingManager.shared.isSignUpLoginScreenDisplayed === true) {
			aFoundationBuildingArray.push(<SignupLoginCustomerComponent key="login_signup" />);
		} else if (RoutingManager.shared.isResetPasswordScreenDisplayed === true) {
			aFoundationBuildingArray.push(<ResetPasswordComponent key="reset_password" />);
		} else if (RoutingManager.shared.isSearchPlanScreenDisplayed === true) {
			aFoundationBuildingArray.push(<SearchPlanComponent key="search_plan" />);
		} else if (RoutingManager.shared.isSearchKnowledgebaseScreenDisplayed === true) {
			aFoundationBuildingArray.push(
				<div className="foundation-basement-bordered" key="basement_bordered">
					<SearchKnowledgebaseComponent key="search_knowledgebase" />
				</div>
			)
		} else if (RoutingManager.shared.isSupportDetailsScreenDisplayed === true) {
			aFoundationBuildingArray.push(<SupportDetailsComponent key="support_details" />);
		} else {
			aFoundationBuildingArray.push(
				<div className="foundation-basement-bordered" key="basement_bordered">
					<NavigationBarComponent key="navigation_bar" />
					<div className="foundation-building" key="details_container">
						<CeilingBarComponent headerLabelReference={this.ceilingBarHeaderLabelReference} />
						<BrowserRouter basename={ConfigurationManager.appRouterBaseName}>
							<Routes>
								<Route exact={true} path={URL.newProductPath()} element={<NewProductComponent/>} />
								<Route exact={true} path={URL.searchProductPath()} element={<SearchProductComponent/>} />
								<Route exact={true} path={URL.productDetailsPath()} element={<ProductDetailsComponent ceilingBarHeaderLabel={this.ceilingBarHeaderLabelReference} />} />
								<Route exact={true} path={URL.searchProductInvitationPath()} element={<SearchProductInvitationComponent/>} />
								<Route exact={true} path={URL.searchTicketPath()} element={<SearchTicketComponent/>} />
								<Route exact={true} path={URL.ticketDetailsPath()} element={<TicketDetailsComponent/>} />
								<Route exact={true} path={URL.customerProfileDetailsPath()} element={<CustomerProfileDetailsComponent/>} />
								<Route exact={true} path={URL.subscriptionDetailsPath()} element={<SubscriptionDetailsComponent ceilingBarHeaderLabel={this.ceilingBarHeaderLabelReference} />} />
							</Routes>
						</BrowserRouter>
					</div>
				</div>
			)
		}

		var aVersion = "v" + ConfigurationManager.appVersion
		var aVersionClassNameArray = []
		aVersionClassNameArray.push("app-version")
		if (ConfigurationManager.appEnvironmentSignet !== AppEnvironment.production.signet()) {
			aVersion += " - " + ConfigurationManager.appEnvironmentSignet
		}

		return (
			<div className="foundation-slab">
				<div className="foundation-tiles">&nbsp;</div>
				<MenuBarComponent />
				{ aFoundationBuildingArray }
				<div className={aVersionClassNameArray.join(" ")}>{aVersion}</div>
			</div>
		);
	}
}

export default FoundationComponent;
