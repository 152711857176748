import BaseComponent from '../app-ui-kit/base-component';
import { RoutingManager } from "../../collaborators/routing-manager"
import { DataTransferManager, DataTransferManagerResultSignet } from "../../data-manager/data-transfer-manager"
import { ProgressIndicationManager } from '../../collaborators/progress-indication-manager';
import { PopupManager } from '../../collaborators/popup-manager';
import { AppTable, AppTableRow, AppTableCell } from '../app-ui-kit/app-table';
import { AppButton } from '../app-ui-kit/app-button';
import { CacheManager } from '../../collaborators/cache-manager';

import "../app-ui-kit/app-ui-kit.css"
import "./search-product-invitation-component.css"
import { LocalizationManager } from '../../localization-manager/localization-manager';


class SearchProductInvitationComponent extends BaseComponent {
	productInvitations = null;

	reloadAllData() {
		if (CacheManager.shared.loggedInCustomer != null) {
			this.searchProductInvitation();
		}
	}

	render() {
		var aReturnVal = this.loginRequiredView();
		if (CacheManager.shared.loggedInCustomer != null) {
			aReturnVal = this.searchProductInvitationView();
		}
		return aReturnVal;
	}

}


// MARK:- Views

SearchProductInvitationComponent.prototype.searchProductInvitationView = function() {
	// Create table rows
	var aProductRowArray = [];
	var aNoDataView = null;

	var aHeaderRow = new AppTableRow(
		[
			AppTableCell.initWithTitle(LocalizationManager.searchProductInvitationComponent_titleRowTitle())
			, AppTableCell.initWithTitle(LocalizationManager.searchProductInvitationComponent_roleRowTitle())
			, AppTableCell.initWithTitle("", ["search-product-invitation-accept-cell"])
		]
		, null
		, null
		, true
	);
	aProductRowArray.push(aHeaderRow);

	if (this.productInvitations instanceof Array && this.productInvitations.length > 0) {
		for (var anIndex = 0; anIndex < this.productInvitations.length; anIndex ++) {
			var aProductInvitation = this.productInvitations[anIndex];
			var aCellArray = [];
			aCellArray.push(AppTableCell.initWithTitle(aProductInvitation.product.title));
			aCellArray.push(AppTableCell.initWithTitle(aProductInvitation.role.localizedTitle()));
			aCellArray.push(AppTableCell.initWithView(
				<AppButton
					title={LocalizationManager.newProductComponent_acceptButtonTitle()}
					inputClassNames={["search-product-invitation-accept-button"]}
					didSelectCallback={this.didSelectAcceptProductInvitationButton.bind(this, aProductInvitation.uuid)}
				/>
				, ["search-product-invitation-accept-cell"])
			);
			aProductRowArray.push(new AppTableRow(aCellArray));
		}
	} else {
		aNoDataView = <div className="no-data-view">{LocalizationManager.searchProductInvitationComponent_noDataMessage()}</div>;
	}

	return (
		<div className="foundation-storey vertical-scroll-view">
			<AppTable data={aProductRowArray} />
			{aNoDataView}
		</div>
	);
}


// MARK:- Action Handlers

SearchProductInvitationComponent.prototype.didSelectAcceptProductInvitationButton = function(pProductInvitationUuid) {
	var aThis = this;
		PopupManager.shared.displayConfirmationMessage(
			LocalizationManager.searchProductInvitationComponent_acceptInvitationConfirmationMessage()
			, function() {
				aThis.acceptProductInvitation(pProductInvitationUuid);
			}
		)
}


// MARK:- Data Manager

SearchProductInvitationComponent.prototype.searchProductInvitation = function() {
	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.searchProductInvitation(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			if (pResult.outcome instanceof Array) {
				aThis.productInvitations = pResult.outcome;
			} else {
				aThis.productInvitations = null;
			}
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	});
}

SearchProductInvitationComponent.prototype.acceptProductInvitation = function(pProductInvitationUuid) {
	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.acceptProductInvitation(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			var aMessage = LocalizationManager.common_apiResponseSuccessMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
			aThis.reloadAllData();
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, pProductInvitationUuid);
}

export default SearchProductInvitationComponent;
