import BaseComponent from '../app-ui-kit/base-component';
import { RoutingManager, URL } from '../../collaborators/routing-manager';
import { AppSpacer } from '../app-ui-kit/app-ui-kit';
import { LocalizationManager } from '../../localization-manager/localization-manager';
import '../../localization-manager/localization-manager-foundation';

import './navigation-bar-component.css';


class NavigationBarComponent extends BaseComponent {


	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	reloadAllData() {
		this.sections = [];

		// Hide ticket section till its official launch.
		// var aTicketSection = this.ticketNavigationBarSection();
		// this.sections.push(aTicketSection);

		var aProductSection = this.productNavigationBarSection()
		this.sections.push(aProductSection);

		var aMiscellaneousSection = this.miscellaneousNavigationBarSection()
		this.sections.push(aMiscellaneousSection);

		this.reloadAllViews();
	}

	reloadAllViews() {
		this.forceUpdate();
	}

	render() {
		var aNavigationBarRowArray = [];

		if (this.sections instanceof Array) {
			for (var aSectionIndex = 0; aSectionIndex < this.sections.length; aSectionIndex ++) {
				var aSection = this.sections[aSectionIndex];

				var aLeftIcon = (
					<div className="navigation-bar-cell navigation-bar-cell-icon">
						<div className={"navigation-bar-icon " + (aSection.leftIconClass != null ? aSection.leftIconClass : "")}>&nbsp;</div>
					</div>
				);

				var aTitle = (
					<div className="navigation-bar-cell">
						{aSection.title}
					</div>
				);

				var aRightIcon = null;
				if (aSection.rightIconClass != null) {
					aRightIcon = (<div className="navigation-bar-cell navigation-bar-cell-icon" onClick={this.didSelectNavigationBarSectionRightIcon.bind(this, aSection.signet)}>
						<div className={"navigation-bar-icon " + aSection.rightIconClass + " navigation-bar-button"}>&nbsp;</div>
					</div>);
				}

				aNavigationBarRowArray.push(
					<div className="navigation-bar-row navigation-bar-row-header" key={"Section_" + aSectionIndex + "_" + anItemIndex}>
						{aLeftIcon}
						{aTitle}
						{aRightIcon}
					</div>
				);

				if (aSection.items instanceof Array) {
					var aSelectedSignet = null;
					if (RoutingManager.shared.currentPath === URL.newProduct()) {
						aSelectedSignet = NavigationBarItem.Signet.newProduct;
					} else if (RoutingManager.shared.currentPath === URL.searchProduct()) {
						aSelectedSignet = NavigationBarItem.Signet.myProducts;
					} else if (RoutingManager.shared.currentPath === URL.searchProductInvitation()) {
						aSelectedSignet = NavigationBarItem.Signet.productInvitations;
					} else if (RoutingManager.shared.currentPath === URL.searchTicket()) {
						aSelectedSignet = NavigationBarItem.Signet.searchTicket;
					} else if (RoutingManager.shared.currentPath === URL.customerProfileDetails()) {
						aSelectedSignet = NavigationBarItem.Signet.customerProfileDetails;
					} else if (RoutingManager.shared.currentPath === URL.subscriptionDetails()) {
						aSelectedSignet = NavigationBarItem.Signet.subscriptionDetails;
					}
					for (var anItemIndex = 0; anItemIndex < aSection.items.length; anItemIndex ++) {
						var anItem = aSection.items[anItemIndex];
						var anIsItemSelected = false;
						if (aSelectedSignet != null && aSelectedSignet === anItem.signet) {
							anIsItemSelected = true;
						}
						aNavigationBarRowArray.push(
							<div className={"navigation-bar-row" + (anIsItemSelected ? " navigation-bar-row-selected" : "")} onClick={this.didSelectNavigationBarItem.bind(this, new IndexPath(aSectionIndex, anItemIndex))} key={"Row_" + aSectionIndex + "_" + anItemIndex}>
								<div className="navigation-bar-cell navigation-bar-cell-icon">&nbsp;</div>
								<div className="navigation-bar-cell">{anItem.title}</div>
							</div>
						)
					}
				}
			}
		}

		return (
			<div className="navigation-bar">

			<div className="navigation-bar-header">
				<div className="navigation-bar-header-icon">&nbsp;</div>
				<div style={{"width":"14px"}}></div>
				<div className="navigation-bar-header-text">{LocalizationManager.navigationBarComponent_headerTitle()}</div>
			</div>

			<div className="navigation-bar-table vertical-scroll-view">
				<AppSpacer height="8px" />
				{aNavigationBarRowArray}
			</div>

			</div>
		);
	}

	ticketNavigationBarSection() {
		var aReturnVal = new NavigationBarSection();
		aReturnVal.leftIconClass = "navigation-bar-icon-ticket";
		aReturnVal.title = LocalizationManager.navigationBarComponent_ticketSectionTitle();
		aReturnVal.items = [];
		aReturnVal.items.push(new NavigationBarItem(NavigationBarItem.Signet.searchTicket, LocalizationManager.navigationBarComponent_searchTicketTitle()));
		return aReturnVal;
	}

	productNavigationBarSection() {
		var aReturnVal = new NavigationBarSection();
		aReturnVal.leftIconClass = "navigation-bar-icon-product";
		aReturnVal.title = LocalizationManager.navigationBarComponent_productSectionTitle();
		aReturnVal.items = [];
		aReturnVal.items.push(new NavigationBarItem(NavigationBarItem.Signet.newProduct, LocalizationManager.navigationBarComponent_newProductTitle()));
		aReturnVal.items.push(new NavigationBarItem(NavigationBarItem.Signet.myProducts, LocalizationManager.navigationBarComponent_searchProductTitle()));
		aReturnVal.items.push(new NavigationBarItem(NavigationBarItem.Signet.productInvitations, LocalizationManager.navigationBarComponent_searchProductInvitationTitle()));
		return aReturnVal;
	}

	miscellaneousNavigationBarSection() {
		var aReturnVal = new NavigationBarSection();
		aReturnVal.leftIconClass = "navigation-bar-icon-miscellaneous";
		aReturnVal.title = LocalizationManager.navigationBarComponent_miscellaneousSectionTitle();
		aReturnVal.items = [];
		aReturnVal.items.push(new NavigationBarItem(NavigationBarItem.Signet.customerProfileDetails, LocalizationManager.navigationBarComponent_profileDetailsTitle()));
		// Hide subscription row till its official launch.
		// aReturnVal.items.push(new NavigationBarItem(NavigationBarItem.Signet.subscriptionDetails, LocalizationManager.navigationBarComponent_mySubscriptionTitle()));
		return aReturnVal;
	}

	didSelectNavigationBarItem(pIndexPath) {
		if (this.sections != null && this.sections.length > pIndexPath.sectionIndex) {
			var aSection = this.sections[pIndexPath.sectionIndex];
			if (aSection.items != null && aSection.items.length > pIndexPath.rowIndex) {
				var anItem = aSection.items[pIndexPath.rowIndex];
				if (anItem.signet === NavigationBarItem.Signet.customerProfileDetails) {
					RoutingManager.shared.gotoCustomerProfileDetails();
				} else if (anItem.signet === NavigationBarItem.Signet.searchTicket) {
					RoutingManager.shared.gotoSearchTicket();
				} else if (anItem.signet === NavigationBarItem.Signet.newProduct) {
					RoutingManager.shared.gotoNewProduct();
				} else if (anItem.signet === NavigationBarItem.Signet.myProducts) {
					RoutingManager.shared.gotoSearchProduct();
				} else if (anItem.signet === NavigationBarItem.Signet.productInvitations) {
					RoutingManager.shared.gotoSearchProductInvitation();
				} else if (anItem.signet === NavigationBarItem.Signet.searchPlan) {
					RoutingManager.shared.gotoSearchPlan();
				} else if (anItem.signet === NavigationBarItem.Signet.subscriptionDetails) {
					RoutingManager.shared.gotoSubscriptionDetails();
				}
			}
		}
	}

	didSelectNavigationBarSectionRightIcon(pSignet) {

	}

}


class NavigationBarSection {
	constructor() {
		this.signet = null;
		this.leftIconClass = null;
		this.rightIconClass = null;
		this.title = null;
		this.items = null;
	}
}


class NavigationBarItem {
	static Signet = {
		customerProfileDetails : "profile"
		, newProduct : "new_product"
		, myProducts : "search_product"
		, productInvitations : "product_invitations"
		, searchTicket : "search_ticket"
		, searchPlan : "search_plan"
		, subscriptionDetails : "subscription_details"
	}

	signet = null;
	title = null;

	constructor(pSignet, pTitle) {
		this.signet = pSignet;
		this.title = pTitle;
	}
}


class IndexPath {
	constructor(pSection, pRow) {
		this.sectionIndex = pSection;
		this.rowIndex = pRow;
	}
}

export { NavigationBarComponent, NavigationBarItem }
