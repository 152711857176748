import BaseComponent from '../app-ui-kit/base-component';
import { AppSpacer } from '../app-ui-kit/app-ui-kit';
import { AppButton, AppButtonType } from '../app-ui-kit/app-button';
import { RoutingManager } from '../../collaborators/routing-manager';
import { CacheManager } from '../../collaborators/cache-manager';
import { ConfigurationManager } from '../../collaborators/configuration-manager';
import { LocalizationManager } from '../../localization-manager/localization-manager';
import '../../localization-manager/localization-manager-home';

import "./home-page-component.css"


class HomePageComponent extends BaseComponent {

	render() {
		return this.homePageViewAppUpdatePopupOnly()
	}

	homePageView() {
		return (
			<div className="foundation-storey home-page-storey">

				<div className="home-page-header">
					<span className="home-page-header-hightlighter">{ LocalizationManager.homePageComponent_headerAppName() }</span>
					<span> - </span>
					<span>{ LocalizationManager.homePageComponent_headerAppTagLine() }</span>
				</div>

				<AppSpacer height="15px" />

				<div className="home-page-subheader">
					{ LocalizationManager.homePageComponent_subheader() }
				</div>

				<AppSpacer height="40px" />

				<div className="home-page-feature-header">
					{ LocalizationManager.homePageComponent_featureHeader() }
				</div>

				<AppSpacer height="15px" />

				<div className="home-page-feature-summary">{ LocalizationManager.homePageComponent_featureSummary() }</div>

				<AppSpacer height="50px" />

				<div className="feature-row">
					<div className="feature-column-text">
						<div className="feature-column-text-title">{ LocalizationManager.homePageComponent_featureWorksFromWithinAppTitle() }</div>
						<div>{ LocalizationManager.homePageComponent_featureWorksFromWithinAppSummary01() }</div>
						<div>{ LocalizationManager.homePageComponent_featureWorksFromWithinAppSummary02() }</div>
					</div>
					<div className="feature-column-image">
						<div className="feature-apple-review-guidelines"></div>
					</div>
				</div>

				<AppSpacer height="70px" />

				<div className="feature-row feature-row-even">
					<div className="feature-column-text">
						<div className="feature-column-text-title">{ LocalizationManager.homePageComponent_featureNoRegistrationRequiredTitle() }</div>
						<div>{ LocalizationManager.homePageComponent_featureNoRegistrationRequiredSummary01() }</div>
					</div>
					<div className="feature-column-image">
						<div className="feature-apple-review-guidelines"></div>
					</div>
				</div>

				<AppSpacer height="70px" />

				<div className="feature-row">
					<div className="feature-column-text">
						<div className="feature-column-text-title">{ LocalizationManager.homePageComponent_featureBetterTeamCollborationTitle() }</div>
						<div>{ LocalizationManager.homePageComponent_featureBetterTeamCollborationSummary01() }</div>
						<div>{ LocalizationManager.homePageComponent_featureBetterTeamCollborationSummary02() }</div>
					</div>
					<div className="feature-column-image">
						<div className="feature-apple-review-guidelines"></div>
					</div>
				</div>

				<AppSpacer height="70px" />

				<div className="feature-row feature-row-even">
					<div className="feature-column-text">
						<div className="feature-column-text-title">{ LocalizationManager.homePageComponent_featureStayWellOrganizedTitle() }</div>
						<div>{ LocalizationManager.homePageComponent_featureStayWellOrganizedSummary01() }</div>
						<div>{ LocalizationManager.homePageComponent_featureStayWellOrganizedSummary02() }</div>
						<div>{ LocalizationManager.homePageComponent_featureStayWellOrganizedSummary03() }</div>
						<div>{ LocalizationManager.homePageComponent_featureStayWellOrganizedSummary04() }</div>
					</div>
					<div className="feature-column-image">
						<div className="feature-apple-review-guidelines"></div>
					</div>
				</div>

				<AppSpacer height="70px" />

				<div className="feature-row">
					<div className="feature-column-text">
						<div className="feature-column-text-title">{ LocalizationManager.homePageComponent_featureEasyIntegrationTitle() }</div>
						<div>{ LocalizationManager.homePageComponent_featureEasyIntegrationSummary01() }</div>
					</div>
					<div className="feature-column-image">
						<div className="feature-apple-review-guidelines"></div>
					</div>
				</div>

				<AppSpacer height="70px" />

				<div className="feature-row feature-row-even">
					<div className="feature-column-text">
					<div className="feature-column-text-title">{ LocalizationManager.homePageComponent_featureBetterCommunicationTitle() }</div>
						<div>{ LocalizationManager.homePageComponent_featureBetterCommunicationSummary01() }</div>
					</div>
					<div className="feature-column-image">
						<div className="feature-apple-review-guidelines"></div>
					</div>
				</div>

				<AppSpacer height="50px" />

			</div>
		);
	}

	homePageViewAppUpdatePopupOnly() {
		return (
			<div className="foundation-storey home-page-storey">

				<div className="home-page-header">
					<span className="home-page-header-hightlighter">{ LocalizationManager.homePageComponent_appUpdatePopup_pageHeader01() }</span>
					<span> - </span>
					<span>{ LocalizationManager.homePageComponent_appUpdatePopup_pageHeader02() }</span>
				</div>

				<AppSpacer height="15px" />

				<div className="home-page-feature-summary">{ LocalizationManager.homePageComponent_appUpdatePopup_pageSubheader() }</div>

				<AppSpacer height="40px" />

				<div className="home-page-feature-appupdatepopup-box">
					<div className="home-page-feature-appupdatepopup-title">{ LocalizationManager.homePageComponent_featureAppUpdatePopupTitle() }</div>
					<AppSpacer height="10px" />
					<div>{ LocalizationManager.homePageComponent_featureAppUpdatePopupSummary01() }</div>
					<AppSpacer height="20px" />
					<iframe
						className="home-page-feature-appupdatepopup-preview"
						frameborder="0"
						referrerpolicy="strict-origin-when-cross-origin"
						allow="picture-in-picture;encrypted-media;"
						src={ ConfigurationManager.appUpdatePopupYoutubeVideoEmbededUrl }
					></iframe>
					<AppSpacer height="20px" />
					<div className="home-page-feature-appupdatepopup-button-container">
						<AppButton
							title={ LocalizationManager.homePageComponent_getStartedButtonTitle() }
							type={AppButtonType.arcRect}
							didSelectCallback={this.didSelectGetStarted.bind(this)}
						/>
						<AppSpacer width="20px" />
						<AppButton
							title={ LocalizationManager.homePageComponent_watchOnYoutubeButtonTitle() }
							type={AppButtonType.arcRect}
							didSelectCallback={this.didSelectFeatureAppUpdatePopupWatchOnYoutube.bind(this)}
						/>
					</div>
				</div>

				<AppSpacer height="50px" />

			</div>
		);
	}

	didSelectGetStarted() {
		if (CacheManager.shared.loggedInCustomer != null) {
			RoutingManager.shared.gotoSearchProduct();
		} else {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		}
	}

	didSelectFeatureAppUpdatePopupWatchOnYoutube() {
		RoutingManager.shared.gotoAppUpdatePopupYoutubeVideo();
	}

}

export default HomePageComponent;
