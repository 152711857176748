const kLocaleHindi = {
	"localizationManager_englishLocaleTitle" : "English" // This value will always be English otherwise english user can not understand it.
	, "localizationManager_hindiLocaleTitle" : "हिन्दी" // This value will always be हिन्दी otherwise hindi user can not understand it.
	, "localizationManager_thaiLocaleTitle" : "ไทย" // This value will always be ไทย otherwise thai user can not understand it.


	, "common_unknownApiResponseSignet" : "अज्ञात प्रतिक्रिया कोड।"
	, "common_unknownErrorMessage" : "अज्ञात त्रुटि।"
	, "common_apiResponseSuccessMessage" : "अनुरोध सफलतापूर्वक संसाधित हुआ।"
	, "common_dateFormatDateAndTime" : "dd-mmm-yyyy 'के' hh:MM tt"
	, "common_loginRequiredMessage" : "विवरण हेतु साइनअप / लॉग इन करें"


	, "signupLoginCustomerComponent_signupFormTitle" : "कोई खाता नहीं है?"
	, "signupLoginCustomerComponent_signupEmailPlaceholder" : "ई-मेल पता"
	, "signupLoginCustomerComponent_signupDisplayNamePlaceholder" : "प्रदर्शित होने वाला नाम"
	, "signupLoginCustomerComponent_signupPasswordPlaceholder" : "कूट शब्द"
	, "signupLoginCustomerComponent_signupConfirmPasswordPlaceholder" : "कूट शब्द की पुष्टि कीजिये"
	, "signupLoginCustomerComponent_signupButtonTitle" : "साइन अप करें"
	, "signupLoginCustomerComponent_signupButtonInstructionAcceptTerms" : "साइन अप करके, आप उपयोग की शर्तों और गोपनीयता नीति को समझते हैं और उससे सहमत होते हैं"
	, "signupLoginCustomerComponent_signupButtonInstructionNextStep" : "साइनअप के बाद, आपको पुष्टिकरण लिंक के साथ एक ईमेल प्राप्त होगा।"

	, "signupLoginCustomerComponent_separatorOr" : "अथवा"

	, "signupLoginCustomerComponent_loginFormTitle" : "अपने खाते पर जाएँ"
	, "signupLoginCustomerComponent_loginEmailPlaceholder" : "ई-मेल पता"
	, "signupLoginCustomerComponent_loginPasswordPlaceholder" : "कूट शब्द"
	, "signupLoginCustomerComponent_loginButtonTitle" : "लॉग इन करें"
	, "signupLoginCustomerComponent_loginButtonInstructionAcceptTerms" : "लॉग इन करके, आप उपयोग की शर्तों और गोपनीयता नीति को समझते हैं और उससे सहमत होते हैं"

	, "signupLoginCustomerComponent_forgotPasswordFormTitle" : "अपना कूट शब्द भूल गए?"
	, "signupLoginCustomerComponent_forgotPasswordButtonTitle" : "कूट शब्द रीसेट करने के लिए जाएं"


	, "homePageComponent_headerAppName" : "सहाय्यक"
	, "homePageComponent_headerAppTagLine" : "हल्की इन-ऐप सहायता प्रणाली"

	, "homePageComponent_subheader" : "आपको अपने ऐप उपयोगकर्ताओं को अधिक कुशलता से सहायता देने के लिए आवश्यक सभी चीज़ें।"

	, "homePageComponent_featureHeader" : "हमारे प्रायोजन"
	, "homePageComponent_featureSummary" : "हम एक हल्की लाइब्रेरी प्रदान करते हैं जिसे कोड की केवल एक पंक्ति के साथ आपके आयओएस या एंड्रॉइड ऐप के साथ एकीकृत किया जा सकता है। यह लाइब्रेरी समर्थन टिकट दृश्य और कार्यक्षमता उत्पन्न करने का ध्यान रखेगी।"

	, "homePageComponent_featureWorksFromWithinAppTitle" : "आपके मोबाइल ऐप के अंतर्गत काम करता है"
	, "homePageComponent_featureWorksFromWithinAppSummary01" : "इन-ऐप सहायता प्रणाली होने के कारण, सहाय्यक दृश्य आपके मोबाइल ऐप में प्रदर्शित किया जाएगा।"
	, "homePageComponent_featureWorksFromWithinAppSummary02" : "आपके उपयोगकर्ताओं को आपके सहायता दल के साथ संवाद करने के लिए अलग ऐप इंस्टॉल करने या आपके ऐप से बाहर जाने की आवश्यकता नहीं है।"

	, "homePageComponent_featureNoRegistrationRequiredTitle" : "किसी उपयोगकर्ता पंजीकरण की आवश्यकता नहीं है"
	, "homePageComponent_featureNoRegistrationRequiredSummary01" : "नई इन-ऐप प्रमाणीकरण तकनीक के साथ, आपके ऐप उपयोगकर्ताओं को प्रश्न निवेदित करने के लिए सहायता प्रणाली पर जटिल पंजीकरण और लॉग इन प्रक्रिया के माध्यम से जाने की आवश्यकता नहीं है।"

	, "homePageComponent_featureBetterTeamCollborationTitle" : "श्रेष्ठतर दल सहयोग"
	, "homePageComponent_featureBetterTeamCollborationSummary01" : "आप अपने सहायता दल में सहयोग करने के लिए जितने चाहें उतने लोगों को निमंत्रित कर सकते हैं।"
	, "homePageComponent_featureBetterTeamCollborationSummary02" : "यह सुनिश्चित करने के लिए कि आपके प्रत्येक ऐप उपयोगकर्ता की देखभाल की जा रही है, व्यक्तिगत दल सदस्य को टिकट नियुक्त करें।"

	, "homePageComponent_featureStayWellOrganizedTitle" : "श्रेष्ठतर संयोजित रहें"
	, "homePageComponent_featureStayWellOrganizedSummary01" : "नयी \"संदेश अभिगम स्तर\" सुविधा आपको संयोजित पद्धति से काम करने में मदद करती है।"
	, "homePageComponent_featureStayWellOrganizedSummary02" : "सहायता टिकट के बारे में नोट्स रखने के लिए \"निजी संदेशों\" का उपयोग करें।"
	, "homePageComponent_featureStayWellOrganizedSummary03" : "अपने साथी दल सदस्यों के बीच सहायता टिकट के बारे में टिप्पणी साझा करने के लिए \"आंतरिक संदेशों\" का उपयोग करें।"
	, "homePageComponent_featureStayWellOrganizedSummary04" : "अलग प्रलेख बनाने की तथा संदर्भित करने की कोई आवश्यकता नहीं है।"

	, "homePageComponent_featureEasyIntegrationTitle" : "आसान एकीकरण, कोई तकनीकी कंटक नहीं"
	, "homePageComponent_featureEasyIntegrationSummary01" : "आपके आयफ़ोन और/या एंड्रॉइड ऐप में सहायता सदृश्यक को स्थापित तथा प्रदर्शित करने के लिए कोड की केवल 2 पंक्तियों की आवश्यकता है।"

	, "homePageComponent_featureBetterCommunicationTitle" : "श्रेष्ठतर संसूचना"
	, "homePageComponent_featureBetterCommunicationSummary01" : "स्क्रीनशॉट तथा लघु वीडियो साझा करने के लिए एकाधिक अनुलग्नक।"

	, "homePageComponent_appUpdatePopup_pageHeader01" : "सहाय्यक"
	, "homePageComponent_appUpdatePopup_pageHeader02" : "हल्की संबद्धयुक्त प्रणाली"

	, "homePageComponent_appUpdatePopup_pageSubheader" : "हम एक बैकएंड सिस्टम (एस-ए-ए-एस के रूप में) और एक फ्रंटएंड सिस्टम (आपके मोबाइल ऐप में एकीकृत करने के लिए लाइब्रेरी के रूप में) दोनों प्रदान करते हैं। सेटअप यूआई और कार्यक्षमता उत्पन्न करने का ख्याल रखेगा।"

	, "homePageComponent_featureAppUpdatePopupTitle" : "फ़ीचर: ऐप अपडेट पॉपअप"
	, "homePageComponent_featureAppUpdatePopupSummary01" : "कोड की कुछ पंक्तियों के साथ केवल एक बार एकीकरण द्वारा अपने उपयोगकर्ताओं को अपने ऐप अपडेट के बारे में सूचित करें।"

	, "homePageComponent_watchOnYoutubeButtonTitle" : "यूट्यूब पर वीडियो देखें"
	, "homePageComponent_getStartedButtonTitle" : "प्रारंभ करे"


	, "searchPlanComponent_header01" : "सरल"
	, "searchPlanComponent_header02" : "मूल्य निर्धारण"

	, "searchPlanComponent_subheader01" : "जब तक आप चाहें तब तक निःशुल्क योजना का उपयोग करें और अधिक लाभों के लिए कभी भी अपग्रेड करें।"
	, "searchPlanComponent_subheader02" : "हमारा मानना ​​है कि ग्राहक सहायता सॉफ्टवेयर हर फ्रीलांसर और हर कंपनी के लिए सुलभ होना चाहिए, चाहे आकार कोई भी हो।"
	, "searchPlanComponent_loginToPurchaseButtonTitle" : "क्रय हेतु साइन अप / लॉग इन करे"
	, "searchPlanComponent_gotoSubscriptionDetailsToPurchaseButtonTitle" : "क्रय हेतु सदस्यता विवरण दिखाएं"

	, "searchPlanComponent_basicPlanName" : "मूलभूत"
	, "searchPlanComponent_basicPlanNote" : "सदैव निशुल्क"
	, "searchPlanComponent_basicPlanAmount" : "$0"
	, "searchPlanComponent_basicPlanDetails01" : "असीमित उत्पाद"
	, "searchPlanComponent_basicPlanDetails02" : "असीमित उत्पाद सदस्य"
	, "searchPlanComponent_basicPlanDetails03" : "टिकट संलग्नक अनुमती नहीं"

	, "searchPlanComponent_premiumPlanName" : "प्ररितौपिक"
	, "searchPlanComponent_premiumPlanNote" : "वार्षिक शुल्क आकार"
	, "searchPlanComponent_premiumPlanAmountMonthly" : "%s%s / महीना"
	, "searchPlanComponent_premiumPlanAmountYearly" : "कुल %s%s / वर्ष"
	, "searchPlanComponent_premiumPlanDetails01" : "असीमित उत्पाद"
	, "searchPlanComponent_premiumPlanDetails02" : "असीमित उत्पाद सदस्य"
	, "searchPlanComponent_premiumPlanDetails03" : "1 जीबी डिस्क स्थान (संलग्नक आदि हेतु)"

	, "searchPlanComponent_freeForever_header01" : "विशेष अनुबोध-संहिता,"
	, "searchPlanComponent_freeForever_header02" : "निःशुल्क प्रयोज्यता"

	, "searchPlanComponent_freeForever_subheader01" : "जब तक चाहें तब तक निःशुल्क योजना का उपयोग करें, कोई गोपनीय मूल्य नहीं।"
	, "searchPlanComponent_freeForever_subheader02" : "हमारा मानना ​​है कि सरल सहायक सुविधाएँ हर फ्रीलांसर और हर कंपनी के लिए सुलभ होनी चाहिए, चाहे समुदाय संख्या कुछ भी हो।"

	, "searchPlanComponent_freeForever_basicPlanName" : "मूलभूत"
	, "searchPlanComponent_freeForever_basicPlanNote" : "सदैव निशुल्क"
	, "searchPlanComponent_freeForever_basicPlanAmount" : "$0"
	, "searchPlanComponent_freeForever_basicPlanTagline" : "इसे अंगीकारे और आराम करें"

	, "searchPlanComponent_freeForever_basicPlanDetails01" : "असीमित उत्पाद"
	, "searchPlanComponent_freeForever_basicPlanDetails02" : "असीमित उत्पाद सदस्य"
	, "searchPlanComponent_freeForever_basicPlanDetails03" : "कोई गोपनीय मूल्य नहीं"
	, "searchPlanComponent_freeForever_basicPlanDetails04" : "क्रेडिट कार्ड की आवश्यकता नहीं"


	, "menuBarComponent_homeTitle" : "मुखपृष्ठ"
	, "menuBarComponent_knowledgebaseTitle" : "ज्ञानकोष"
	, "menuBarComponent_downloadTitle" : "डाउनलोड"
	, "menuBarComponent_supportTitle" : "सहायता"
	, "menuBarComponent_pricingTitle" : "मूल्य"
	, "menuBarComponent_signupLoginTitle" : "पंजीकरण / लॉग इन"
	, "menuBarComponent_dashboardTitle" : "प्रावरणी"


	, "navigationBarComponent_headerTitle" : "सहाय्यक"

	, "navigationBarComponent_ticketSectionTitle" : "टिकट"
	, "navigationBarComponent_searchTicketTitle" : "टिकट अन्वेषण"

	, "navigationBarComponent_productSectionTitle" : "उत्पाद"
	, "navigationBarComponent_newProductTitle" : "नया उत्पाद"
	, "navigationBarComponent_searchProductTitle" : "मेरे उत्पाद"
	, "navigationBarComponent_searchProductInvitationTitle" : "उत्पाद निमंत्रण"

	, "navigationBarComponent_miscellaneousSectionTitle" : "विविध"
	, "navigationBarComponent_profileDetailsTitle" : "मेरी रूपरेखा"
	, "navigationBarComponent_searchSubscriptionTitle" : "सदस्यता योजनाएँ"
	, "navigationBarComponent_mySubscriptionTitle" : "मेरी सदस्यता"

	, "ceilingBarComponent_welcomeMessage" : "सुस्वागतम"
	, "ceilingBarComponent_guestUserDisplayName" : "अतिथि"


	, "customerProfileDetailsComponent_textInputPlaceholder" : "यहाँ टाइप करें"
	, "customerProfileDetailsComponent_deleteCtaTitle" : "विलुप्त करे"
	, "customerProfileDetailsComponent_updateCtaTitle" : "अद्यतन करे"

	, "customerProfileDetailsComponent_profileDetailsSectionTitle" : "रूपरेखा विवरण"
	, "customerProfileDetailsComponent_profileDetailsDisplayNameInputTitle" : "प्रदर्शित होने वाला नाम"

	, "customerProfileDetailsComponent_identitySectionTitle" : "अभिज्ञान"
	, "customerProfileDetailsComponent_identityEmailAddressColumnTitle" : "ई-मेल पता"
	, "customerProfileDetailsComponent_identityVerificationDateColumnTitle" : "सत्यापन दिनांक"
	, "customerProfileDetailsComponent_identityEmailAddressInputTitle" : "ई-मेल पता"
	, "customerProfileDetailsComponent_addIdentityCtaTitle" : "अभिज्ञान जोड़ें"
	, "customerProfileDetailsComponent_resendVerificationCodeCtaTitle" : "कोड पुन: भेजे"
	, "customerProfileDetailsComponent_provideEmailAddress" : "कृपया ईमेल पता प्रदान करें।"
	, "customerProfileDetailsComponent_provideVerificationCode" : "कृपया सत्यापन कोड प्रदान करें।"
	, "customerProfileDetailsComponent_deleteEmailConformationMessage" : "क्या आप चयनित ईमेल पता विलुप्त करनेके इच्छुक हैं?"

	, "customerProfileDetailsComponent_updatePasswordSectionTitle" : "गुप्तशब्द अद्यतन"
	, "customerProfileDetailsComponent_updatePasswordNewPasswordInputTitle" : "नूतन गुप्तशब्द"
	, "customerProfileDetailsComponent_updatePasswordNewPasswordRetypeInputTitle" : "नूतन गुप्तशब्द पुनरावृत्ति"
	, "customerProfileDetailsComponent_updatePasswordCurrentPasswordInputTitle" : "वर्तमान गुप्तशब्द"
	, "customerProfileDetailsComponent_provideNewPassword" : "कृपया नूतन गुप्तशब्द प्रदान करें।"
	, "customerProfileDetailsComponent_retypeNewPassword" : "कृपया नूतन गुप्तशब्दकी पुनरावृत्ती करें।"
	, "customerProfileDetailsComponent_newRetypePasswordDoNotMatch" : "नूतन गुप्तशब्द एवं नूतन गुप्तशब्दकी पुनरावृत्ती समान नहीं है।"
	, "customerProfileDetailsComponent_provideCurrentPassword" : "कृपया अपना वर्तमान गुप्तशब्द प्रदान करें।"

	, "pageNotFoundComponent_header01" : "पृष्ठ नहीं मिला"
	, "pageNotFoundComponent_header02" : "४०४"
	, "pageNotFoundComponent_message" : "आपके द्वारा अनुरोधित पृष्ठ नहीं मिला"


	, "searchTicketComponent_productFilterSelectFieldTitle" : "उत्पाद"
	, "searchTicketComponent_assigneeFilterSelectFieldTitle" : "अभिहस्तांकिती"
	, "searchTicketComponent_stateFilterSelectFieldTitle" : "स्थिति"
	, "searchTicketComponent_pageNumberSelectTitle" : "पृष्ठ संख्या"
	, "searchTicketComponent_searchButtonTitle" : "अन्वेषण"
	, "searchTicketComponent_unassigned" : "अनाभिहस्तांकित"
	, "searchTicketComponent_ticketStateOpenTitle" : "विवृत"
	, "searchTicketComponent_ticketStateClosedTitle" : "अवस्र्द्ध"
	, "searchTicketComponent_noDataMessage" : "कोई टिकट नहीं मिला"
	, "searchTicketComponent_showingFromTo" : "कुल {2} मे {0} से {1} पर्यन्त प्रस्तुत"


	, "newProductComponent_pageHeader" : "उत्पाद संसूचना"
	, "newProductComponent_pageInstruction" : "नया उत्पाद जोड़ने के लिए नीचे आवश्यक संसूचना निवेदित करें। आप अपने इच्छानुसार इसे बदल सकते हैं।"
	, "newProductComponent_productTitleTextFieldTitle" : "उत्पाद शीर्षक"
	, "newProductComponent_submitButtonTitle" : "प्रस्तुत"


	, "productCustomerRole_ownerTitle" : "स्वामी"
	, "productCustomerRole_agentTitle" : "प्रतिनिधि"


	, "searchProductComponent_titleRowTitle" : "शीर्षक"
	, "searchProductComponent_roleRowTitle" : "भूमिका"
	, "searchProductComponent_noDataMessage" : "कोई उत्पाद नहीं मिला"


	, "searchProductInvitationComponent_titleRowTitle" : "शीर्षक"
	, "searchProductInvitationComponent_roleRowTitle" : "भूमिका"
	, "searchProductInvitationComponent_noDataMessage" : "कोई उत्पाद निमंत्रण नहीं मिला"
	, "newProductComponent_acceptButtonTitle" : "स्वीकार"
	, "searchProductInvitationComponent_acceptInvitationConfirmationMessage" : "क्या आप चयनित आमंत्रण स्वीकार करना चाहते हैं?"


	, "subscriptionDetailsComponent_currentSubscriptionBasicPlanTitle" : "आपने वर्तमान में बेसिक प्लान की सदस्यता ले रखी है।"
	, "subscriptionDetailsComponent_currentSubscriptionBasicPlanTagLine" : "हमारा सदैव निःशुल्क बेसिक प्लान आपको अपने दर्शकों को सरलता से सहायता प्रदान करने के लिए आवश्यक उपकरण प्रदान करता है।"

	, "subscriptionDetailsComponent_currentSubscriptionPremiumPlanTitle" : "आपने वर्तमान में वार्षिक बिलिंग के साथ प्रीमियम योजना की सदस्यता ले रखी है।"
	, "subscriptionDetailsComponent_currentSubscriptionPremiumPlanTagLine" : "आपके बाज़ार से अधिक डेटा का लाभ उठाना शुरू करने के लिए आवश्यक हर उपकरण तक असीमित पहुंच।"
	, "subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit01" : "असीमित उत्पाद"
	, "subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit02" : "असीमित उत्पाद सदस्य"
	, "subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit03" : "डिस्क क्षमता"

	, "subscriptionDetailsComponent_needMore" : "क्या अधिक आवश्यकता है?"
	, "subscriptionDetailsComponent_upgradeToPremium" : "प्रीमियम में उन्नत करें"
	, "subscriptionDetailsComponent_premiumPlanTagLine" : "अपना व्यवसाय बढ़ाने के लिए आपको जो कुछ भी चाहिए।"
	, "subscriptionDetailsComponent_premiumPlanKnowMore" : "अधिक संसूचना"
	, "subscriptionDetailsComponent_premiumPlanBoxTitle" : "प्रीमियम योजना"
	, "subscriptionDetailsComponent_premiumPlanBoxDuration" : "एक वर्ष के लिए"
	, "subscriptionDetailsComponent_premiumPlanBoxTotalTitle" : "कुल"
	, "subscriptionDetailsComponent_contactUsForPaymentOptions" : "भुगतान विकल्प हेतु संपर्क करें"


	, "productDetailsComponent_productDetailsSectionTitle" : "उत्पाद विवरण"
	, "productDetailsComponent_productTitleInputTitle" : "उत्पाद शीर्षक"
	, "productDetailsComponent_productTitleInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_productDetailsSubmitInputTitle" : "अद्यतन"

	, "productDetailsComponent_memberListSectionTitle" : "सदस्य"
	, "productDetailsComponent_memberListNameColumnTitle" : "सदस्य नाम"
	, "productDetailsComponent_memberListRoleColumnTitle" : "भूमिका"
	, "productDetailsComponent_memberListRemoveButtonTitle" : "विलुप्त"

	, "productDetailsComponent_invitationListSectionTitle" : "निमंत्रण"
	, "productDetailsComponent_invitationListEmailColumnTitle" : "ई-मेल पता"
	, "productDetailsComponent_invitationListRoleColumnTitle" : "भूमिका"
	, "productDetailsComponent_invitationListEmailInputTitle" : "ई-मेल पता"
	, "productDetailsComponent_invitationListEmailInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_invitationListRoleInputDefaultValue" : "प्रतिनिधि"
	, "productDetailsComponent_invitationListInviteButtonTitle" : "निमंत्रण"
	, "productDetailsComponent_invitationListRemoveButtonTitle" : "विलुप्त"

	, "productDetailsComponent_apiKeySectionTitle" : "एपीआई कुंजी"
	, "productDetailsComponent_apiKeyGeneratedOn" : "उत्पन्न हुआ"
	, "productDetailsComponent_apiKeyRegenerateButtonTitle" : "पुनरुत्पन्न"
	, "productDetailsComponent_apiKeyRecoveryMessage" : "टिप्पणी: कुंजी निर्माण के बाद, संकेत मिलने पर कृपया एपीआई कुंजी फ़ाइल को सुरक्षित स्थान पर सहेजें। हम आपकी निजी एपीआई कुंजी संग्रहीत नहीं करते हैं इसलिए इसे पुनर्प्राप्त करने का कोई तरीका नहीं है। यदि आप एपीआई कुंजी खो देते हैं तो आपको एक नई कुंजी तैयार करनी होगी और अपने मोबाइल ऐप एकीकरण को अपडेट करना होगा।"

	, "productDetailsComponent_iosAppSectionTitle" : "आईओएस ऐप"
	, "productDetailsComponent_iosAppBundleIdentifierInputTitle" : "बंडल पहचानकर्ता"
	, "productDetailsComponent_iosAppBundleIdentifierInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_iosAppMinimumSupportedVersionInputTitle" : "न्यूनतम समर्थित संस्करण"
	, "productDetailsComponent_iosAppMinimumSupportedVersionInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_iosAppLatestVersionInputTitle" : "नवीनतम संस्करण"
	, "productDetailsComponent_iosAppLatestVersionInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_iosAppDownloadUrlInputTitle" : "ऐप डाउनलोड यूआरएल"
	, "productDetailsComponent_iosAppDownloadUrlInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_iosAppAppleTeamIdInputTitle" : "एप्पल टीम आईडी"
	, "productDetailsComponent_iosAppAppleTeamIdInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_iosAppApnsAuthKeyIdInputTitle" : "एपीएनएस प्रामाणिक कुंजी आईडी"
	, "productDetailsComponent_iosAppApnsAuthKeyIdInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_iosAppApnsAuthKeyFileInputTitle" : "एपीएनएस प्रामाणिक कुंजी (पी८) फ़ाइल"
	, "productDetailsComponent_iosAppApnsAuthKeyFileDeleteButtonTitle" : "APNS प्रामाणिक कुंजी (p८) फ़ाइल विलुप्त करें"
	, "productDetailsComponent_iosAppSubmitButtonTitle" : "अद्यतन"

	, "productDetailsComponent_androidAppSectionTitle" : "एंड्रॉइड ऐप"
	, "productDetailsComponent_androidAppBundleIdentifierInputTitle" : "बंडल पहचानकर्ता"
	, "productDetailsComponent_androidAppBundleIdentifierInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_androidAppMinimumSupportedVersionInputTitle" : "न्यूनतम समर्थित संस्करण"
	, "productDetailsComponent_androidAppMinimumSupportedVersionInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_androidAppLatestVersionInputTitle" : "नवीनतम संस्करण"
	, "productDetailsComponent_androidAppLatestVersionInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_androidAppDownloadUrlInputTitle" : "ऐप डाउनलोड यूआरएल"
	, "productDetailsComponent_androidAppDownloadUrlInputPlaceholder" : "यहाँ टाइप करें"
	, "productDetailsComponent_androidAppFcmAuthJsonFileInputTitle" : "एफसीएम निजी कुंजी फ़ाइल"
	, "productDetailsComponent_androidAppSubmitButtonTitle" : "अद्यतन"


	, "ticketDetailsComponent_messageAccessLevelInputTitle" : "संदेश अभिगम स्तर"
	, "ticketDetailsComponent_assigneeInputTitle" : "अभिहस्तांकिती"
	, "ticketDetailsComponent_assignButtonTitle" : "अभिहस्तांकित"
	, "ticketDetailsComponent_closeTicketButtonTitle" : "टिकट अवस्र्द्ध करें"
	, "ticketDetailsComponent_draftReplyButtonTitle" : "प्रत्युत्तर"
	, "ticketDetailsComponent_messageAttachmentFileInputTitle" : "अनुलग्नक"
	, "ticketDetailsComponent_submitReplyButtonTitle" : "प्रस्तुत"


	, "ticketMessageAccessLevel_privateTitle" : "व्यक्तिगत"
	, "ticketMessageAccessLevel_internalTitle" : "आंतरिक"
	, "ticketMessageAccessLevel_defaultTitle" : "अध्याहृत"


	, "ticketDetailsComponent_messageAccessLevelHelpTitle" : "संदेश अभिगम स्तर"
	, "ticketDetailsComponent_messageAccessLevelHelpPrivateTitle" : "व्यक्तिगत"
	, "ticketDetailsComponent_messageAccessLevelHelpPrivateDescription" : "केवल आपको दिखाई देगा। अपने स्वयं के संदर्भ के लिए निजी नोट्स लिखने के लिए इस अभिगम स्तर का उपयोग करें।"
	, "ticketDetailsComponent_messageAccessLevelHelpInternalTitle" : "आंतरिक"
	, "ticketDetailsComponent_messageAccessLevelHelpInternalDescription" : "आपके और आपके सहकर्मियों के लिए दृश्यमान, किंतु दर्शकों के लिए नहीं। आंतरिक (अपनी सहायता टीम के अंतर्गत) चर्चा और अद्यतन के लिए इस पहुंच स्तर का उपयोग करें।"
	, "ticketDetailsComponent_messageAccessLevelHelpDefaultTitle" : "अध्याहृत"
	, "ticketDetailsComponent_messageAccessLevelHelpDefaultDescription" : "यह आपके, आपके सहकर्मियों और टिकट जमा करने वाले दर्शकों के लिए दृश्यमान है। दर्शक को उत्तर देने के लिए इस अभिगम स्तर का उपयोग करें। आपको इसे  पूर्वनिर्धारित या अधिमानित अभिगम स्तर के रूप में उपयोग करना चाहिए।"

	, "supportDetailsComponent_header01" : "कोई प्रश्न है?"
	, "supportDetailsComponent_header02" : "या केवल नमस्ते कहें"
	, "supportDetailsComponent_subheader" : "हमें आपकी सहायता करने में प्रसन्नता होगी। कृपया नीचे दिए गए किसी भी पद्धति से हमसे संपर्क करें।"
	, "supportDetailsComponent_emailSupportHeader" : "ईमेल"
	, "supportDetailsComponent_emailSupportNote" : "यदि आपको कोई प्रश्न हैं तो कृपया हमें एक ईमेल भेजें और हम यथाशीघ्र उत्तर देंगे।"
	, "supportDetailsComponent_inAppSupportHeader" : "इन-ऐप सहायता"
	, "supportDetailsComponent_inAppSupportNote" : "हम स्वयं सहायक इन-ऐप सहायता प्रणाली का उपयोग करते हैं। सहायता टिकट प्रस्तुतीकरण हेतु कृपया सहायक ऐप डाउनलोड करें।"
}

export { kLocaleHindi };