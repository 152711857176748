
class Spectator {
	uuid = null;
	addedOnUsdfDate = null;

	displayName = null;
}


export { Spectator };
