
class ProductInvitation {
	uuid = null;
	addedOnUsdfDate = null;

	product = null;
	emailAddress = null;
	role = null;
}


export { ProductInvitation };
