const kLocaleThai = {
	"localizationManager_englishLocaleTitle" : "English" // This value will always be English otherwise english user can not understand it.
	, "localizationManager_hindiLocaleTitle" : "हिन्दी" // This value will always be हिन्दी otherwise hindi user can not understand it.
	, "localizationManager_thaiLocaleTitle" : "ไทย" // This value will always be ไทย otherwise thai user can not understand it.


	, "homePageComponent_headerAppName" : "สหยัค"
	, "homePageComponent_headerAppTagLine" : "ระบบสนับสนุนในแอปที่มีน้ำหนักเบา"

	, "homePageComponent_subheader" : "ทุกสิ่งที่คุณต้องการเพื่อสนับสนุนผู้ใช้แอปของคุณอย่างมีประสิทธิภาพมากขึ้น"

	, "homePageComponent_featureHeader" : "สิ่งที่เรานำเสนอ"
	, "homePageComponent_featureSummary" : "เรามีไลบรารี่ขนาดเล็กที่สามารถรวมเข้ากับแอป iOS หรือ Android ของคุณได้โดยใช้โค้ดเพียงบรรทัดเดียว ห้องสมุดจะดูแลการสร้าง UI ตั๋วสนับสนุนและฟังก์ชันการทำงาน"
}

export { kLocaleThai };