import React, { Component } from 'react';

import "./app-button.css"

class AppButton extends Component {
	title = null;
	options = null;

	constructor(props) {
		super(props);

		this.state = {
			shouldDisplayOptions: false
			, selectedOptionValues: []
		}
	}

	value() {
		var aReturnVal = null;
		if (this.options instanceof Array && this.options.length > 0
			&& this.state.selectedOptionValues instanceof Array && this.selectedOptionValues.length > 0) {
			this.options.find(pItem => this.state.selectedOptionValues.contains(pItem.value));
		}
		return aReturnVal;
	}

	componentDidMount() {
		this.reloadAllData();
	}

	reloadAllData() {
		this.reloadAllViews();
	}

	reloadAllViews() {
		this.forceUpdate();
	}

	render() {
		var aTitle = "Submit"
		if (this.props != null && this.props.title != null) {
			aTitle = this.props.title;
		} else if (this.title != null) {
			aTitle = this.title;
		}

		var anIsDisabled = false;
		if (this.props.isDisabled === true) {
			anIsDisabled = true;
		}

		var anInputClassArray = [];
		anInputClassArray.push("app-button")
		if (this.props.type === AppButtonType.submit) {
			anInputClassArray.push("app-button-submit");
		} else if (this.props.type === AppButtonType.arcRect) {
			anInputClassArray.push("app-button-arc-rect");
		}
		if (this.props.isDestructive === true) {
			anInputClassArray.push("app-button-destructive")
		}
		if (anIsDisabled === true) {
			anInputClassArray.push("app-button-disabled")
		}
		if (this.props.inputClassNames instanceof Array) {
			anInputClassArray = anInputClassArray.concat(this.props.inputClassNames);
		}
		return (
			<input type="submit" disabled={anIsDisabled} className={anInputClassArray.join(" ")} value={aTitle} onClick={this.props.didSelectCallback} ref={this.submitButton} key="submitButton" />
		);
	}

}


class AppButtonType {
	static submit = "submit";
	static arcRect = "arc_rect";
}


export { AppButton, AppButtonType }
