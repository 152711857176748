import React, { Component } from 'react';

import "./app-text-field.css"


class AppTextField extends Component {
	title = null;
	shouldSecureTextEntry = false;

	constructor(props) {
		super(props);

		this.textInputField = React.createRef();

		this.state = {
			shouldDisplayOptions: false
			, selectedOptionValues: []
		}
	}

	get value() {
		var aReturnVal = null;
		if (this.textInputField.current.value != null && this.textInputField.current.value.length > 0) {
			aReturnVal = this.textInputField.current.value;
		}
		return aReturnVal;
	}

	set value(pNewValue) {
		this.textInputField.current.value = pNewValue;
	}

	_shouldSecureTextEntry() {
		var aReturnVal = false;
		if (this.props.shouldSecureTextEntry === true) {
			aReturnVal = true;
		} else if (this.shouldSecureTextEntry === true) {
			aReturnVal = true;
		}
		return aReturnVal;
	}

	componentDidMount() {
		this.reloadAllData();
	}

	reloadAllData() {
		this.reloadAllViews();
	}

	reloadAllViews() {
		this.forceUpdate();
	}

	getTitle() {
		var aReturnVal = "";
		if (this.title != null) {
			aReturnVal = this.title;
		} else if (this.props != null && this.props.title != null) {
			aReturnVal = this.props.title;
		}
		return aReturnVal;
	}

	getPlaceholder() {
		var aReturnVal = "";
		if (this.placeholder != null) {
			aReturnVal = this.placeholder;
		} else if (this.props != null && this.props.placeholder != null) {
			aReturnVal = this.props.placeholder;
		}
		return aReturnVal;
	}

	didKeyDown(pEvent) {
		if (pEvent.key === "Enter") {
			if (this.props.didClickEnterCallback != null) {
				this.props.didClickEnterCallback();
			}
		}
	}

	render() {
		var anInputType = "text";
		if (this._shouldSecureTextEntry() === true) {
			anInputType = "password";
		}

		var aContainerClassArray = [];
		aContainerClassArray.push("app-input-field");
		aContainerClassArray.push("app-input-textfield");

		var aTitleClassArray = [];
		aTitleClassArray.push("app-input-textfield-title");
		if (this.getTitle() == null || this.getTitle() === "") {
			aTitleClassArray = aTitleClassArray.concat(["app-input-textfield-title-hidden"]);
		}

		var anInputClassArray = [];
		anInputClassArray.push("app-input-textfield-input");
		if (this.props.inputClassNames instanceof Array) {
			anInputClassArray = anInputClassArray.concat(this.props.inputClassNames);
		}

		return (
			<div className={aContainerClassArray.join(" ")}>
				<div className={aTitleClassArray.join(" ")}>{this.getTitle()}</div>
				<input type={anInputType} className={anInputClassArray.join(" ")} ref={this.textInputField} key="textInputField" placeholder={this.getPlaceholder()} onKeyDown={this.didKeyDown.bind(this)} />
			</div>
		);
	}

}


export { AppTextField }