const kLocaleEnglish = {
	"localizationManager_englishLocaleTitle" : "English" // This value will always be English otherwise english user can not understand it.
	, "localizationManager_hindiLocaleTitle" : "हिन्दी" // This value will always be हिन्दी otherwise hindi user can not understand it.
	, "localizationManager_thaiLocaleTitle" : "ไทย" // This value will always be ไทย otherwise thai user can not understand it.


	, "common_unknownApiResponseSignet" : "Unknown response code."
	, "common_unknownErrorMessage" : "Unknown error."
	, "common_apiResponseSuccessMessage" : "Request processed successfully."
	, "common_dateFormatDateAndTime" : "dd-mmm-yyyy 'at' hh:MM tt"
	, "common_loginRequiredMessage" : "Please Signup / Login to view details"


	, "signupLoginCustomerComponent_signupFormTitle" : "Don't have an account?"
	, "signupLoginCustomerComponent_signupEmailPlaceholder" : "Email Address"
	, "signupLoginCustomerComponent_signupDisplayNamePlaceholder" : "Display Name"
	, "signupLoginCustomerComponent_signupPasswordPlaceholder" : "Password"
	, "signupLoginCustomerComponent_signupConfirmPasswordPlaceholder" : "Confirm Password"
	, "signupLoginCustomerComponent_signupButtonTitle" : "Sign Up"
	, "signupLoginCustomerComponent_signupButtonInstructionAcceptTerms" : "By Sign Up, you understand and agree to Terms of Use and Privacy Policy"
	, "signupLoginCustomerComponent_signupButtonInstructionNextStep" : "After signup, you will receive an email with confirmation link."

	, "signupLoginCustomerComponent_separatorOr" : "OR"

	, "signupLoginCustomerComponent_loginFormTitle" : "Go to your account"
	, "signupLoginCustomerComponent_loginEmailPlaceholder" : "Email Address"
	, "signupLoginCustomerComponent_loginPasswordPlaceholder" : "Password"
	, "signupLoginCustomerComponent_loginButtonTitle" : "Login"
	, "signupLoginCustomerComponent_loginButtonInstructionAcceptTerms" : "By Login, you understand and agree to Terms of Use and Privacy Policy"

	, "signupLoginCustomerComponent_forgotPasswordFormTitle" : "Forgot your password?"
	, "signupLoginCustomerComponent_forgotPasswordButtonTitle" : "Go To Reset Password"


	, "homePageComponent_headerAppName" : "Sahayak"
	, "homePageComponent_headerAppTagLine" : "lightweight in-app support system"

	, "homePageComponent_subheader" : "Everything you need to support your app users more efficiently."

	, "homePageComponent_featureHeader" : "What We Offer"
	, "homePageComponent_featureSummary" : "We provide a lightweight library that can be integrated with your iOS or Android app with just single line of code. The library will take care of generating support ticket UI and functionality."

	, "homePageComponent_featureWorksFromWithinAppTitle" : "Works From Within Your Mobile App"
	, "homePageComponent_featureWorksFromWithinAppSummary01" : "Being an in-app support system, Sahayak inteface will be displayed within your mobile app."
	, "homePageComponent_featureWorksFromWithinAppSummary02" : "Your users need not install separate app or go outside your app to communicate with your support team."

	, "homePageComponent_featureNoRegistrationRequiredTitle" : "No User Registration Required"
	, "homePageComponent_featureNoRegistrationRequiredSummary01" : "With the new in-app authentication technology, your app users do not need to go through the complex registration and login process on the support system to submit a query."

	, "homePageComponent_featureBetterTeamCollborationTitle" : "Better Team Collboration"
	, "homePageComponent_featureBetterTeamCollborationSummary01" : "You can invite as many people as you want to collborate in your support team."
	, "homePageComponent_featureBetterTeamCollborationSummary02" : "Assign tickets to individual team member to make sure that each one of your app user is attended with care."

	, "homePageComponent_featureStayWellOrganizedTitle" : "Stay Well Organized"
	, "homePageComponent_featureStayWellOrganizedSummary01" : "New \"message access level\" feature helps you to work in an organized manner."
	, "homePageComponent_featureStayWellOrganizedSummary02" : "Use \"private messages\" to keep notes about the support ticket."
	, "homePageComponent_featureStayWellOrganizedSummary03" : "Use \"internal messages\" to share notes about the support ticket between your fellow team members."
	, "homePageComponent_featureStayWellOrganizedSummary04" : "No need to maintain and refer to separate document."

	, "homePageComponent_featureEasyIntegrationTitle" : "Easy Integration, No Technical Hassle"
	, "homePageComponent_featureEasyIntegrationSummary01" : "Only 2 lines of code needed to setup and display the support screen within your iPhone and/or Android app."

	, "homePageComponent_featureBetterCommunicationTitle" : "Better Communication"
	, "homePageComponent_featureBetterCommunicationSummary01" : "Multiple attachments to share screenshot and short videos."

	, "homePageComponent_appUpdatePopup_pageHeader01" : "Sahayak"
	, "homePageComponent_appUpdatePopup_pageHeader02" : "lightweight plug-and-play system"

	, "homePageComponent_appUpdatePopup_pageSubheader" : "We provide both a backend system (as SAAS) and a frontend system (as libraries to integrate in your mobile app). The setup will take care of generating UI and functionality."

	, "homePageComponent_featureAppUpdatePopupTitle" : "Feature: App Update Popup"
	, "homePageComponent_featureAppUpdatePopupSummary01" : "Notify your users about your app updates by only one-time integration with few lines of code."

	, "homePageComponent_watchOnYoutubeButtonTitle" : "Watch Video On Youtube"
	, "homePageComponent_getStartedButtonTitle" : "Get Started"


	, "searchPlanComponent_header01" : "Simple"
	, "searchPlanComponent_header02" : "pricing for everyone"

	, "searchPlanComponent_subheader01" : "Use the free plan for as long as you want and upgrade anytime for more benefits."
	, "searchPlanComponent_subheader02" : "We believe that the customer support software should be accessible to every freelancer and every company, no matter the size."
	, "searchPlanComponent_loginToPurchaseButtonTitle" : "Signup / Login To Purchase"
	, "searchPlanComponent_gotoSubscriptionDetailsToPurchaseButtonTitle" : "Go To Subscription Details To Purchase"

	, "searchPlanComponent_basicPlanName" : "Basic"
	, "searchPlanComponent_basicPlanNote" : "Free forever"
	, "searchPlanComponent_basicPlanAmount" : "$0"
	, "searchPlanComponent_basicPlanDetails01" : "Unlimited products"
	, "searchPlanComponent_basicPlanDetails02" : "Unlimited product members"
	, "searchPlanComponent_basicPlanDetails03" : "No ticket attachments"

	, "searchPlanComponent_premiumPlanName" : "Premium"
	, "searchPlanComponent_premiumPlanNote" : "Billed annually"
	, "searchPlanComponent_premiumPlanAmountMonthly" : "%s%s / month"
	, "searchPlanComponent_premiumPlanAmountYearly" : "Total %s%s / year"
	, "searchPlanComponent_premiumPlanDetails01" : "Unlimited products"
	, "searchPlanComponent_premiumPlanDetails02" : "Unlimited product members"
	, "searchPlanComponent_premiumPlanDetails03" : "1 GB disk space (attachments etc.)"

	, "searchPlanComponent_freeForever_header01" : "Special source,"
	, "searchPlanComponent_freeForever_header02" : "free application"

	, "searchPlanComponent_freeForever_subheader01" : "Use the free plan for as long as you want, no hidden costs."
	, "searchPlanComponent_freeForever_subheader02" : "We believe that the simple accessory features should be accessible to every freelancer and every company, no matter the size."

	, "searchPlanComponent_freeForever_basicPlanName" : "Basic"
	, "searchPlanComponent_freeForever_basicPlanNote" : "Free forever"
	, "searchPlanComponent_freeForever_basicPlanAmount" : "$0"
	, "searchPlanComponent_freeForever_basicPlanTagline" : "Opt for this and relax"

	, "searchPlanComponent_freeForever_basicPlanDetails01" : "Unlimited products"
	, "searchPlanComponent_freeForever_basicPlanDetails02" : "Unlimited product members"
	, "searchPlanComponent_freeForever_basicPlanDetails03" : "No hidden cost"
	, "searchPlanComponent_freeForever_basicPlanDetails04" : "No credit card required"


	, "menuBarComponent_homeTitle" : "Home"
	, "menuBarComponent_knowledgebaseTitle" : "Knowledgebase"
	, "menuBarComponent_downloadTitle" : "Download"
	, "menuBarComponent_supportTitle" : "Support"
	, "menuBarComponent_pricingTitle" : "Pricing"
	, "menuBarComponent_signupLoginTitle" : "Sign up / Login"
	, "menuBarComponent_dashboardTitle" : "Dashboard"


	, "navigationBarComponent_headerTitle" : "Sahayak"

	, "navigationBarComponent_ticketSectionTitle" : "Tickets"
	, "navigationBarComponent_searchTicketTitle" : "Search Tickets"

	, "navigationBarComponent_productSectionTitle" : "Products"
	, "navigationBarComponent_newProductTitle" : "New Product"
	, "navigationBarComponent_searchProductTitle" : "My Products"
	, "navigationBarComponent_searchProductInvitationTitle" : "Product Invitations"

	, "navigationBarComponent_miscellaneousSectionTitle" : "Miscellaneous"
	, "navigationBarComponent_profileDetailsTitle" : "My Profile"
	, "navigationBarComponent_searchSubscriptionTitle" : "Subscription Plans"
	, "navigationBarComponent_mySubscriptionTitle" : "My Subscription"

	, "ceilingBarComponent_welcomeMessage" : "Welcome back"
	, "ceilingBarComponent_guestUserDisplayName" : "Guest"


	, "customerProfileDetailsComponent_textInputPlaceholder" : "Type here"
	, "customerProfileDetailsComponent_deleteCtaTitle" : "Delete"
	, "customerProfileDetailsComponent_updateCtaTitle" : "Update"

	, "customerProfileDetailsComponent_profileDetailsSectionTitle" : "Profile Details"
	, "customerProfileDetailsComponent_profileDetailsDisplayNameInputTitle" : "Display Name"

	, "customerProfileDetailsComponent_identitySectionTitle" : "Identities"
	, "customerProfileDetailsComponent_identityEmailAddressColumnTitle" : "Email Address"
	, "customerProfileDetailsComponent_identityVerificationDateColumnTitle" : "Verified On"
	, "customerProfileDetailsComponent_identityEmailAddressInputTitle" : "Email Address"
	, "customerProfileDetailsComponent_addIdentityCtaTitle" : "Add Identity"
	, "customerProfileDetailsComponent_resendVerificationCodeCtaTitle" : "Resend Code"
	, "customerProfileDetailsComponent_provideEmailAddress" : "Please provide email address."
	, "customerProfileDetailsComponent_provideVerificationCode" : "Please provide verification code."
	, "customerProfileDetailsComponent_deleteEmailConformationMessage" : "Do you want to delete selected email address?"

	, "customerProfileDetailsComponent_updatePasswordSectionTitle" : "Change Password"
	, "customerProfileDetailsComponent_updatePasswordNewPasswordInputTitle" : "New Password"
	, "customerProfileDetailsComponent_updatePasswordNewPasswordRetypeInputTitle" : "Retype New Password"
	, "customerProfileDetailsComponent_updatePasswordCurrentPasswordInputTitle" : "Current Password"
	, "customerProfileDetailsComponent_provideNewPassword" : "Please provide new password."
	, "customerProfileDetailsComponent_retypeNewPassword" : "Please retype new password."
	, "customerProfileDetailsComponent_newRetypePasswordDoNotMatch" : "New password and retyped password does not match."
	, "customerProfileDetailsComponent_provideCurrentPassword" : "Please provide your current password."

	, "pageNotFoundComponent_header01" : "Page Not Found"
	, "pageNotFoundComponent_header02" : "404"
	, "pageNotFoundComponent_message" : "Page you requested was not found"


	, "searchTicketComponent_productFilterSelectFieldTitle" : "Products"
	, "searchTicketComponent_assigneeFilterSelectFieldTitle" : "Assignee"
	, "searchTicketComponent_stateFilterSelectFieldTitle" : "State"
	, "searchTicketComponent_pageNumberSelectTitle" : "Page Number"
	, "searchTicketComponent_searchButtonTitle" : "Search"
	, "searchTicketComponent_unassigned" : "Unassigned"
	, "searchTicketComponent_ticketStateOpenTitle" : "Open"
	, "searchTicketComponent_ticketStateClosedTitle" : "Closed"
	, "searchTicketComponent_noDataMessage" : "No Tickets Found"
	, "searchTicketComponent_showingFromTo" : "Showing {0} to {1} of {2}"


	, "newProductComponent_pageHeader" : "Product Information"
	, "newProductComponent_pageInstruction" : "Enter the required information below to add a new product. You can change it anytime you want."
	, "newProductComponent_productTitleTextFieldTitle" : "Product Title"
	, "newProductComponent_submitButtonTitle" : "Submit"


	, "productCustomerRole_ownerTitle" : "Owner"
	, "productCustomerRole_agentTitle" : "Agent"


	, "searchProductComponent_titleRowTitle" : "Title"
	, "searchProductComponent_roleRowTitle" : "Role"
	, "searchProductComponent_noDataMessage" : "No Products Found"


	, "searchProductInvitationComponent_titleRowTitle" : "Title"
	, "searchProductInvitationComponent_roleRowTitle" : "Role"
	, "searchProductInvitationComponent_noDataMessage" : "No Invitations Found"
	, "newProductComponent_acceptButtonTitle" : "Accept"
	, "searchProductInvitationComponent_acceptInvitationConfirmationMessage" : "Do you want to accept the selected invitation?"


	, "subscriptionDetailsComponent_currentSubscriptionBasicPlanTitle" : "You are currently subscribed to Basic plan."
	, "subscriptionDetailsComponent_currentSubscriptionBasicPlanTagLine" : "Our free forever Basic plan gives you essential tools needed to provide easy support to your spectators."

	, "subscriptionDetailsComponent_currentSubscriptionPremiumPlanTitle" : "You are currently subscribed to Premium plan with yearly billing."
	, "subscriptionDetailsComponent_currentSubscriptionPremiumPlanTagLine" : "Unlimited access to every tool needed to begin leveraging more data from your market."
	, "subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit01" : "Unlimited products"
	, "subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit02" : "Unlimited product members"
	, "subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit03" : "disk space"

	, "subscriptionDetailsComponent_needMore" : "Need more?"
	, "subscriptionDetailsComponent_upgradeToPremium" : "Upgrade to Premium"
	, "subscriptionDetailsComponent_premiumPlanTagLine" : "Everything you need to grow your business."
	, "subscriptionDetailsComponent_premiumPlanKnowMore" : "Know more"
	, "subscriptionDetailsComponent_premiumPlanBoxTitle" : "Premium Plan"
	, "subscriptionDetailsComponent_premiumPlanBoxDuration" : "For One Year"
	, "subscriptionDetailsComponent_premiumPlanBoxTotalTitle" : "Total"
	, "subscriptionDetailsComponent_contactUsForPaymentOptions" : "Contact Us For Payment Options"


	, "productDetailsComponent_productDetailsSectionTitle" : "Product Details"
	, "productDetailsComponent_productTitleInputTitle" : "Product Title"
	, "productDetailsComponent_productTitleInputPlaceholder" : "Type here"
	, "productDetailsComponent_productDetailsSubmitInputTitle" : "Update"

	, "productDetailsComponent_memberListSectionTitle" : "Members"
	, "productDetailsComponent_memberListNameColumnTitle" : "Member Name"
	, "productDetailsComponent_memberListRoleColumnTitle" : "Role"
	, "productDetailsComponent_memberListRemoveButtonTitle" : "Remove"

	, "productDetailsComponent_invitationListSectionTitle" : "Invitations"
	, "productDetailsComponent_invitationListEmailColumnTitle" : "Email Address"
	, "productDetailsComponent_invitationListRoleColumnTitle" : "Role"
	, "productDetailsComponent_invitationListEmailInputTitle" : "Email Address"
	, "productDetailsComponent_invitationListEmailInputPlaceholder" : "Type here"
	, "productDetailsComponent_invitationListRoleInputDefaultValue" : "Agent"
	, "productDetailsComponent_invitationListInviteButtonTitle" : "Invite"
	, "productDetailsComponent_invitationListRemoveButtonTitle" : "Remove"

	, "productDetailsComponent_apiKeySectionTitle" : "API Key"
	, "productDetailsComponent_apiKeyGeneratedOn" : "Generated on"
	, "productDetailsComponent_apiKeyRegenerateButtonTitle" : "Regenerate"
	, "productDetailsComponent_apiKeyRecoveryMessage" : "Note: After the key generation, please save the API key file at a secure location when prompted. We do not store your private API key hence there is no way to recover it. If you lose the API key then you will need to generate a new one and update your mobile app integration."

	, "productDetailsComponent_iosAppSectionTitle" : "iOS App"
	, "productDetailsComponent_iosAppBundleIdentifierInputTitle" : "Bundle Identifier"
	, "productDetailsComponent_iosAppBundleIdentifierInputPlaceholder" : "Type here"
	, "productDetailsComponent_iosAppMinimumSupportedVersionInputTitle" : "Minimum Supported App Version"
	, "productDetailsComponent_iosAppMinimumSupportedVersionInputPlaceholder" : "Type here"
	, "productDetailsComponent_iosAppLatestVersionInputTitle" : "Latest App Version"
	, "productDetailsComponent_iosAppLatestVersionInputPlaceholder" : "Type here"
	, "productDetailsComponent_iosAppDownloadUrlInputTitle" : "App Download URL"
	, "productDetailsComponent_iosAppDownloadUrlInputPlaceholder" : "Type here"
	, "productDetailsComponent_iosAppAppleTeamIdInputTitle" : "Apple Team ID"
	, "productDetailsComponent_iosAppAppleTeamIdInputPlaceholder" : "Type here"
	, "productDetailsComponent_iosAppApnsAuthKeyIdInputTitle" : "APNS Auth Key ID"
	, "productDetailsComponent_iosAppApnsAuthKeyIdInputPlaceholder" : "Type here"
	, "productDetailsComponent_iosAppApnsAuthKeyFileInputTitle" : "APNS Auth Key (p8) File"
	, "productDetailsComponent_iosAppApnsAuthKeyFileDeleteButtonTitle" : "Delete APNS Auth Key (p8) File"
	, "productDetailsComponent_iosAppSubmitButtonTitle" : "Update"

	, "productDetailsComponent_androidAppSectionTitle" : "Android App"
	, "productDetailsComponent_androidAppBundleIdentifierInputTitle" : "Bundle Identifier"
	, "productDetailsComponent_androidAppBundleIdentifierInputPlaceholder" : "Type here"
	, "productDetailsComponent_androidAppMinimumSupportedVersionInputTitle" : "Minimum Supported App Version"
	, "productDetailsComponent_androidAppMinimumSupportedVersionInputPlaceholder" : "Type here"
	, "productDetailsComponent_androidAppLatestVersionInputTitle" : "Latest App Version"
	, "productDetailsComponent_androidAppLatestVersionInputPlaceholder" : "Type here"
	, "productDetailsComponent_androidAppDownloadUrlInputTitle" : "App Download URL"
	, "productDetailsComponent_androidAppDownloadUrlInputPlaceholder" : "Type here"
	, "productDetailsComponent_androidAppFcmAuthJsonFileInputTitle" : "FCM Private Key File"
	, "productDetailsComponent_androidAppSubmitButtonTitle" : "Update"


	, "ticketDetailsComponent_messageAccessLevelInputTitle" : "Message Access Level"
	, "ticketDetailsComponent_assigneeInputTitle" : "Assignee"
	, "ticketDetailsComponent_assignButtonTitle" : "Assign"
	, "ticketDetailsComponent_closeTicketButtonTitle" : "Close Ticket"
	, "ticketDetailsComponent_draftReplyButtonTitle" : "Reply"
	, "ticketDetailsComponent_messageAttachmentFileInputTitle" : "Attachment"
	, "ticketDetailsComponent_submitReplyButtonTitle" : "Submit"


	, "ticketMessageAccessLevel_privateTitle" : "Private"
	, "ticketMessageAccessLevel_internalTitle" : "Internal"
	, "ticketMessageAccessLevel_defaultTitle" : "Default"

	, "ticketDetailsComponent_messageAccessLevelHelpTitle" : "Message Access Level"
	, "ticketDetailsComponent_messageAccessLevelHelpPrivateTitle" : "Private"
	, "ticketDetailsComponent_messageAccessLevelHelpPrivateDescription" : "Only visible to you. Use this access level to write down private notes for your own reference."
	, "ticketDetailsComponent_messageAccessLevelHelpInternalTitle" : "Internal"
	, "ticketDetailsComponent_messageAccessLevelHelpInternalDescription" : "Visible to you and your colleagues, but NOT to the spectator. Use this access level for internal (within your support team) discussion and updates."
	, "ticketDetailsComponent_messageAccessLevelHelpDefaultTitle" : "Default"
	, "ticketDetailsComponent_messageAccessLevelHelpDefaultDescription" : "Visible to you, your colleagues and the spectator who submitted the ticket. Use this access level to reply to the spectator. You should use this as default or preferred access level."

	, "supportDetailsComponent_header01" : "Have a query?"
	, "supportDetailsComponent_header02" : "or just say hello"
	, "supportDetailsComponent_subheader" : "We would be more than happy to assist you. Please contact us via any of the methods below."
	, "supportDetailsComponent_emailSupportHeader" : "Email"
	, "supportDetailsComponent_emailSupportNote" : "If you have any questions, please drop us an email and we will reply as soon as possible."
	, "supportDetailsComponent_inAppSupportHeader" : "In-App Support"
	, "supportDetailsComponent_inAppSupportNote" : "We use Sahayak in-app support system ourselves. Please download Sahayak app to raise a support ticket."
}

export { kLocaleEnglish };