
class Ticket {
	uuid = null;
	addedOnUsdfDate = null;

	title = null;
	author = null;

	assignee = null;
	state = null;

	messages = null;
	latestMessage = null;
}


export { Ticket };
