
class SubscriptionPlan {
	static premiumR1 = new SubscriptionPlan("premium_r_one");

	_signet = null;

	constructor(pSignet) {
		this._signet = pSignet;
	}

	signet() {
		return this._signet;
	}

	equals(pRhs) {
		return this.signet() === pRhs.signet();
	}

	title() {
		var aReturnVal = null;
		if (this.equals(SubscriptionPlan.premiumR1)) {
			aReturnVal = "Premium"
		}
		return aReturnVal;
	}

}


export { SubscriptionPlan };
