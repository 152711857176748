import { kLocaleEnglish } from './localization-bundle/en/localizable';
import { kLocaleHindi } from './localization-bundle/hi/localizable';
import { kLocaleThai } from './localization-bundle/th/localizable';


class LocalizationManager {

	static get currentLocale() {
		var aReturnVal = LocalizationLocale.en
		if (localStorage.getItem("appCurrentLocale") != null) {
			aReturnVal = new LocalizationLocale(localStorage.getItem("appCurrentLocale"));
		} else if (navigator.language === "en-US") {
			aReturnVal = LocalizationLocale.en;
		} else if (navigator.language === "in-HI") {
			aReturnVal = LocalizationLocale.hi;
		} else if (navigator.language === "th-TH") {
			aReturnVal = LocalizationLocale.th;
		}
		return aReturnVal;
	}

	static set currentLocaleSignet(pNewValue) {
		localStorage.setItem("appCurrentLocale", pNewValue)
	}

	static _localizedString(pLocale, pKey, pArgumentArray) {
		var aReturnVal = null;

		var aLocalizationDict = kLocaleEnglish;
		if (pLocale.equals(LocalizationLocale.en)) {
			aLocalizationDict = kLocaleEnglish;
		} else if (pLocale.equals(LocalizationLocale.hi)) {
			aLocalizationDict = kLocaleHindi;
		} else if (pLocale.equals(LocalizationLocale.th)) {
			aLocalizationDict = kLocaleThai;
		}
		var aLocalizedString = aLocalizationDict[pKey];
		if (aLocalizedString != null && Array.isArray(pArgumentArray) && pArgumentArray.length > 0) {
			if (aLocalizedString.includes("%s")) {
				for (var anIndex = 0; anIndex < pArgumentArray.length; anIndex ++) {
					aLocalizedString = aLocalizedString.replace("%s", pArgumentArray[anIndex]);
				}
			} else {
				aLocalizedString = aLocalizedString.replace(/{(\d+)}/g, function(pMatch, pIndex) { 
					return typeof pArgumentArray[pIndex] != 'undefined' ? pArgumentArray[pIndex] : pMatch;
				});
			}
			aReturnVal = aLocalizedString;
		} else {
			aReturnVal = aLocalizedString;
		}

		return aReturnVal;
	}

	static localizedString(pKey, ...pArguments) {
		var aReturnVal = null;

		aReturnVal = this._localizedString(LocalizationManager.currentLocale, pKey, pArguments)
		if (aReturnVal == null) {
			aReturnVal = this._localizedString(LocalizationLocale.en, pKey, pArguments)
		}
		if (aReturnVal == null) {
			aReturnVal = pKey
		}

		return aReturnVal;
	}

}

class LocalizationLocale {
	static en = new LocalizationLocale("en");
	static hi = new LocalizationLocale("hi");
	static th = new LocalizationLocale("th");

	_signet = null;

	constructor(pSignet) {
		this._signet = pSignet;
	}

	signet() {
		return this._signet;
	}

	equals(pRhs) {
		return this.signet() === pRhs.signet();
	}

	localizedTitle() {
		var aReturnVal = null;
		if (this.equals(LocalizationLocale.en)) {
			aReturnVal = LocalizationManager.localizationManager_englishLocaleTitle()
		} else if (this.equals(LocalizationLocale.hi)) {
			aReturnVal = LocalizationManager.localizationManager_hindiLocaleTitle()
		} else if (this.equals(LocalizationLocale.th)) {
			aReturnVal = LocalizationManager.localizationManager_thaiLocaleTitle()
		}
		return aReturnVal;
	}

}

export { LocalizationManager, LocalizationLocale }
