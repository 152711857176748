import React, { Component } from 'react';

import "./app-select-field.css"


class AppSelectField extends Component {
	title = null;
	options = null;

	constructor(props) {
		super(props);

		this.baseContainer = React.createRef();

		this.state = {
			shouldDisplayOptions: false
			, selectedOptions: []
		}
	}

	value() {
		var aReturnVal = null;
		if (this.options instanceof Array && this.options.length > 0
		&& this.state.selectedOptions instanceof Array && this.state.selectedOptions.length > 0) {
			aReturnVal = this.options.filter(pOptionItem => this.state.selectedOptions.find(pSelectedOptionItem => pSelectedOptionItem.value === pOptionItem.value) != null);
		}
		return aReturnVal;
	}

	selectOptionValue(pOptionValue) {
		if (this.options instanceof Array && this.options.length > 0) {
			var aSelectedOption = this.options.find(pOptionItem => pOptionValue === pOptionItem.value);
			if (aSelectedOption instanceof AppSelectFieldOption) {
				var aSelectedOptions = []
				aSelectedOptions.push(aSelectedOption);
				this._setSelectedOptions(aSelectedOptions, false);
			}
		}
	}

	_setSelectedOptions(pSelectedOptionArray, pIsManual) {
		this.setState({
			selectedOptions: pSelectedOptionArray
		})
		if (pIsManual && this.props.didChangeValueCallback != null) {
			var aThis = this;
			setTimeout(function() {
				aThis.props.didChangeValueCallback()
			}, 100)
		}
	}

	_setShouldDisplayOptions(pShouldDisplayOptions) {
		this.setState({
			shouldDisplayOptions: pShouldDisplayOptions
		})
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.didMouseDownForDocument, false);
		this.reloadAllData();
	}

	reloadAllData() {
		this.reloadAllViews();
	}

	reloadAllViews() {
		this.forceUpdate();
	}

	render() {
		var anOptionRowArray = null;
		if (this.options instanceof Array && this.options.length > 0) {
			anOptionRowArray = [];
			for (var anIndex = 0; anIndex < this.options.length; anIndex ++) {
				var anOption = this.options[anIndex];
				var aCheckbox = null;
				if (this.props.isMultiSelect) {
					var anIsOptionChecked = this.state.selectedOptions.find(pItem => anOption.value === pItem.value) != null;
					aCheckbox = <input type="checkbox" readOnly checked={anIsOptionChecked}/>;
				}
				anOptionRowArray.push(
					<div className="app-select-field-option" onClick={this.didSelectOption.bind(this, anOption)} key={"option_" + anIndex}>
						{aCheckbox}
						<div>{anOption.title}</div>
					</div>
				);
			}
		}

		var aSelectFieldClassArray = [];
		aSelectFieldClassArray.push("app-input-field");
		aSelectFieldClassArray.push("app-select-field");
		if (this.state.shouldDisplayOptions) {
			aSelectFieldClassArray.push("app-select-field-selected");
		}
		if (this.props.inputClassNames instanceof Array) {
			aSelectFieldClassArray = aSelectFieldClassArray.concat(this.props.inputClassNames);
		}

		var aTitleComponentArray = [];
		if (this.props != null && this.props.title != null) {
			aTitleComponentArray.push(
				<span key={"title"}>{this.props.title}</span>
			);
		} else if (this.title != null) {
			aTitleComponentArray.push(
				<span key={"title"}>{this.title}</span>
			);
		} else if (this.props != null && this.props.titleView != null) {
			aTitleComponentArray.push(
				<span key={"title"}>{this.props.titleView}</span>
			);
		}
		if (this.state.selectedOptions instanceof Array && this.state.selectedOptions.length > 0) {
			if (this.state.selectedOptions.length === 1) {
				aTitleComponentArray.push(
					<span key={"option_title"}>{"(" + this.state.selectedOptions[0].title + ")"}</span>
				);
			} else {
				aTitleComponentArray.push(
					<span key={"option_count"}>{"(" + this.state.selectedOptions.length + " Selected)"}</span>
				);
			}
		}

		return (
			<div className={aSelectFieldClassArray.join(" ")} ref={this.baseContainer}>
				<div className="app-select-field-title" onClick={this.didSelectInputField.bind(this)}>
					{aTitleComponentArray}
				</div>
				<div className="app-select-field-dropdown">
					{anOptionRowArray}
				</div>
			</div>
		);
	}

	didSelectInputField(pSender) {
		this._setShouldDisplayOptions(true)
	}

	didSelectOption(pOption) {
		var aSelectedOptions = []
		if (this.props.isMultiSelect === true) {
			aSelectedOptions = aSelectedOptions.concat(this.state.selectedOptions);
			var anIndex = -1;
			var aSelectedOption = aSelectedOptions.find(pItem => pItem.value === pOption.value);
			if (aSelectedOption != null) {
				anIndex = aSelectedOptions.indexOf(aSelectedOption);
			}
			if (anIndex < 0) {
				aSelectedOptions.push(pOption);
			} else {
				aSelectedOptions.splice(anIndex, 1);
			}
		} else {
			aSelectedOptions.push(pOption);
		}
		this._setSelectedOptions(aSelectedOptions, true);
		if (this.props.isMultiSelect !== true) {
			this.didSelectDone();
		}
	}

	didSelectDone() {
		this._setShouldDisplayOptions(false)
	}

	didMouseDownForDocument = pEvent => {
		if (this.baseContainer.current.contains(pEvent.target) === false) {
			this.didSelectDone();
		}
	};

}


class AppSelectFieldOption {
	title = null;
	value = null;

	constructor(pTitle, pValue) {
		this.title = pTitle;
		this.value = pValue;
	}
}


export { AppSelectField, AppSelectFieldOption }