import React, { Component } from 'react';

import { Customer } from '../../models/customer';

import { DataTransferManager, DataTransferManagerResultSignet } from "../../data-manager/data-transfer-manager"
import { ProgressIndicationManager } from '../../collaborators/progress-indication-manager';
import { ATEncryptionManager } from '../../third-party/com-aurvan-atkit-reactjs/src/at-encryption-manager';
import { RoutingManager } from '../../collaborators/routing-manager';
import { PopupManager } from '../../collaborators/popup-manager';
import { AppTextField } from '../app-ui-kit/app-text-field';
import { AppButton } from '../app-ui-kit/app-button';
import { Session } from '../../models/session';
import { CacheManager } from '../../collaborators/cache-manager';

import "./reset-password-component.css"


class ResetPasswordComponent extends Component {

	constructor(props) {
		super(props);

		this.newResetPasswordCodeEmailAddressTextField = React.createRef();
		this.newResetPasswordCodeButton = React.createRef();

		this.resetPasswordEmailAddressTextField = React.createRef();
		this.resetPasswordCodeTextField = React.createRef();
		this.resetPasswordNewPasswordTextField = React.createRef();
		this.resetPasswordConfirmNewPasswordTextField = React.createRef();
		this.resetPasswordButton = React.createRef();
	}

	componentDidMount() {
		this.newResetPasswordCodeButton.current.title = "Send Me Code";

		this.resetPasswordButton.current.title = "Reset Password";

		this.reloadAllData();
	}

	reloadAllData() {
	}

	reloadAllViews() {
		this.forceUpdate();
	}

	resetAllViews() {
		this.forceUpdate();
	}

	render() {
		return (
			<div className="foundation-storey vertical-scroll-view reset-password-storey">

				<div className="reset-password-box">

					<div className="reset-password-form">
						<div className="reset-password-form-heading">Forgot your password?</div>

						<AppTextField ref={this.newResetPasswordCodeEmailAddressTextField} placeholder="Email Address" didClickEnterCallback={this.didSelectNewResetPasswordCodeButton.bind(this)} />

						<AppButton ref={this.newResetPasswordCodeButton} inputClassNames={["reset-password-form-button"]} didSelectCallback={this.didSelectNewResetPasswordCodeButton.bind(this)} />

						<div className="reset-password-form-instruction">After submitting, you will receive a verification code on your email. You can use the code to reset your password.</div>
						
						<div className="reset-password-form-instruction">Just copy-paste the code in "Reset Password Code" text field in "Reset your password" form on the right.</div>
					</div>

					<div className="reset-password-vertical-separator-container">
						<div className="reset-password-vertical-separator-line"></div>
					</div>

					<div className="reset-password-form">
						<div className="reset-password-form-heading">Reset your password</div>

						<AppTextField ref={this.resetPasswordEmailAddressTextField} placeholder="Email Address" didClickEnterCallback={this.didSelectResetPasswordButton.bind(this)} />

						<AppTextField ref={this.resetPasswordCodeTextField} placeholder="Reset Password Code" didClickEnterCallback={this.didSelectResetPasswordButton.bind(this)} />

						<AppTextField ref={this.resetPasswordNewPasswordTextField} placeholder="New Password" shouldSecureTextEntry={true} didClickEnterCallback={this.didSelectResetPasswordButton.bind(this)} />

						<AppTextField ref={this.resetPasswordConfirmNewPasswordTextField} placeholder="Confirm New Password" shouldSecureTextEntry={true} didClickEnterCallback={this.didSelectResetPasswordButton.bind(this)} />

						<AppButton ref={this.resetPasswordButton} inputClassNames={["reset-password-form-button"]} didSelectCallback={this.didSelectResetPasswordButton.bind(this)} />

					</div>
				
				</div>

			</div>
		);
	}


	didSelectNewResetPasswordCodeButton() {
		try {
			var anEmailAddress = this.newResetPasswordCodeEmailAddressTextField.current.value;
			if (anEmailAddress == null || anEmailAddress.length <= 0) {
				throw new Error("Please provide email address.");
			}

			var aCustomer = new Customer();
			aCustomer.emailAddress = anEmailAddress;

			var aThis = this;
			ProgressIndicationManager.shared.showLoadingOverlay();
			DataTransferManager.shared.newResetCustomerPasswordCode(function(pResult) {
				ProgressIndicationManager.shared.hideLoadingOverlay();
				if (pResult.signet === DataTransferManagerResultSignet.success) {
					var aMessage = "Request processed successfully.";
					if (pResult.localizedDescription != null) {
						aMessage = pResult.localizedDescription;
					}
					PopupManager.shared.displaySuccessMessage(aMessage);
					aThis.resetAllViews();
				} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
					RoutingManager.shared.gotoSignUpLoginCustomer();
				} else {
					var aMessage = "Unknown error.";
					if (pResult.localizedDescription != null) {
						aMessage = pResult.localizedDescription;
					}
					PopupManager.shared.displayErrorMessage(aMessage);
				}
			}, aCustomer);
		} catch (pError) {
			PopupManager.shared.displayError(pError);
		}
	}

	didSelectResetPasswordButton() {
		try {
			var anEmailAddress = this.resetPasswordEmailAddressTextField.current.value;
			if (anEmailAddress == null || anEmailAddress.length <= 0) {
				throw new Error("Please provide email address.");
			}

			var aPlainTextCode = this.resetPasswordCodeTextField.current.value;
			if (aPlainTextCode == null || aPlainTextCode.length <= 0) {
				throw new Error("Please provide your reset password code.");
			}
			var aCodeHash = ATEncryptionManager.sha512(aPlainTextCode);

			var aPlainTextPassword = this.resetPasswordNewPasswordTextField.current.value;
			if (aPlainTextPassword == null || aPlainTextPassword.length <= 0) {
				throw new Error("Please provide your password.");
			}
			var aPlainTextConfirmPassword = this.resetPasswordConfirmNewPasswordTextField.current.value;
			if (aPlainTextPassword != aPlainTextConfirmPassword) {
				throw new Error("Password and Confirm Password do not match.");
			}
			var aPasswordHash = ATEncryptionManager.sha512(aPlainTextPassword);

			var aCustomer = new Customer();
			aCustomer.emailAddress = anEmailAddress;
			aCustomer.resetPasswordCodeHash = aCodeHash;
			aCustomer.passwordHash = aPasswordHash;

			var aThis = this;
			ProgressIndicationManager.shared.showLoadingOverlay();
			DataTransferManager.shared.resetCustomerPassword(function(pResult) {
				ProgressIndicationManager.shared.hideLoadingOverlay();
				if (pResult.signet === DataTransferManagerResultSignet.success) {
					var aMessage = "Request processed successfully.";
					if (pResult.localizedDescription != null) {
						aMessage = pResult.localizedDescription;
					}
					PopupManager.shared.displaySuccessMessage(aMessage, function() {
						RoutingManager.shared.gotoSignUpLoginCustomer();
					});
					aThis.resetAllViews();
				} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
					RoutingManager.shared.gotoSignUpLoginCustomer();
				} else {
					var aMessage = "Unknown error.";
					if (pResult.localizedDescription != null) {
						aMessage = pResult.localizedDescription;
					}
					PopupManager.shared.displayErrorMessage(aMessage);
				}
			}, aCustomer);
		} catch (pError) {
			PopupManager.shared.displayError(pError);
		}
	}

}

export default ResetPasswordComponent;
