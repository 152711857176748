import { DataContractManagerHttp } from "../data-manager/data-contract-manager-http";
import { Customer } from "../models/customer";
import { ObservationManager, ObservationEventName } from "./observation-manager";

import { ProgressIndicationManager } from './progress-indication-manager';
import { DataTransferManager, DataTransferManagerResultSignet } from '../data-manager/data-transfer-manager';


class CacheManager {
	static shared = new CacheManager();

	_loggedInCustomer = null;

	set loggedInCustomer(pNewValue) {
		this._loggedInCustomer = pNewValue
		if (this._loggedInCustomer != null) {
			localStorage.setItem("loggedInCustomerJson", JSON.stringify(this._loggedInCustomer));
		} else {
			localStorage.removeItem("loggedInCustomerJson");
		}
	}

	/** @var Customer loggedInCustomer */
	get loggedInCustomer() {
		var aLoggedInCustomerJson = localStorage.getItem("loggedInCustomerJson");
		if (this._loggedInCustomer == null && aLoggedInCustomerJson != null) {
			var aDict = JSON.parse(aLoggedInCustomerJson);
			this._loggedInCustomer = DataContractManagerHttp.customer(aDict);
		}
		return this._loggedInCustomer;
	}


	_session = null;

	set session(pNewValue) {
		this._session = pNewValue
		if (this._session != null) {
			localStorage.setItem("sessionJson", JSON.stringify(this._session));
		} else {
			localStorage.removeItem("sessionJson");
			this.loggedInCustomer = null;
		}
	}

	get session() {
		if (localStorage.sessionJson != null) {
			var aDict = JSON.parse(localStorage.sessionJson);
			this._session = DataContractManagerHttp.session(aDict);
		}
		return this._session;
	}


	reloadLoggedInUser(pShouldForceReload = false) {
		if (this.session != null && (this.loggedInCustomer == null || pShouldForceReload)) {
			var aThis = this;
			ProgressIndicationManager.shared.showLoadingOverlay();
			DataTransferManager.shared.profileDetails(function(pResult) {
				ProgressIndicationManager.shared.hideLoadingOverlay();
				if (pResult.signet === DataTransferManagerResultSignet.success) {
					if (pResult.outcome instanceof Customer) {
						aThis.loggedInCustomer = pResult.outcome;
					}
				}
				ObservationManager.shared.postNotification(ObservationEventName.didFinishReloadLoggedInUser);
			});
		} else {
			ObservationManager.shared.postNotification(ObservationEventName.didFinishReloadLoggedInUser);
		}
	}
}


export { CacheManager }
