import { LocalizationManager } from "../localization-manager/localization-manager";
import '../localization-manager/localization-manager-ticket';


class TicketState {
	static open = new TicketState("open");
	static closed = new TicketState("closed");

	_signet = null;

	constructor(pSignet) {
		this._signet = pSignet;
	}

	signet() {
		return this._signet;
	}

	equals(pRhs) {
		return this.signet() === pRhs.signet();
	}

	localizedTitle() {
		var aReturnVal = null;
		if (this.equals(TicketState.open)) {
			aReturnVal = LocalizationManager.searchTicketComponent_ticketStateOpenTitle()
		} else if (this.equals(TicketState.closed)) {
			aReturnVal = LocalizationManager.searchTicketComponent_ticketStateClosedTitle()
		}
		return aReturnVal;
	}

}


export { TicketState };
