import { LocalizationManager } from './localization-manager';

LocalizationManager.menuBarComponent_homeTitle = function() {
	return LocalizationManager.localizedString("menuBarComponent_homeTitle");
}

LocalizationManager.menuBarComponent_knowledgebaseTitle = function() {
	return LocalizationManager.localizedString("menuBarComponent_knowledgebaseTitle");
}

LocalizationManager.menuBarComponent_downloadTitle = function() {
	return LocalizationManager.localizedString("menuBarComponent_downloadTitle");
}

LocalizationManager.menuBarComponent_supportTitle = function() {
	return LocalizationManager.localizedString("menuBarComponent_supportTitle");
}

LocalizationManager.menuBarComponent_pricingTitle = function() {
	return LocalizationManager.localizedString("menuBarComponent_pricingTitle");
}

LocalizationManager.menuBarComponent_signupLoginTitle = function() {
	return LocalizationManager.localizedString("menuBarComponent_signupLoginTitle");
}

LocalizationManager.menuBarComponent_dashboardTitle = function() {
	return LocalizationManager.localizedString("menuBarComponent_dashboardTitle");
}


LocalizationManager.navigationBarComponent_headerTitle = function() {
	return LocalizationManager.localizedString("navigationBarComponent_headerTitle");
}

LocalizationManager.navigationBarComponent_ticketSectionTitle = function() {
	return LocalizationManager.localizedString("navigationBarComponent_ticketSectionTitle");
}

LocalizationManager.navigationBarComponent_searchTicketTitle = function() {
	return LocalizationManager.localizedString("navigationBarComponent_searchTicketTitle");
}

LocalizationManager.navigationBarComponent_productSectionTitle = function() {
	return LocalizationManager.localizedString("navigationBarComponent_productSectionTitle");
}

LocalizationManager.navigationBarComponent_newProductTitle = function() {
	return LocalizationManager.localizedString("navigationBarComponent_newProductTitle");
}

LocalizationManager.navigationBarComponent_searchProductTitle = function() {
	return LocalizationManager.localizedString("navigationBarComponent_searchProductTitle");
}

LocalizationManager.navigationBarComponent_searchProductInvitationTitle = function() {
	return LocalizationManager.localizedString("navigationBarComponent_searchProductInvitationTitle");
}

LocalizationManager.navigationBarComponent_miscellaneousSectionTitle = function() {
	return LocalizationManager.localizedString("navigationBarComponent_miscellaneousSectionTitle");
}

LocalizationManager.navigationBarComponent_profileDetailsTitle = function() {
	return LocalizationManager.localizedString("navigationBarComponent_profileDetailsTitle");
}

LocalizationManager.navigationBarComponent_searchSubscriptionTitle = function() {
	return LocalizationManager.localizedString("navigationBarComponent_searchSubscriptionTitle");
}

LocalizationManager.navigationBarComponent_mySubscriptionTitle = function() {
	return LocalizationManager.localizedString("navigationBarComponent_mySubscriptionTitle");
}


LocalizationManager.ceilingBarComponent_welcomeMessage = function() {
	return LocalizationManager.localizedString("ceilingBarComponent_welcomeMessage");
}

LocalizationManager.ceilingBarComponent_guestUserDisplayName = function() {
	return LocalizationManager.localizedString("ceilingBarComponent_guestUserDisplayName");
}


LocalizationManager.pageNotFoundComponent_header01 = function() {
	return LocalizationManager.localizedString("pageNotFoundComponent_header01");
}

LocalizationManager.pageNotFoundComponent_header02 = function() {
	return LocalizationManager.localizedString("pageNotFoundComponent_header02");
}

LocalizationManager.pageNotFoundComponent_message = function() {
	return LocalizationManager.localizedString("pageNotFoundComponent_message");
}
