const SHA512 = require('./third-party/sha512');
const JSEncrypt = require('./third-party/jsencrypt');

class ATEncryptionManager {

}

ATEncryptionManager.sha512 = function(pString) {
	return SHA512(pString);
}

ATEncryptionManager.encodeBase64 = function(pString) {
	return btoa(pString);
}

ATEncryptionManager.decodeBase64 = function(pString) {
	return btoa(pString);
}

ATEncryptionManager.generateRsaKeyPair = function() {
	var aReturnVal = new ATEncryptionManagerRsaKeyPair();

	var aKeySize = 2048;
	var aJsEncrypt = new JSEncrypt({default_key_size: aKeySize});

	var aPrivateKeyPadded = aJsEncrypt.getPrivateKey();
	var aPrivateKeyFirstIndex = aPrivateKeyPadded.indexOf("\n")
	var aPrivateKeyLastIndex = aPrivateKeyPadded.lastIndexOf("\n")
	var aPrivateKeyBegin = aPrivateKeyPadded.substring(0, aPrivateKeyFirstIndex);
	var aPrivateKeyMiddle = aPrivateKeyPadded.substring(aPrivateKeyFirstIndex, aPrivateKeyLastIndex);
	var aPrivateKeyEnd = aPrivateKeyPadded.substring(aPrivateKeyLastIndex + 1);
	var aPrivateKey = aPrivateKeyBegin + "\n" + aPrivateKeyMiddle.replace(/(\r\n|\n|\r)/gm, "") + "\n" + aPrivateKeyEnd;
	
	var aPublicKeyPadded = aJsEncrypt.getPublicKey();
	var aPublicKeyFirstIndex = aPublicKeyPadded.indexOf("\n")
	var aPublicKeyLastIndex = aPublicKeyPadded.lastIndexOf("\n")
	var aPublicKeyBegin = aPublicKeyPadded.substring(0, aPublicKeyFirstIndex);
	var aPublicKeyMiddle = aPublicKeyPadded.substring(aPublicKeyFirstIndex, aPublicKeyLastIndex);
	var aPublicKeyEnd = aPublicKeyPadded.substring(aPublicKeyLastIndex + 1);
	var aPublicKey = aPublicKeyBegin + "\n" + aPublicKeyMiddle.replace(/(\r\n|\n|\r)/gm, "") + "\n" + aPublicKeyEnd;

	aReturnVal.privateKey = aPrivateKey;
	aReturnVal.publicKey = aPublicKey;

	return aReturnVal
}

class ATEncryptionManagerRsaKeyPair {
	privateKey = null;
	publicKey = null;
}

export { ATEncryptionManager, ATEncryptionManagerRsaKeyPair }
