import { LocalizationManager } from "../localization-manager/localization-manager";

class TicketMessageAccessLevel {
	static private = new TicketMessageAccessLevel("private");
	static internal = new TicketMessageAccessLevel("internal");
	static default = new TicketMessageAccessLevel("default");

	_signet = null;

	constructor(pSignet) {
		this._signet = pSignet;
	}

	signet() {
		return this._signet;
	}

	equals(pRhs) {
		return this.signet() === pRhs.signet();
	}

	localizedTitle() {
		var aReturnVal = null;
		if (this.equals(TicketMessageAccessLevel.private)) {
			aReturnVal = LocalizationManager.ticketMessageAccessLevel_privateTitle()
		} else if (this.equals(TicketMessageAccessLevel.internal)) {
			aReturnVal = LocalizationManager.ticketMessageAccessLevel_internalTitle()
		} else if (this.equals(TicketMessageAccessLevel.default)) {
			aReturnVal = LocalizationManager.ticketMessageAccessLevel_defaultTitle()
		}
		return aReturnVal;
	}
}


export { TicketMessageAccessLevel };
