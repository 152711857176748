import BaseComponent from '../app-ui-kit/base-component';
import React from 'react';
import { RoutingManager } from "../../collaborators/routing-manager"
import { DataTransferManager, DataTransferManagerResultSignet } from "../../data-manager/data-transfer-manager"
import { ProgressIndicationManager } from '../../collaborators/progress-indication-manager';
import { PopupManager } from '../../collaborators/popup-manager';
import { AppSelectField, AppSelectFieldOption } from '../app-ui-kit/app-select-field';
import { AppTable, AppTableRow, AppTableCell } from '../app-ui-kit/app-table';
import { CacheManager } from '../../collaborators/cache-manager';

import "../app-ui-kit/app-ui-kit.css"
import "./search-ticket-component.css"
import { SearchTicketForm } from '../../models/search-ticket-form';
import { PagedList } from '../../models/paged-list';
import { TicketState } from '../../models/ticket-state';
import { TicketFilter } from '../../models/ticket-filter';
import { Customer } from '../../models/customer';
import { TicketMessage } from '../../models/ticket-message';
import { Product } from '../../models/product';
import { AppButton } from '../app-ui-kit/app-button';
import { ATDateManager } from "../../third-party/com-aurvan-atkit-reactjs/src/at-date-manager";
import { AppSpacer } from '../app-ui-kit/app-ui-kit';
import { ObservationManager, ObservationEventName } from '../../collaborators/observation-manager';
import { LocalizationManager } from '../../localization-manager/localization-manager';
import '../../localization-manager/localization-manager-common';
import '../../localization-manager/localization-manager-ticket';


class SearchTicketComponent extends BaseComponent {
	searchTicketForm = null;
	ticketPagedList = null;

	constructor(props) {
		super(props);

		this.productFilterSelectField = React.createRef();
		this.assigneeFilterSelectField = React.createRef();
		this.stateFilterSelectField = React.createRef();
		this.pageNumberSelect = React.createRef();
	}

	reloadAllData() {
		if (CacheManager.shared.loggedInCustomer != null) {
			this.searchTicketFormDetails()
			this.searchTicket();
		}
	}

	reloadAllViews() {
		this.reloadSearchInputBarView()
		this.forceUpdate();
	}

	render() {
		var aReturnVal = this.loginRequiredView();
		if (CacheManager.shared.loggedInCustomer != null) {
			aReturnVal = this.searchTicketView();
		}
		return aReturnVal;
	}

}


// MARK:- Views

SearchTicketComponent.prototype.searchTicketView = function() {
	return (
		<div className="foundation-storey vertical-scroll-view">

			{this.searchInputBarView()}

			{this.ticketCountBarView()}

			{this.ticketListView()}
		</div>
	);
}

SearchTicketComponent.prototype.ticketListView = function() {
	var aTicketRowArray = [];
	var aNoDataView = null;

	if (this.ticketPagedList instanceof PagedList
	&& this.ticketPagedList.list instanceof Array
	&& this.ticketPagedList.list.length > 0) {
		for (var anIndex = 0; anIndex < this.ticketPagedList.list.length; anIndex ++) {
			var aTicket = this.ticketPagedList.list[anIndex];

			var aProductTitle = "NA";
			if (aTicket.author.product instanceof Product) {
				aProductTitle = aTicket.author.product.title;
			}

			var aLatestMessageDate = ATDateManager.stringFromUsdfDateWithFormat(
				aTicket.addedOnUsdfDate
				, LocalizationManager.common_dateFormatDateAndTime()
				, LocalizationManager.currentLocale.signet()
			);
			if (aTicket.latestMessage instanceof TicketMessage) {
				aLatestMessageDate = ATDateManager.stringFromUsdfDateWithFormat(
					aTicket.latestMessage.addedOnUsdfDate
					, LocalizationManager.common_dateFormatDateAndTime()
					, LocalizationManager.currentLocale.signet()
				);
			}

			var anAssigneeDisplayName = LocalizationManager.searchTicketComponent_unassigned();
			if (aTicket.assignee instanceof Customer) {
				anAssigneeDisplayName = aTicket.assignee.displayName;
			}

			var aCellArray = [];

			var aTitleClassNameArray = [];
			aTitleClassNameArray.push("search-ticket-cell-title");
			if (TicketState.open.equals(aTicket.state) === true) {
				aTitleClassNameArray.push("search-ticket-cell-title-unattended");
			}
			var aCellView = <div className="search-ticket-cell-view">
				<div className="search-ticket-cell-row">
					<div className={aTitleClassNameArray.join(" ")}>{aTicket.title}</div>
					<div>{aLatestMessageDate}</div>
				</div>
				<div className="search-ticket-cell-row">
					<div>{aTicket.state.equals(TicketState.open) ? "🟠" : "⚪️"}&nbsp;&nbsp;{aTicket.state.localizedTitle()}</div>
					<div>📦&nbsp;&nbsp;{aProductTitle}</div>
					<div>👤&nbsp;&nbsp;{aTicket.author.displayName}</div>
					<div>🛟&nbsp;&nbsp;{anAssigneeDisplayName}</div>
				</div>
			</div>
			aCellArray.push(AppTableCell.initWithView(aCellView, ["search-ticket-cell"]));
			
			aTicketRowArray.push(new AppTableRow(aCellArray, ["search-ticket-row"], anIndex));
		}
	} else {
		aNoDataView = <div className="no-data-view">{LocalizationManager.searchTicketComponent_noDataMessage()}</div>;
	}

	return <AppTable data={aTicketRowArray} didSelectRowCallback={this.didSelectRow.bind(this)} />
}

SearchTicketComponent.prototype.searchInputBarView = function() {
	return <div className="filter-table">
		<div className={"filter-row"}>
			<div className="filter-cell">
				<AppSelectField ref={this.productFilterSelectField} isMultiSelect={true} didChangeValueCallback={this.productFilterSelectFieldDidChangeValue.bind(this)} />
			</div>
			<div className="filter-cell">
				<AppSelectField ref={this.assigneeFilterSelectField} isMultiSelect={true} />
			</div>
			<div className="filter-cell">
				<AppSelectField ref={this.stateFilterSelectField} isMultiSelect={true} />
			</div>
			<div className="filter-cell filter-search-page-number-cell">
				<AppSelectField ref={this.pageNumberSelect} />
				</div>
			<div className="filter-cell filter-search-button-cell">
				<AppButton
					title={LocalizationManager.searchTicketComponent_searchButtonTitle()}
					inputClassNames={["filter-search-button"]}
					didSelectCallback={this.didSelectSearch.bind(this)}
				/>
			</div>
		</div>
	</div>;
}

SearchTicketComponent.prototype.reloadSearchInputBarView = function() {
	if (this.productFilterSelectField != null && this.productFilterSelectField.current != null) {
		this.productFilterSelectField.current.title = LocalizationManager.searchTicketComponent_productFilterSelectFieldTitle();
		if (this.searchTicketForm instanceof SearchTicketForm) {
			var aProductFilterOptions = [];
			var aProductArray = this.searchTicketForm.products();
			if (aProductArray instanceof Array) {
				for (var anIndex = 0; anIndex < aProductArray.length; anIndex ++) {
					var aProduct = aProductArray[anIndex];
					aProductFilterOptions.push(new AppSelectFieldOption(aProduct.title, aProduct.uuid));
				}
			}
			this.productFilterSelectField.current.options = aProductFilterOptions;
		}
	}

	if (this.assigneeFilterSelectField != null && this.assigneeFilterSelectField.current != null) {
		this.assigneeFilterSelectField.current.title = LocalizationManager.searchTicketComponent_assigneeFilterSelectFieldTitle();
		if (this.searchTicketForm instanceof SearchTicketForm) {
			var aSelectedProductUuidArray = this._selectedProductUuidArray();
			var anAssigneeFilterOptions = [];
			var aCustomerArray = this.searchTicketForm.customers(aSelectedProductUuidArray);
			if (aCustomerArray instanceof Array) {
				for (var anIndex = 0; anIndex < aCustomerArray.length; anIndex ++) {
					var aCustomer = aCustomerArray[anIndex];
					if (anAssigneeFilterOptions.find(pElement => pElement.value === aCustomer.uuid) == null) {
						anAssigneeFilterOptions.push(new AppSelectFieldOption(aCustomer.displayName, aCustomer.uuid));
					}
				}
			}
			this.assigneeFilterSelectField.current.options = anAssigneeFilterOptions;
		}
	}

	if (this.stateFilterSelectField != null && this.stateFilterSelectField.current != null) {
		this.stateFilterSelectField.current.title = LocalizationManager.searchTicketComponent_stateFilterSelectFieldTitle();
		if (this.searchTicketForm instanceof SearchTicketForm) {
			var aStateFilterOptions = [];
			var aStateArray = this.searchTicketForm.ticketStates;
			if (aStateArray instanceof Array) {
				for (var anIndex = 0; anIndex < aStateArray.length; anIndex ++) {
					var aState = aStateArray[anIndex];
					aStateFilterOptions.push(new AppSelectFieldOption(aState.localizedTitle(), aState.signet()));
				}
			}
			this.stateFilterSelectField.current.options = aStateFilterOptions;
		}
	}

	if (this.pageNumberSelect != null && this.pageNumberSelect.current != null) {
		this.pageNumberSelect.current.title = LocalizationManager.searchTicketComponent_pageNumberSelectTitle();
		var aMaxPageNumber = 1;
		if (this.ticketPagedList instanceof PagedList) {
			aMaxPageNumber = Math.ceil(this.ticketPagedList.listTotal / this.ticketPagedList.itemsPerPage);
		}
		var aPageNumberOptions = [];
		for (var aPageNumber = 1; aPageNumber <= aMaxPageNumber; aPageNumber ++) {
			aPageNumberOptions.push(new AppSelectFieldOption(aPageNumber, aPageNumber));
		}
		this.pageNumberSelect.current.options = aPageNumberOptions;
	}
}

SearchTicketComponent.prototype.ticketCountBarView = function() {
	var aListFrom = 0;
	var aListTo = 0;
	var aListTotal = 0;
	if (this.ticketPagedList instanceof PagedList) {
		aListFrom = this.ticketPagedList.listFrom;
		aListTo = this.ticketPagedList.listTo;
		aListTotal = this.ticketPagedList.listTotal;
	}
	return <div>
		<AppSpacer height="20px" />
		<div className="ticket-count-text">
			{LocalizationManager.searchTicketComponent_showingFromTo(aListFrom, aListTo, aListTotal)}
		</div>
		<AppSpacer height="8px" />
	</div>;
}


// MARK:- Actions

SearchTicketComponent.prototype.didSelectRow = function(pRowIndex) {
	if (this.ticketPagedList instanceof PagedList
		&& this.ticketPagedList.list instanceof Array
		&& this.ticketPagedList.list.length > pRowIndex) {
		var aTicket = this.ticketPagedList.list[pRowIndex];
		RoutingManager.shared.gotoTicketDetails(aTicket.uuid);
	}
}

SearchTicketComponent.prototype.didSelectSearch = function() {
	this.searchTicket();
}

SearchTicketComponent.prototype.productFilterSelectFieldDidChangeValue = function() {
	this.reloadAllViews();
}


// MARK:- Data Manager

SearchTicketComponent.prototype._selectedProductUuidArray = function() {
	var aReturnVal = null;
	if (this.productFilterSelectField != null && this.productFilterSelectField.current != null) {
		var aProductUuidOptionArray = this.productFilterSelectField.current.value();
		if (aProductUuidOptionArray instanceof Array && aProductUuidOptionArray.length > 0) {
			var aProductUuidArray = [];
			for (var anIndex = 0; anIndex < aProductUuidOptionArray.length; anIndex ++) {
				var aProductUuidOption = aProductUuidOptionArray[anIndex];
				aProductUuidArray.push(aProductUuidOption.value);
			}
			aReturnVal = aProductUuidArray;
		}
	}
	return aReturnVal;
}

SearchTicketComponent.prototype._selectedAssigneeUuidArray = function() {
	var aReturnVal = null;
	if (this.assigneeFilterSelectField != null && this.assigneeFilterSelectField.current != null) {
		var anAssigneeUuidOptionArray = this.assigneeFilterSelectField.current.value();
		if (anAssigneeUuidOptionArray instanceof Array && anAssigneeUuidOptionArray.length > 0) {
			var anAssigneeUuidArray = [];
			for (var anIndex = 0; anIndex < anAssigneeUuidOptionArray.length; anIndex ++) {
				var anAssigneeUuidOption = anAssigneeUuidOptionArray[anIndex];
				anAssigneeUuidArray.push(anAssigneeUuidOption.value);
			}
			aReturnVal = anAssigneeUuidArray;
		}
	}
	return aReturnVal;
}

SearchTicketComponent.prototype._selectedStateSignetArray = function() {
	var aReturnVal = null;
	if (this.stateFilterSelectField != null && this.stateFilterSelectField.current != null) {
		var aTicketStateOptionArray = this.stateFilterSelectField.current.value();
		if (aTicketStateOptionArray instanceof Array && aTicketStateOptionArray.length > 0) {
			var aTicketStateSignetArray = [];
			for (var anIndex = 0; anIndex < aTicketStateOptionArray.length; anIndex ++) {
				var aTicketStateOption = aTicketStateOptionArray[anIndex];
				aTicketStateSignetArray.push(aTicketStateOption.value);
			}
			aReturnVal = aTicketStateSignetArray;
		}
	}
	return aReturnVal;
}

SearchTicketComponent.prototype.searchTicketFormDetails = function() {
	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.searchTicketFormDetails(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			if (pResult.outcome instanceof SearchTicketForm) {
				aThis.searchTicketForm = pResult.outcome;
			} else {
				aThis.searchTicketForm = null;
			}
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	});
}

SearchTicketComponent.prototype.searchTicket = function() {
	var aThis = this;

	var aTicketFilter = new TicketFilter();

	aTicketFilter.productUuids = this._selectedProductUuidArray();

	aTicketFilter.assigneeUuids = this._selectedAssigneeUuidArray();

	aTicketFilter.stateSignets = this._selectedStateSignetArray();

	var aPageNumberOptionArray = null;
	if (this.pageNumberSelect != null && this.pageNumberSelect.current != null) {
		aPageNumberOptionArray = this.pageNumberSelect.current.value();
	}
	if (aPageNumberOptionArray instanceof Array && aPageNumberOptionArray.length > 0) {
		aTicketFilter.pageNumber = aPageNumberOptionArray[0].value;
	}

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.searchTicket(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			if (pResult.outcome instanceof PagedList) {
				aThis.ticketPagedList = pResult.outcome;
			} else {
				aThis.ticketPagedList = null;
			}
		} else if (pResult.signet === DataTransferManagerResultSignet.error) {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			PopupManager.shared.displayErrorMessage(LocalizationManager.common_unknownApiResponseSignet());
		}
		aThis.reloadAllViews();
	}, aTicketFilter);
}


export default SearchTicketComponent;
