
class TicketMessage {
	uuid = null;
	addedOnUsdfDate = null;

	author = null;

	description = null;
	accessLevel = null;

	attachments = null;
}


export { TicketMessage };
