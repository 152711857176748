
class PagedList {
	listTotal = null;
	listFrom = null;
	listTo = null;
	itemsPerPage = null;
	list = null;
}


export { PagedList };
