
class TicketMessageAttachment {
	uuid = null;
	addedOnUsdfDate = null;

	extension = null;
	sizeInBytes = null;
	downloadUrl = null;

	localFile = null;
}


export { TicketMessageAttachment };
