import { LocalizationManager } from './localization-manager';

LocalizationManager.signupLoginCustomerComponent_signupFormTitle = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_signupFormTitle");
}

LocalizationManager.signupLoginCustomerComponent_signupEmailPlaceholder = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_signupEmailPlaceholder");
}

LocalizationManager.signupLoginCustomerComponent_signupDisplayNamePlaceholder = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_signupDisplayNamePlaceholder");
}

LocalizationManager.signupLoginCustomerComponent_signupPasswordPlaceholder = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_signupPasswordPlaceholder");
}

LocalizationManager.signupLoginCustomerComponent_signupConfirmPasswordPlaceholder = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_signupConfirmPasswordPlaceholder");
}

LocalizationManager.signupLoginCustomerComponent_signupButtonTitle = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_signupButtonTitle");
}

LocalizationManager.signupLoginCustomerComponent_signupButtonInstructionAcceptTerms = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_signupButtonInstructionAcceptTerms");
}

LocalizationManager.signupLoginCustomerComponent_signupButtonInstructionNextStep = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_signupButtonInstructionNextStep");
}


LocalizationManager.signupLoginCustomerComponent_separatorOr = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_separatorOr");
}


LocalizationManager.signupLoginCustomerComponent_loginFormTitle = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_loginFormTitle");
}

LocalizationManager.signupLoginCustomerComponent_loginEmailPlaceholder = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_loginEmailPlaceholder");
}

LocalizationManager.signupLoginCustomerComponent_loginPasswordPlaceholder = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_loginPasswordPlaceholder");
}

LocalizationManager.signupLoginCustomerComponent_loginButtonTitle = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_loginButtonTitle");
}

LocalizationManager.signupLoginCustomerComponent_loginButtonInstructionAcceptTerms = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_loginButtonInstructionAcceptTerms");
}


LocalizationManager.signupLoginCustomerComponent_forgotPasswordFormTitle = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_forgotPasswordFormTitle");
}

LocalizationManager.signupLoginCustomerComponent_forgotPasswordButtonTitle = function() {
	return LocalizationManager.localizedString("signupLoginCustomerComponent_forgotPasswordButtonTitle");
}


LocalizationManager.customerProfileDetailsComponent_profileDetailsSectionTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_profileDetailsSectionTitle");
}

LocalizationManager.customerProfileDetailsComponent_profileDetailsDisplayNameInputTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_profileDetailsDisplayNameInputTitle");
}

LocalizationManager.customerProfileDetailsComponent_textInputPlaceholder = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_textInputPlaceholder");
}

LocalizationManager.customerProfileDetailsComponent_updateCtaTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_updateCtaTitle");
}

LocalizationManager.customerProfileDetailsComponent_deleteCtaTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_deleteCtaTitle");
}

LocalizationManager.customerProfileDetailsComponent_identitySectionTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_identitySectionTitle");
}

LocalizationManager.customerProfileDetailsComponent_identityEmailAddressColumnTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_identityEmailAddressColumnTitle");
}

LocalizationManager.customerProfileDetailsComponent_identityVerificationDateColumnTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_identityVerificationDateColumnTitle");
}

LocalizationManager.customerProfileDetailsComponent_identityEmailAddressInputTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_identityEmailAddressInputTitle");
}

LocalizationManager.customerProfileDetailsComponent_addIdentityCtaTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_addIdentityCtaTitle");
}

LocalizationManager.customerProfileDetailsComponent_resendVerificationCodeCtaTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_resendVerificationCodeCtaTitle");
}

LocalizationManager.customerProfileDetailsComponent_provideEmailAddress = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_provideEmailAddress");
}

LocalizationManager.customerProfileDetailsComponent_provideVerificationCode = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_provideVerificationCode");
}

LocalizationManager.customerProfileDetailsComponent_deleteEmailConformationMessage = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_deleteEmailConformationMessage");
}

LocalizationManager.customerProfileDetailsComponent_updatePasswordSectionTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_updatePasswordSectionTitle");
}

LocalizationManager.customerProfileDetailsComponent_updatePasswordNewPasswordInputTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_updatePasswordNewPasswordInputTitle");
}

LocalizationManager.customerProfileDetailsComponent_updatePasswordNewPasswordRetypeInputTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_updatePasswordNewPasswordRetypeInputTitle");
}

LocalizationManager.customerProfileDetailsComponent_updatePasswordCurrentPasswordInputTitle = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_updatePasswordCurrentPasswordInputTitle");
}

LocalizationManager.customerProfileDetailsComponent_provideNewPassword = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_provideNewPassword");
}

LocalizationManager.customerProfileDetailsComponent_retypeNewPassword = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_retypeNewPassword");
}

LocalizationManager.customerProfileDetailsComponent_newRetypePasswordDoNotMatch = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_newRetypePasswordDoNotMatch");
}

LocalizationManager.customerProfileDetailsComponent_provideCurrentPassword = function() {
	return LocalizationManager.localizedString("customerProfileDetailsComponent_provideCurrentPassword");
}
