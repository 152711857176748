
class ProductCustomerMap {
	uuid = null;
	addedOnUsdfDate = null;

	product = null;
	customer = null;
	role = null;
}


export { ProductCustomerMap };
