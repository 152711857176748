import { LocalizationManager } from './localization-manager';

LocalizationManager.ticketMessageAccessLevel_privateTitle = function() {
	return LocalizationManager.localizedString("ticketMessageAccessLevel_privateTitle");
}

LocalizationManager.ticketMessageAccessLevel_internalTitle = function() {
	return LocalizationManager.localizedString("ticketMessageAccessLevel_internalTitle");
}

LocalizationManager.ticketMessageAccessLevel_defaultTitle = function() {
	return LocalizationManager.localizedString("ticketMessageAccessLevel_defaultTitle");
}


LocalizationManager.searchTicketComponent_productFilterSelectFieldTitle = function() {
	return LocalizationManager.localizedString("searchTicketComponent_productFilterSelectFieldTitle");
}

LocalizationManager.searchTicketComponent_assigneeFilterSelectFieldTitle = function() {
	return LocalizationManager.localizedString("searchTicketComponent_assigneeFilterSelectFieldTitle");
}

LocalizationManager.searchTicketComponent_stateFilterSelectFieldTitle = function() {
	return LocalizationManager.localizedString("searchTicketComponent_stateFilterSelectFieldTitle");
}

LocalizationManager.searchTicketComponent_pageNumberSelectTitle = function() {
	return LocalizationManager.localizedString("searchTicketComponent_pageNumberSelectTitle");
}

LocalizationManager.searchTicketComponent_searchButtonTitle = function() {
	return LocalizationManager.localizedString("searchTicketComponent_searchButtonTitle");
}

LocalizationManager.searchTicketComponent_unassigned = function() {
	return LocalizationManager.localizedString("searchTicketComponent_unassigned");
}

LocalizationManager.searchTicketComponent_ticketStateOpenTitle = function() {
	return LocalizationManager.localizedString("searchTicketComponent_ticketStateOpenTitle");
}

LocalizationManager.searchTicketComponent_ticketStateClosedTitle = function() {
	return LocalizationManager.localizedString("searchTicketComponent_ticketStateClosedTitle");
}

LocalizationManager.searchTicketComponent_noDataMessage = function() {
	return LocalizationManager.localizedString("searchTicketComponent_noDataMessage");
}

LocalizationManager.searchTicketComponent_showingFromTo = function(pFrom, pTo, pCount) {
	return LocalizationManager.localizedString("searchTicketComponent_showingFromTo", pFrom, pTo, pCount);
}


LocalizationManager.ticketDetailsComponent_messageAccessLevelInputTitle = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_messageAccessLevelInputTitle");
}

LocalizationManager.ticketDetailsComponent_assigneeInputTitle = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_assigneeInputTitle");
}

LocalizationManager.ticketDetailsComponent_assignButtonTitle = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_assignButtonTitle");
}

LocalizationManager.ticketDetailsComponent_closeTicketButtonTitle = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_closeTicketButtonTitle");
}

LocalizationManager.ticketDetailsComponent_draftReplyButtonTitle = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_draftReplyButtonTitle");
}

LocalizationManager.ticketDetailsComponent_messageAttachmentFileInputTitle = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_messageAttachmentFileInputTitle");
}

LocalizationManager.ticketDetailsComponent_submitReplyButtonTitle = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_submitReplyButtonTitle");
}

LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpTitle = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_messageAccessLevelHelpTitle");
}

LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpPrivateTitle = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_messageAccessLevelHelpPrivateTitle");
}

LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpPrivateDescription = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_messageAccessLevelHelpPrivateDescription");
}

LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpInternalTitle = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_messageAccessLevelHelpInternalTitle");
}

LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpInternalDescription = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_messageAccessLevelHelpInternalDescription");
}

LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpDefaultTitle = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_messageAccessLevelHelpDefaultTitle");
}

LocalizationManager.ticketDetailsComponent_messageAccessLevelHelpDefaultDescription = function() {
	return LocalizationManager.localizedString("ticketDetailsComponent_messageAccessLevelHelpDefaultDescription");
}

