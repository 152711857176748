
class SearchTicketForm {
	productCustomerMaps = null;
	ticketStates = null;

	customers(pProductUuidArray) {
		var aReturnVal = null;
		if (this.productCustomerMaps instanceof Array) {
			var aProductCustomerMapArray = this.productCustomerMaps;
			if (pProductUuidArray instanceof Array) {
				aProductCustomerMapArray = aProductCustomerMapArray.filter(pItem => pProductUuidArray.find(pProductUuid => pProductUuid === pItem.product.uuid) != null);
			}
			var aCustomerArray = [];
			for (var anIndex = 0; anIndex < aProductCustomerMapArray.length; anIndex ++) {
				var aProductCustomerMap = aProductCustomerMapArray[anIndex];
				var anIsCustomerAdded = aCustomerArray.find(pItem => aProductCustomerMap.customer.uuid === pItem.uuid) != null;
				if (anIsCustomerAdded === false) {
					aCustomerArray.push(aProductCustomerMap.customer);
				}
			}
			aReturnVal = aCustomerArray;
		}
		return aReturnVal;
	}

	products() {
		var aReturnVal = null;
		if (this.productCustomerMaps instanceof Array) {
			var aProductArray = []
			for (var anIndex = 0; anIndex < this.productCustomerMaps.length; anIndex ++) {
				var aProductCustomerMap = this.productCustomerMaps[anIndex];
				var anIsProductAdded = aProductArray.find(pItem => aProductCustomerMap.product.uuid === pItem.uuid) != null;
				if (anIsProductAdded === false) {
					aProductArray.push(aProductCustomerMap.product);
				}
			}
			aReturnVal = aProductArray;
		}
		return aReturnVal;
	}
}


export { SearchTicketForm };
