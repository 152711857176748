
class ProgressIndicationManager {
	static shared = new ProgressIndicationManager();

	constructor() {
		this._setup();
	}

	_setup() {
		ProgressIndicationManager._overlayElement = document.getElementById("ProgressIndicationManagerOverlayElement");
		if (ProgressIndicationManager._overlayElement == null) {
			var anOverlayElement = document.createElement("div");
			document.body.appendChild(anOverlayElement);
			anOverlayElement.id = "ProgressIndicationManagerOverlayElement"
			anOverlayElement.style.position = "fixed";
			anOverlayElement.style.left = "0";
			anOverlayElement.style.top = "0";
			anOverlayElement.style.width = "100vw";
			anOverlayElement.style.height = "100vh";
			anOverlayElement.style.backgroundColor = "rgba(0, 0, 0, 0.4)";
			anOverlayElement.style.zIndex = "9000";
			anOverlayElement.style.display = "none";
			anOverlayElement.style.fontSize = "24px";
			anOverlayElement.style.color = "rgba(255, 255, 255, 1)";
			anOverlayElement.style.textAlign = "center";
			anOverlayElement.style.lineHeight = "100vh";
			anOverlayElement.innerText = "Loading...";
			ProgressIndicationManager._overlayElement = anOverlayElement;
		}
	}

	showLoadingOverlay() {
		if (ProgressIndicationManager._overlayElement != null) {
			ProgressIndicationManager._overlayElement.style.display = "block";
		}
	}

	hideLoadingOverlay() {
		if (ProgressIndicationManager._overlayElement != null) {
			ProgressIndicationManager._overlayElement.style.display = "none";
		}
	}
}


export { ProgressIndicationManager }
